import {useState, useEffect} from "react";
import { Box, TextField, FormControl, Button, InputLabel, OutlinedInput, InputAdornment, IconButton} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useLogin } from "../../hooks/useLogin";

const LoginForm = () => {

	const history = useHistory();
	const {login, error, isPending} = useLogin();

	const [values, setValues] = useState({
		username: '',
		password: '',
		showPassword: false,
	  });
	
	  const handleChange = (prop) => (event) => {
		setValues({ ...values, [prop]: event.target.value });
	  };
	
	  const handleClickShowPassword = () => {
		setValues({
		  ...values,
		  showPassword: !values.showPassword,
		});
	  };
	
	  const handleMouseDownPassword = (event) => {
		event.preventDefault();
	  };

	  const handleLoginClick = (event) => {
		  login(values.username, values.password).then(
			() => {
				history.push("/userprofile");
				// how to fetch & use the state from the authContext ??? ==> no need, serve it at userprofile.  
				// MyAppBar will be immediately updated
			},
			error => {
				const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
				console.log(resMessage);
			}
		  );
	  }//handleLoginClick

	return (
		<Box 
			component="form"
			sx={{
				'& > :not(style)': { m: 1, width: '25ch' },
			}}
			noValidate
			autoComplete="off"
		>
			<TextField id="username" label="username" variant="outlined" onChange={handleChange('username')} />
			<FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
				<InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
				<OutlinedInput
					id="outlined-adornment-password"
					type={values.showPassword ? 'text' : 'password'}
					value={values.password}
					onChange={handleChange('password')}
					endAdornment={
						<InputAdornment position="end">
							<IconButton
								aria-label="toggle password visibility"
								onClick={handleClickShowPassword}
								onMouseDown={handleMouseDownPassword}
								edge="end">
									{values.showPassword ? <VisibilityOff /> : <Visibility />}
							</IconButton>
						</InputAdornment>
					}
					label="Password"
				/>
			</FormControl>
			{isPending && <Button disabled>loading ...</Button>}
			{!isPending && <Button variant="contained" onClick={handleLoginClick}>Log in</Button>}
			{error && <p>{error}</p>}
		</Box>
	);
}

export default LoginForm;