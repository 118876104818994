import "./subject-component.css";
import "./Modal.css";
import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

//import { useSurveySubjects } from "../../hooks/useSurveySubjects";
import { useManageSurvey } from "../../hooks/useManageSurvey";

import SurveySubjectDetails from "./SurveySubjectDetails";
import SurveySubjectForm from "./SurveySubjectForm";


import { Button, IconButton } from "@mui/material";
import { Add, AnalyticsOutlined, Delete, Edit} from "@mui/icons-material";
import SurveyMessageEditor from "./SurveyMessageEditor";
import SurveyMessageEditorForm from "./SurveyMessageEditorForm";
import { useSurvey } from "../../hooks/useSurvey";
import SurveyFlatEditor from "./SurveyFlatEditor";
import SurveyFlatEditorForm from "./SurveyFlatEditorForm";
import SurveyModalComponent from "./survey-modal-component";
import SurveyClusters from "./survey-clusters-component";
import SurveyPages from "./survey-pages-component";
import EditSurveyForm from "./EditSurveyForm";
import { isNullOrUndef } from "chart.js/helpers";

const SurveyDetailsComponent = () => {

    //const {changeSurveyId} = useSurveySubjects();
    //const {respondentInviteMessage, respondentReminderMessage, configFile} = useSurvey();
    const {survey} = useSurvey();
    const {setSurveyById, 
           changeInviteMessage, changeReminderMessage, changeInstructions, changeIntro, changeOutro, 
           changeSurveyName, changeConfigFile, 
           addSurveySubject, deleteSurveySubject, updateSurveySubject,
           error} = useManageSurvey();

    const {surveyIdParam} = useParams();

    const [isReady, setIsReady] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const [showMessageEditor, setShowMessageEditor] = useState(false);
    const [showFlatEditor, setShowFlatEditor] = useState(false);
    const [showClusters, setShowClusters] = useState(false);
    const [showPages, setShowPages] = useState(false);
    const [showEditSurvey, setShowEditSurvey] = useState(false);

    const [updatingMessageType, setUpdatingMessageType] = useState("");
    const [currentMessageText, setCurrentMessageText] = useState("");

    const activeSurveySubject = useRef(null);


    useEffect(() => {
        console.log("survey-details useEffect no deps for "+surveyIdParam);
        //changeSurveyId(surveyIdParam); //using the reducer TODO: should this be done in useEffect ??


        /** adjusted 2024-009: surveySubjects are part of the survey now, see how to avoid using what's not needed
         *  concerns:
         *  - what happens when anything is updated ?
         *  - what happens when a surveySubject is added ?
         *
         */
        setSurveyById(surveyIdParam).then(() => setIsReady(true));
        /*setSurveyById(surveyIdParam);
        setIsReady(false);
        const fetchSurveySubjects = async () => {
            await setSurveySubjectsForSurveyId(surveyIdParam);
        }
        fetchSurveySubjects().then(() => setIsReady(true));*/
    }, []); //useEffect

    useEffect(()=> {
        console.log("survey-details useEffect [survey] "+survey.name);
    },[survey]);

    const handleCloseModal = () => {
        setShowModal(false);
    }

    const handleOpenEditSurvey = () => {
        setShowEditSurvey(true);
    }
    const handleUpdateSurveyName = (surveyName) => {
        changeSurveyName(surveyIdParam, surveyName);
    }

    const handleCloseEditSurvey = () => {
        setShowEditSurvey(false);
    }

    const handleOpenClusters = () => {
        setShowClusters(true);
    }

    const handleCloseClusters = () => {
        setShowClusters(false);
    }
    const handleOpenPages = () => {
        setShowPages(true);
    }
    const handleClosePages = () => {
        setShowPages(false);
    }


    const addSurveySubjectInComponent = (surveySubject) => {
        //28/09
        //addSurveySubject(surveySubject);
        //console.log("adding surveySubject in component:");
        //console.log(surveySubject);

        // create it in db and adjust state ==> should be done for the survey ???
        /**2024-05-10 
        add(surveySubject.firstName, surveySubject.lastName, surveySubject.email, surveyIdParam);
        */
       
       addSurveySubject(surveySubject);
        setShowModal(false);
    }

    const adjustSurveySubject = (_surveySubject) => {
        activeSurveySubject.current = _surveySubject;
        setShowModal(true);
    }

    const adjustSurveySubjectInComponent = (_surveySubject) => {
        updateSurveySubject(_surveySubject);
        setShowModal(false);
    }

    const handleCloseMessageEditor = () => {
        setShowMessageEditor(false);
    }
    const handleCloseFlatEditor = () => {
        setShowFlatEditor(false);
    }

    const handleOpenEditor = (msgType) => {
        //console.log("opening editor for "+msgType);
        switch (msgType){
            case 'invite':
                setUpdatingMessageType(msgType);
                setCurrentMessageText(survey.respondentInviteMessage);
                setShowMessageEditor(true);
                break;
            case 'remind':
                setUpdatingMessageType(msgType);
                setCurrentMessageText(survey.respondentReminderMessage);
                setShowMessageEditor(true);
                break;
            case 'configuration':
                setUpdatingMessageType(msgType);
                setCurrentMessageText(survey.configFile);
                setShowFlatEditor(true);
                break;
            case 'instructions':
                setUpdatingMessageType(msgType);
                setCurrentMessageText(survey.instructions);
                setShowMessageEditor(true);
                break;
            case 'intro':
                setUpdatingMessageType(msgType);
                setCurrentMessageText(survey.intro);
                setShowMessageEditor(true);
                break;
            case 'outro':
                setUpdatingMessageType(msgType);
                setCurrentMessageText(survey.outro);
                setShowMessageEditor(true);
                break;
           default:
                setShowMessageEditor(false);
                setShowFlatEditor(false);
                break;
        }//switch
    }

    const updateSurveyMessageInComponent = (msgText) => {
        //console.log("update SurveyMessage In Component");
        switch (updatingMessageType){
            case 'invite':
                changeInviteMessage(surveyIdParam, msgText);
                setShowMessageEditor(false);
                break;
            case 'remind':
                changeReminderMessage(surveyIdParam, msgText);
                setShowMessageEditor(false);
                break;
          case 'configuration':
                changeConfigFile(surveyIdParam, msgText);
                setShowFlatEditor(false);
                break;
        case 'instructions':
            //console.log("switch.instructions");
            //console.log(msgText);
            changeInstructions(surveyIdParam, msgText);
            setShowMessageEditor(false);
            break;
        case 'intro':
            //console.log("switch.intro");
            //console.log(msgText);
            changeIntro(surveyIdParam, msgText);
            setShowMessageEditor(false);
            break;
        case 'outro':
            //console.log("switch.outro");
            //console.log(msgText);
            changeOutro(surveyIdParam, msgText);
            setShowMessageEditor(false);
            break;                                              
         default:
                //console.log("switch.default");
                setShowFlatEditor(false);
                setShowMessageEditor(false);
                break;
        }//switch

    }

    function readableError(error){
        if (error === 'Request failed with status code 401'){
            return "Er liep iets mis met je toegangsrechten.  Dit kan te wijten zijn aan een vervallen beveiligingscertificaat.  Log opnieuw in indien nodig en/of ververs de pagina";
        }
        else return error;
    }
    
    return (        
            <div>
                {!isReady && (<div>Loading...</div>)}
                {/** {isReady && surveySubjects && ( */}
                {isReady && survey.surveySubjects && (
                    <>
                        {!showModal && (
                            <div className="rightButton" >
                                <Button 
                                    variant="outlined"
                                    onClick={() => setShowModal(true)} 
                                    startIcon={<Add />} >Subject toevoegen
                                </Button>
                            </div>
                        )}
                        <table className='personsTable'>
                            <thead>
                                <tr>
                                    <th>Voornaam</th>
                                    <th>Naam</th>
                                    <th>e-mail</th>
                                    <th>bewerken</th>
                                    <th># respondenten</th>
                                    <th>{/*uitnodiging*/}</th>
                                    <th>{/*herinnering*/}</th>
                                    <th>rapport</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {/** {surveySubjects.map(subject => ( */}
                                {survey.surveySubjects.map(subject => (

                                    <tr key={subject.id}>

                                   {/** <tr key={subject.key}>
                                        <td><a href={"/newsubject/" + subject.key}>{subject.firstName}</a></td>
                                        <td>{subject.lastName}</td>
                                        <td>{subject.email}</td>
                                        <td style={{textAlign:'right',}}>{subject.respondentCount} </td>
                                        <td>
                                        </td>
                                        <td>
                                        
                                        </td>
                                        <td><a href={"/report/" + subject.key}><IconButton color="secondary" aria-label="rapport raadplegen"><AnalyticsOutlined/></IconButton></a></td>
                                        <td>
                                            {(subject.respondentCount) === 0 && <IconButton color="primary" aria-label="verwijderen" onClick={(e) => remove(subject)}>
                                                <Delete/>
                                            </IconButton>}
                                        </td>
                                    </tr> */}
                                        <td><a href={"/newsubject/" + subject.id}>{subject.subjectPerson.firstName}</a></td>
                                        <td>{subject.subjectPerson.lastName}</td>
                                        <td>{subject.subjectPerson.email}</td>
                                        <td><IconButton color="secondary" aria-label="aanpassen subject" onClick={() => adjustSurveySubject(subject)}><Edit/></IconButton></td>
                                        <td style={{textAlign:'right',}}>{isNullOrUndef(subject.respondents) ? 0 : subject.respondents.length} </td>
                                        <td>
                                            {/**<IconButton color="secondary" aria-label="verstuur uitnodiging">
                                                <Email/>
                                            </IconButton>*/}
                                         </td>
                                        <td>
                                            {/**
                                            <IconButton color="secondary" aria-label="verstuur reminder">
                                                <AlarmAddOutlined/>
                                            </IconButton>
                                             */}
                                        </td>
                                        <td><a href={"/report/" + subject.key}><IconButton color="secondary" aria-label="rapport raadplegen"><AnalyticsOutlined/></IconButton></a></td>
                                        <td>
                                            {(isNullOrUndef(subject.respondents) || subject.respondents.length === 0) && <IconButton color="primary" aria-label="verwijderen" onClick={(e) => deleteSurveySubject(subject)}>
                                                <Delete/>
                                            </IconButton>}
                                        </td>
                                    </tr>

                            ))}
                            </tbody>
                        </table>
                    </>
                )}
                {showModal && <SurveySubjectDetails handleClose={handleCloseModal}>
                    <SurveySubjectForm addSurveySubject={addSurveySubjectInComponent} handleClose={handleCloseModal} placeholder={currentMessageText} updateSurveySubject={adjustSurveySubjectInComponent} editableSurveySubject={activeSurveySubject.current}/>
                </SurveySubjectDetails>}
                {!showModal && (
                    <div className="rightButton" >
                        <br/>
                        <Button 
                            variant="outlined"
                            onClick={() => setShowModal(true)} 
                            startIcon={<Add />} >Subject toevoegen
                        </Button> 
                    </div>
                )}
                <div>
                    <table className='personsTable'>
                        <thead>
                            <tr>
                                <th>bericht</th>
                                <th>bewerken</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>uitnodiging respondenten</td>
                                <td>
                                    <IconButton color="secondary" aria-label="aanpassen uitnodiging" onClick={() => handleOpenEditor('invite')}>
                                        <Edit/>
                                    </IconButton>
                                </td>
                            </tr>
                            <tr>
                                <td>herinnnering respondenten</td>
                                <td>
                                    <IconButton color="secondary" aria-label="aanpassen reminder" onClick={() => handleOpenEditor('remind')}>
                                        <Edit/>
                                    </IconButton>
                                </td>
                            </tr>
                            <tr><td>----------</td><td></td></tr>
                            {/**<tr>
                                <td>aanpassen intro</td>
                                <td>
                                    <IconButton color="secondary" aria-label="aanpassen intro" onClick={() => handleOpenEditor('intro')}>
                                        <Edit/>
                                    </IconButton>
                                </td>
                            </tr>
                            */}
                            <tr>
                                <td>aanpassen naam survey</td>
                                <td>
                                    <IconButton variant="outline" color="secondary" aria-label="aanpassen naam survey" onClick={handleOpenEditSurvey}><Edit/></IconButton>
                                </td>
                            </tr>

                            <tr>
                                <td>aanpassen clusters</td>
                                <td>
                                    <IconButton variant="outline" color="secondary" aria-label="aanpassen clusters" onClick={handleOpenClusters}><Edit/></IconButton>
                                </td>
                            </tr>
                            <tr>
                                <td>aanpassen pagina's en vragen</td>
                                <td>
                                    <IconButton variant="outline" color="secondary" aria-label="aanpassen pagina's en vragen" onClick={handleOpenPages}><Edit/></IconButton>
                                </td>
                            </tr>
                            <tr>
                                <td>aanpassen outro</td>
                                <td>
                                    <IconButton color="secondary" aria-label="aanpassen outro" onClick={() => handleOpenEditor('outro')}>
                                        <Edit/>
                                    </IconButton>
                                </td>
                            </tr>
                            <tr>
                                <td>aanpassen configuratie</td>
                                <td>
                                    <IconButton color="secondary" aria-label="aanpassen configuratie" onClick={() => handleOpenEditor('configuration')}>
                                        <Edit/>
                                    </IconButton>
                                </td>
                            </tr>

                            {/**<tr>
                                <td>aanpassen instructies</td>
                                <td>
                                    <IconButton color="secondary" aria-label="aanpassen instructies" onClick={() => handleOpenEditor('instructions')}>
                                        <Edit/>
                                    </IconButton>
                                </td>
                            </tr>*/}
                        </tbody>
                    </table>
                </div>
                {showMessageEditor && 
                    <SurveyMessageEditor handleClose={handleCloseMessageEditor}>
                        <SurveyMessageEditorForm handleClose={handleCloseMessageEditor} updateSurveyMessage={updateSurveyMessageInComponent} placeholder={currentMessageText}/>
                    </SurveyMessageEditor>
                }
                {showFlatEditor && 
                    <SurveyFlatEditor handleClose={handleCloseMessageEditor}>
                        <SurveyFlatEditorForm handleClose={handleCloseFlatEditor} updateSurveyMessage={updateSurveyMessageInComponent} placeholder={currentMessageText}/>
                    </SurveyFlatEditor>
                }
                {showClusters &&
                    <SurveyModalComponent><SurveyClusters survey={survey} changeConfigFile={changeConfigFile} handleClose={handleCloseClusters}/></SurveyModalComponent>
                }
                {showPages &&
                    <SurveyModalComponent modalWidth="95%" modalMinHeight="600px"><SurveyPages survey={survey} changeConfigFile={changeConfigFile} handleClose={handleClosePages}/> </SurveyModalComponent>
                }
                {showEditSurvey &&
                    <SurveyModalComponent><EditSurveyForm surveyName={survey.name} handleClose={handleCloseEditSurvey} updateSurveyName={handleUpdateSurveyName}/></SurveyModalComponent>
                }
                {error && <p>{readableError(error)}</p> }
            </div>
        
	);
}

export default SurveyDetailsComponent;