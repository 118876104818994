const SurveyModalComponent = ({children, modalWidth, modalMinHeight}) => {
    /** children must implement handleClose ==> to be improved <= keep it here */
    return (
        <div className="modal-backdrop" style={{overflow: 'auto'}}>
            <div className="modal-wide" style={{maxWidth: modalWidth, minHeight: modalMinHeight}}>
                {children}
            </div>
        </div>
    );
}
export default  SurveyModalComponent;