import { createContext, useReducer } from "react";

export const SurveySubjectsContext = createContext(); // returns a new context objects


export const surveySubjectsReducer = (state, action) => {
    switch (action.type){
        case 'CHANGE_SURVEYID':
            return { ...state, surveyId: action.payload};
        case 'ADD_SURVEYSUBJECT':
//1. add the respondent to the database (verify doubles ??) 
//NO ! NOT HERE ==> in HOOK ??

//2. add the respondent to the state
            return {...state, surveySubjects: [...state.surveySubjects, action.payload]};

        case 'REMOVE_SURVEYSUBJECT':
            return {...state, surveySubjects: state.surveySubjects.filter((subj)=> subj.key !== action.payload.key)}
        case 'SET_SURVEYSUBJECTS':
            //return {...state, respondents: action.payload.sort((a,b) => a.relationship > b.relationship)};
            return {...state, surveySubjects: action.payload};
        default: 
            return state;

    }//switch


}

export function SurveySubjectsProvider({children}){

    const [state, dispatch] = useReducer(surveySubjectsReducer, {surveyId:null, surveySubjects:[]});

    const changeSurveyId = (surveyId) => {
        dispatch({type: 'CHANGE_SURVEYID', payload: surveyId}); //this is the action object (2nd argument of the reducer)
    }

    const addSurveySubject = (newSurveySubject) => {
        dispatch({type: 'ADD_SURVEYSUBJECT', payload: newSurveySubject});
    }
    const removeSurveySubject = (surveySubject) => {
        dispatch({type: 'REMOVE_SURVEYSUBJECT', payload:surveySubject});
    }

    const setSurveySubjects = (surveySubjects) => {
        dispatch({type: 'SET_SURVEYSUBJECTS', payload: surveySubjects});
    }

    return(
        <SurveySubjectsContext.Provider value={{...state, changeSurveyId, addSurveySubject, removeSurveySubject, setSurveySubjects}} >
            {children}
        </SurveySubjectsContext.Provider>
    );
}