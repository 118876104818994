import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../editor/MsgEditor.css";

import { Clear, ExitToApp, Save } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useState, useEffect } from "react";
import "./RespondentForm.css";


export default function SurveyMessageEditorForm({updateSurveyMessage, handleClose, placeholder}){

    const [editorHtml, setEditorHtml] = useState("");

    const handleChange = html => {
        setEditorHtml(html);
    };

   /**  const handleCommit = () => {
        console.log(editorHtml);
    }*/


    const resetForm = () => {
        setEditorHtml("");
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        updateSurveyMessage(editorHtml);
        resetForm();
    }

    useEffect(() => {
        console.log("surveyMessageEditorForm useEffect fired");
        setEditorHtml(placeholder);
    },[]);

    const toolbarOptions = [
        ['bold', 'italic', 'underline'],        // toggled buttons
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        
        [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction
      
        
        [{ 'color': [] }],          // dropdown with defaults from theme
        [{ 'align': [] }],
        ['link'],
      
        ['clean']                                         // remove formatting button
      ];
    
    const modules = {
        toolbar: toolbarOptions,
         
    };
    
    const formats = [
            "header",
            "font",
            "size",
            "bold",
            "italic",
            "underline",
            "list",
            "bullet",
            "indent",
            "link",
            "color",
            "align",
    ];

    return (
        <div className="text-editor">
            <ReactQuill
            value={editorHtml}
            onChange={handleChange}
            modules={modules}
            formats={formats}
            theme="snow"
            />
            <div className="bottomButtons">
                <div>
                    <Button
                        variant="outlined"
                        onClick={handleSubmit} 
                        startIcon={<Save />} >Bewaren
                    </Button>
                </div>
                <div>
                    <Button 
                        variant="outlined"
                        onClick={resetForm} 
                        startIcon={<Clear />} >Wissen
                    </Button>
                </div> 
                <div>
                    <Button 
                        variant="outlined"
                        onClick={handleClose} 
                        startIcon={<ExitToApp />} >Sluiten
                    </Button>
                </div> 
            </div>
        </div>
    );
}

/**placeholder={props.placeholder}**/
/**placeholder={placeholder} */