import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        debug: true,
        react: { useSuspense: false},
        lng: 'nl',
        fallbackLng: 'en',
        resources: {
            en: {
              translation: {"Welkom op 360°feedback.be !": "Welcome to 360°feedback.be !",
                    "Je komt op deze website terecht omdat je bent uitgenodigd om deel te nemen aan een 360° feedback-oefening voor een collega of iemand waarmee je samenwerkt over de grenzen van organisaties heen.":"You got on this website because je have been invited to participate in a 360° feedback exercise for a colleague of someone you work with across the boundaries of organizations.",
                    "Normaalgezien werd je daarvoor uitgenodigd via mail en met de link in die mail kan je de vragenlijst rechtstreeks beantwoorden.":"Normally, you were invited via mail containing a personalized link and clicking that link, you can respond to the survey directly.",
                    "Veel succes !":"Good luck !",
                    "survey management":"survey management",
                    "user profile": "user profile",
                    "login": "login",
                    "logout": "logout",
                    "Open settings": "Open settings",
                    "welcome": "Welcome",
                    "no user found": "No user found",
                    "actions":"actions",
                    "no active tasks":"no active tasks",
                    "loading...":"loading...",
                    "open report":"open report",
                    "respond/finalize":"respond/finalize",
                    "360° feedback":"360° feedback",
                    "for":"for",
                    "Finalize":"Finalize",
                    "Page":"Page",
                    "I cannot evaluate this": "I cannot evaluate this",
                }
            },
            nl: {
                translation: {
                    "Welkom op 360°feedback.be !": "Welkom op 360°feedback.be !",
                    "Je komt op deze website terecht omdat je bent uitgenodigd om deel te nemen aan een 360° feedback-oefening voor een collega of iemand waarmee je samenwerkt over de grenzen van organisaties heen.":"Je komt op deze website terecht omdat je bent uitgenodigd om deel te nemen aan een 360° feedback-oefening voor een collega of iemand waarmee je samenwerkt over de grenzen van organisaties heen.",
                    "Normaalgezien werd je daarvoor uitgenodigd via mail en met de link in die mail kan je de vragenlijst rechtstreeks beantwoorden.":"Normaalgezien werd je daarvoor uitgenodigd via mail en met de link in die mail kan je de vragenlijst rechtstreeks beantwoorden.",
                    "Veel succes !":"Veel succes !",
                    "survey management": "beheer surveys",
                    "user profile": "gebruikersprofiel",
                    "login": "aanmelden",
                    "logout": "afmelden",
                    "Open settings": "Instellingen openen",
                    "welcome": "Welkom",
                    "no user found": "Geen gebruiker gevonden",
                    "actions":"acties",
                    "no active tasks":"geen actieve taken",
                    "loading...":"laden...",
                    "open report":"open rapport",
                    "respond/finalize":"beantwoorden / afronden",
                    "360° feedback":"360° feedback",
                    "for":"voor",
                    "Finalize":"Afronden",
                    "Page":"Pagina",
                    "I cannot evaluate this":"ik kan hier niet over oordelen",

                }
            },
            fr: {
                translation: {
                    "Welkom op 360°feedback.be !": "Bienvenu à 360°feedback.be !",
                    "Je komt op deze website terecht omdat je bent uitgenodigd om deel te nemen aan een 360° feedback-oefening voor een collega of iemand waarmee je samenwerkt over de grenzen van organisaties heen.":"Vous vous trouvez sur ce site parce que vous êtes invité(e) à participer à une enquête à 360 degrés pour un collègue ou quelqu'un avec qui vous travaillez à travers les limites d'organizations.",
                    "Normaalgezien werd je daarvoor uitgenodigd via mail en met de link in die mail kan je de vragenlijst rechtstreeks beantwoorden.":"Normalement, vous avez été invité(e) par mail et avec le lien dans ce mail, vous pouvez répondre directement l'enquête",
                    "Veel succes !":"Bonne chance !",
                    "survey management": "gestion d'enquêtes",
                    "user profile": "profil d'utilisateur",
                    "login": "se connecter",
                    "logout": "se déconnecter",
                    "Open settings": "Accéder à la Configuration",
                    "welcome": "Bienvenu",
                    "no user found": "Pas d'utilisateur retrouvé",
                    "actions":"actions",
                    "no active tasks":"aucune tâche active",
                    "loading...":"charger...",
                    "open report":"ouvrir rapport",
                    "respond/finalize":"répondre / finaliser",
                    "360° feedback":"Enquête à 360°",
                    "for":"pour",
                    "Finalize":"Finaliser",
                    "Page":"Page",
                    "I cannot evaluate this": "je ne peux pas juger"

                }
            }
          },
          
    });
