import {createContext, useEffect, useReducer} from "react";
import jwtDecode from "jwt-decode";
import axios from "axios";
import configData from '../config/config.json';

export const AuthContext = createContext();

export const authReducer = (state, action) => {
    switch (action.type){
        case 'LOGIN':
            localStorage.setItem('accessToken', action.payload.token);
            return {...state, user: action.payload, authIsReady: true };
        case 'SET_USER':
            return {...state, user: action.payload, authIsReady: true};
        case 'LOGOUT':
            localStorage.removeItem("accessToken");
            return {...state, user: null};
        case 'AUTH_IS_READY':
            return {...state, user: action.payload, authIsReady: true};
        case 'SET_GOOGLE_TOKEN':
            return {...state, googleToken: action.payload};
        default: 
            return state;
    }//switch
}

export const  AuthContextProvider = ({children}) => {
    const [state, dispatch] = useReducer(authReducer, {
        user: null,
        authIsReady: false,
        googleUser: null,
        googleToken: null
    });



useEffect(() => {
    /** set the initial user based on the data in localStorage */
    //console.log("AuthContext - useEffect");
    const storedToken =  localStorage.getItem('accessToken');
    if (storedToken !== null){
        const decoded = jwtDecode(storedToken);
        const userRoles = decoded.userRoles.split(',');

        /** verify expiration  */
        const expirationDate = parseInt(decoded.exp);
        const current_time = new Date().getTime() / 1000;
        
        if (current_time > expirationDate) {/** if token expired */
            localStorage.removeItem('accessToken');
            localStorage.removeItem('user');
        }
        else { /** if token is not expired, fetch the person and set the user */
            axios.get(configData.SERVER_URL+'/api/persons/'+decoded.personId,{ headers: { 'Authorization': 'Bearer ' + storedToken}}).then((res) => {
                const localUser = {
                    firstName: decoded.firstName,
                    roles: userRoles,
                    token: storedToken,
                    person: res.data
                }
                dispatch({type: 'AUTH_IS_READY', payload: localUser});
                //localStorage.setItem('user',JSON.stringify(localUser));
            });
        }
    }
    else{ /** no token present */
      //  console.log("AuthContext - no token present");
        dispatch({type: 'AUTH_IS_READY', payload: null});
    }
    console.log("AuthContext - useEffect end ");
    console.log(state.user);
}, []); /** useEffect with empty array ==> only first time */

    return(
        <AuthContext.Provider value = {{ ...state, dispatch }}>
            {children}
        </AuthContext.Provider>
    );
} // UserProvider