import React from "react";
import { useParams } from "react-router-dom";

const PersonDetails = ({ data }) => {
  const { personId } = useParams();
  const person = data.find(p => parseInt(p.id) === parseInt(personId));
  let personData;

  if (person) {
    personData = (
      <div>
        <h3> {person.lastName} {person.firstName} </h3>
        <p>{person.email}</p>
        <hr />
        <h4>{new Date(person.birthDate).toLocaleDateString("be-FR",{year: 'numeric', month: '2-digit', day: '2-digit' })}</h4>
      </div>
    );
  } else {
    personData = <h2> Sorry. this person could not be retrieved</h2>;
  }

  return (
    <div style={{ display: "flex" }}>
      <div
        style={{
          width: "80%",
          margin: "auto",
          background: "#ffffff"
        }}
      >
        {personData}
      </div>
    </div>
  );
};

export default PersonDetails ;