import { useState } from 'react';
import { createContainer } from 'unstated-next';
import axios from 'axios';
import authHeader from '../services/auth-header';

import configData from '../config/config.json';

//const dbPreText = "http://www.360feedback.be:8888";

function usePersons(){

	const [persons, setPersons] = useState([]);
	const [isFetched, setIsFetched] = useState(false);
	const [selectedPerson, setSelectedPerson] = useState(null);


	let selectAPerson = (aPerson) => {
		console.log("PERSONS.JS setting a selected Person to "+aPerson.id);
		setSelectedPerson(aPerson);
		console.log("PERSONS.JS just set the selected person, it is now ");
		console.log(selectedPerson);
	} 

    let addPerson = async person => {
    	try{
			const newPerson = {
					id: null,
					firstName: person.firstName,
					lastName: person.lastName,
					birthDate: person.birthDate,
					email: person.email
			}
			if (!isFetched) loadPersons();
			const res=await axios.post(configData.SERVER_URL+'/api/persons/', newPerson, { headers: authHeader() }) // http://localhost:8080/api/v1/persons/
			setPersons([...persons, res.data]);
			setSelectedPerson(res.data);//was newPerson
			return newPerson;
    	}
		catch(error) {console.log(error)}
	}// end addPerson

    let updatePerson = async person => {
		try{
			setSelectedPerson(person);
			const updatedPerson = {
					id: person.id,
					firstName: person.firstName,
					lastName: person.lastName,
					birthDate: person.birthDate,
					email: person.email
			}
			const result= await axios.put(configData.SERVER_URL+'/api/persons/' + person.id, updatedPerson, { headers: authHeader() })
			if (!isFetched) loadPersons();
			const fIndex= await persons.findIndex(x => x.id === person.id);
			let clonePersons = [...persons];
			clonePersons[fIndex] = updatedPerson;
			setPersons(clonePersons); //TODO => why setting all persons ?  when is this used ? ==> for lists ??? (I guess)
		}//try		
		catch(error) {console.log(error)}
	}
    
	let removePerson = async id => {
		try{
			if (!isFetched) loadPersons();
			const res = await axios.delete(configData.SERVER_URL+'/api/persons/' + id,{ headers: authHeader() } )
			setPersons(persons.filter(person => person.id !==id));
			setSelectedPerson(null);
			//setIsFetched(true); => gebeurt in loadPersons
		}
		catch(error) {
			console.log(error)
        }//catch
	}// end removePerson
	
	let loadPersons = async () => {
		try{
			const res = await axios.get(configData.SERVER_URL+'/api/persons/',{ headers: authHeader() });
			setPersons(await res.data);
			setIsFetched(true);
			return await res.data;
		}
		catch(error) {
			console.log(error);
			setPersons([]);
			setSelectedPerson(null);
			setIsFetched(false);
		}
	}// end loadPersons

	return {
		persons,
		isFetched,
		selectedPerson,
		selectAPerson,
		addPerson,
		updatePerson,
		removePerson,
		loadPersons
	};
}
export  const PersonsContainer = createContainer(usePersons);