import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Rating from '@mui/material/Rating';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';

const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
    color: theme.palette.action.disabled,
  },
}));

const customIcons = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon color="error" fontSize="large"/>,
    label: 'Absoluut mee oneens',
  },
  2: {
    icon: <SentimentDissatisfiedIcon color="error" fontSize="large"/>,
    label: 'Eerder oneens',
  },
  3: {
    icon: <SentimentSatisfiedIcon color="warning" fontSize="large"/>,
    label: 'Neutraal',
  },
  4: {
    icon: <SentimentSatisfiedAltIcon color="success" fontSize="large"/>,
    label: 'Eerder eens',
  },
  5: {
    icon: <SentimentVerySatisfiedIcon color="success" fontSize="large"/>,
    label: 'Helemaal mee eens',
  },
};

function IconContainer(props) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}

IconContainer.propTypes = {
  value: PropTypes.number.isRequired,
};

function RadioGroupRating(props) {
/**
    useEffect(() => {
        if (props.score){
            console.log(props.score);
        }
      }, []);
*/


  return (
    <StyledRating
      name="highlight-selected-only"
      value={props.score}
      onChange={props.verander}
      IconContainerComponent={IconContainer}
      getLabelText={(value) => customIcons[value].label}
      highlightSelectedOnly
      disabled={props.paused}
    />
  );
}

export default RadioGroupRating;