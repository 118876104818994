import { createContext, useReducer } from "react";

export const SurveySubjectContext = createContext(); // returns a new context objects


export const SurveySubjectReducer = (state, action) => {
    switch (action.type){
        case 'SET_SURVEYSUBJECT':
            return {...state, surveySubject: action.payload};
        case 'ADD_RESPONDENT':
            return {...state, surveySubject: {...state.surveySubject, respondents: [...state.surveySubject.respondents, action.payload]}};
        case 'REMOVE_RESPONDENT':
            return {...state, surveySubject: {...state.surveySubject, respondents: state.surveySubject.respondents.filter((resp)=> resp.id !== action.payload.id)}}
        case 'UPDATE_RESPONDENT':
            const _rspIndex = state.surveySubject.respondents.findIndex((r) => {return r.id === action.payload.id;});
            let _respondents = structuredClone(state.surveySubject.respondents);
            _respondents.splice(_rspIndex,1, action.payload);
            return {...state, surveySubject: {...state.surveySubject, respondents: _respondents}}
        default: 
            return state;
    }//switch
}

export function SurveySubjectProvider({children}){

    const [state, dispatch] = useReducer(SurveySubjectReducer, {surveySubject: {id:null, launchDate:null, subjectPerson:null, respondents:null}});

    const setCurrentSurveySubject = (surveySubjectObject) => {
        dispatch({type:'SET_SURVEYSUBJECT', payload: surveySubjectObject});
    }
    const addRespondentToSurveySubject = (respondent) => {
        dispatch({type:'ADD_RESPONDENT', payload:respondent});
    }
    const deleteRespondentFromSurveySubject = (respondent) => {
        dispatch({type:'REMOVE_RESPONDENT', payload:respondent});
    }
    const updateRespondentForSurveySubject = (respondent) => {
        dispatch({type:'UPDATE_RESPONDENT', payload:respondent});
    }

    return(
        <SurveySubjectContext.Provider value={{...state, setCurrentSurveySubject, addRespondentToSurveySubject, deleteRespondentFromSurveySubject, updateRespondentForSurveySubject}}>
            {children}
        </SurveySubjectContext.Provider>
    );
}