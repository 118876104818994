import { useEffect, useState } from "react";
import { useRespondents } from "./useRespondents";
import axios from 'axios';
//import { useAuthUser } from "./useAuthUser";
import configData from '../config/config.json';
import { useAuthContext } from "./useAuthContext";

export const useManageRespondent =  () => {
    const [isCancelled, setIsCancelled] = useState(false);
    const [error, setError] = useState(null);
    const [isPending, setIsPending] = useState(false);
    const {addRespondent, removeRespondent, updateRespondent, setRespondents} = useRespondents();
    //const {getUser} = useAuthUser();
    const {user} = useAuthContext();

    const sortRespondentsByRelationshipAndName = (respondents) => {
        return respondents.sort((a,b) => {
            const xR = a.relationship.toLowerCase();
            const yR = b.relationship.toLowerCase();
            const xN = a.respondentPerson.lastName.toLowerCase();
            const yN = b.respondentPerson.lastName.toLowerCase();
            return ((xR < yR) ? -1 : ((xR > yR) ? 1 : ((xN < yN) ? -1 : ((xN > yN) ? 1 : 0) )));
        })
    }

    const setRespondentsForSubjectId = async (surveySubjectId) => {
        setError(null);
        setIsPending(true);

        try{
            // fetch the subject from the db

            const res = await axios.get(configData.SERVER_URL+'/api/surveysubjects/'+surveySubjectId, {headers: { 'Authorization': 'Bearer ' + user.token}});
            // set the respondents in the state
            const sortedRespondents = await sortRespondentsByRelationshipAndName(res.data.respondents);
            setRespondents(await sortedRespondents.map((respondent) => ({
                    ...respondent,
//                    key: respondent.id,
//                    firstName: respondent.respondentPerson.firstName,
//                    lastName: respondent.respondentPerson.lastName,
//                    email: respondent.respondentPerson.email,
//                    relationship: respondent.relationship,
//                    status: respondent.status,
//                    responses: respondent.responses.length,
                    isSelected: false
            })) //map
            ); //setRespondents
            
            if (!isCancelled){
                setIsPending(false);
                setError(null);    
            }// if !isCancelled

        } catch (err){
            if (!isCancelled){
                setError(err.message);
                setIsPending(false);    
            } // if !isCancelled
        } // catch errors   
    }
    
    const add = async (firstName, lastName, email, relationship, subjectId) => {
        setError(null);
        setIsPending(true);

        //sign the user in
        try{
            email = email.replace('dpgmedia.be','persgroep.net');
            email = email.replace('dpgmedia.nl','persgroep.net');

            /**
             * contains 2 actions: 
             * 1.add the respondent to the DB (via the API)
             * 2. add the respondent to the application state*/
        
            // DB: create the person
            // TODO verify the existence of the person !!
            const newPerson = {
                    id: null,
                    firstName: firstName,
                    lastName: lastName,
                    birthDate: new Date(),
                    email: email
                }//newPerson
            
            //const checkedUser = await getUser();
            //const res=await axios.post(configData.SERVER_URL+'/api/persons/', newPerson, { headers: { 'Authorization': 'Bearer ' + checkedUser.token} }); // http://localhost:8080/api/v1/persons/
            const res=await axios.post(configData.SERVER_URL+'/api/persons/', newPerson, { headers: { 'Authorization': 'Bearer ' + user.token} });
            // DB: create the respondent
            const createdPerson = res.data;

            //const resSubject = await axios.get(configData.SERVER_URL+'/api/surveysubjects/'+subjectId,{ headers: { 'Authorization': 'Bearer ' + checkedUser.token} });
            const resSubject = await axios.get(configData.SERVER_URL+'/api/surveysubjects/'+subjectId,{ headers: { 'Authorization': 'Bearer ' + user.token} });
            const surveySubject = resSubject.data;

            const minimalRespondent = {
                //surveyId: selectedSurvey.id,
                //TODO: get the surveyId from the subject !!
                surveyId: surveySubject.surveyId,
                surveySubjectId: subjectId,
                respondentPersonId: createdPerson.id,
                launchDate: new Date(),
                relationship: relationship,
                status: "draft"
            }
            //const result =  await axios.post(configData.SERVER_URL + '/api/respondents/simplified',minimalRespondent,{ headers: { 'Authorization': 'Bearer ' + checkedUser.token} });
            const result =  await axios.post(configData.SERVER_URL + '/api/respondents/simplified',minimalRespondent,{ headers: { 'Authorization': 'Bearer ' + user.token} });
            const dbRespondent = result.data;
            /*const screenRespondent = {key: dbRespondent.id,
                                      firstName: dbRespondent.respondentPerson.firstName,
                                      lastName: dbRespondent.respondentPerson.lastName,
                                      //email: dbRespondent.respondentPerson.email,
                                      email: createdPerson.email,
                                      relationship: dbRespondent.relationship,
                                      status: dbRespondent.status,
                                      responses: dbRespondent.responses.length,
                                      isSelected:false
                                    }*/
            const screenRespondent = {...dbRespondent, isSelected:false}

            // change application state
            //dispatch({ type: 'ADD_RESPONDENT' , payload: screenRespondent}); //payload can be skipped
            //verify in tutorial if dispatch is used directly or not.
            //dispatch is not exposed ==> cannot be used directly
            addRespondent(screenRespondent);
            //update state
            if (!isCancelled){
                setIsPending(false);
                setError(null);    
            }

        } catch (err){
            if (!isCancelled){
                console.log(err.message);
                setError(err.message);
                setIsPending(false);    
            }
        }
    }//add function

    const remove = async(screenRespondent) => {
        setError(null);
        setIsPending(true);

        try{
            /**
             * contains 2 actions: 
             * 1.remove the respondent from the DB (via the API)
             * 2.remove the respondent from the application state*/
        
            // DB: remove the respondent
            // TODO keep the persons db clean
            const getFullRespondentFromDb = async (screenrespo) => {
                //const checkedUser = await getUser();
                //const rsp = await axios.get(configData.SERVER_URL+'/api/respondents/'+screenRespondent.key, {headers: { 'Authorization': 'Bearer ' + checkedUser.token} });
                const rsp = await axios.get(configData.SERVER_URL+'/api/respondents/'+screenRespondent.key, {headers: { 'Authorization': 'Bearer ' + user.token} });
                return rsp.data;
            }

            const removeRespondentFromDb = async (fullRespo) => {
                //const checkedUser = await getUser();
                //const rsp = await axios.delete(configData.SERVER_URL+'/api/respondents/'+fullRespo.id, {headers:{ 'Authorization': 'Bearer ' + checkedUser.token}});
                const rsp = await axios.delete(configData.SERVER_URL+'/api/respondents/'+fullRespo.id, {headers:{ 'Authorization': 'Bearer ' + user.token}});
                return (rsp.data);
            }


            const removePersonFromDb = async (pId, feedback) => {
                //const checkedUser = await getUser();
                //const persResult = await axios.delete(configData.SERVER_URL+'/api/persons/'+pId, {headers: { 'Authorization': 'Bearer ' + checkedUser.token}});
                const persResult = await axios.delete(configData.SERVER_URL+'/api/persons/'+pId, {headers: { 'Authorization': 'Bearer ' + user.token}});
                return persResult;
            }

            //console.log("first fetch full respondent (to get Person)");
            const fullRespondent = await getFullRespondentFromDb(screenRespondent);
            //console.log(fullRespondent);
            
            //console.log("removing respondent from database");
            const feedbackRemovedRespondent = await removeRespondentFromDb(fullRespondent);
            //console.log(feedbackRemovedRespondent);

            // change application state
           
            //dispatch({ type: 'ADD_RESPONDENT' , payload: screenRespondent}); //payload can be skipped
            //verify in tutorial if dispatch is used directly or not.
            //dispatch is not exposed ==> cannot be used directly
            removeRespondent(screenRespondent);
            //update state

            // remove person from db
            

            if (!isCancelled){
                setIsPending(false);
                setError(null);    
            }

        } catch (err){
            if (!isCancelled){
                console.log(err.message);
                setError(err.message);
                setIsPending(false);    
            }
        }
    }//remove function

    const invite = async(screenRespondent, googleToken) => {
        setError(null);
        setIsPending(true);
        
        try{
            /**
             * contains 2 actions: 
             * 1. send an invite to the respondent using the API & update the status in the DB
             * 2. adjust the status of the respondent at the front-end
             */

            const sendInvite = async(screenRespo) => {
                if (screenRespondent.status == "draft"){
                    /*const checkedUser = await getUser();
                    const rsp=await axios.post(configData.SERVER_URL+'/api/messages/invite/respondent/'+screenRespo.key, {googleToken: googleToken}, { headers: { 'Authorization': 'Bearer ' + checkedUser.token} });**/
                    const rsp=await axios.post(configData.SERVER_URL+'/api/messages/invite/respondent/'+screenRespo.key, {googleToken: googleToken}, { headers: { 'Authorization': 'Bearer ' + user.token} });
                    return rsp;
                }
                else {
                    console.log("status not draft, message not sent.  TODO: ask for confirmation");
                    return false;
                }
            }

            const getFullRespondentFromDb = async (respondentId) => {
                /*const checkedUser = await getUser();
                const rsp = await axios.get(configData.SERVER_URL+'/api/respondents/'+respondentId, {headers:{ 'Authorization': 'Bearer ' + checkedUser.token}});**/
                const rsp = await axios.get(configData.SERVER_URL+'/api/respondents/'+respondentId, {headers:{ 'Authorization': 'Bearer ' + user.token}});
                //console.log("full respondent (rsp.data):");
                //console.log(rsp.data);
                return rsp.data;
            }

            const updateRespondentInDb = async (fullRespo) => {
                let minimalRespondent = {
                    surveySubjectId: fullRespo.surveySubject.id,
                    respondentPersonId: fullRespo.respondentPerson.id,
                    launchDate: new Date(fullRespo.launchDate),
                    relationship: fullRespo.relationship,
                    status: 'invited'
                }
                /*const checkedUser = await getUser();
                const rsp = await axios.put(configData.SERVER_URL + '/api/respondents/simplified/' + fullRespo.id, minimalRespondent,{ headers: { 'Authorization': 'Bearer ' + checkedUser.token} });**/
                const rsp = await axios.put(configData.SERVER_URL + '/api/respondents/simplified/' + fullRespo.id, minimalRespondent,{ headers: { 'Authorization': 'Bearer ' + user.token} });
                //console.log(rsp.data);
                return (rsp.data);
            }

            // first try to send the invite
            //const inviteFeedback = sendInvite(await screenRespondent);
            const inviteFeedback = await sendInvite(screenRespondent);
            
            //if (await inviteFeedback === false ) {
            if ((inviteFeedback === false)||(inviteFeedback.status !== 200)  ) {
                //console.log("inviteFeedback = "+inviteFeedback);
                setError("there was an error sending the invite.  The respondent's status is not correct, the user gmail token has expired (reload the page), the user was not listed as an admin in the google cloud or another problem occured");           }
            else {
                //console.log("sendInvite result = ");
                //console.log(inviteFeedback);
                const fullRespondent = await getFullRespondentFromDb(screenRespondent.key);
                const updatedRespondent = await updateRespondentInDb(fullRespondent);
                // and finally update the application state
                const newScreenRespondent = 
                    {...screenRespondent, status: updatedRespondent.status, isSelected:false};
                //console.log("newScreenRespondent");
                //console.log(newScreenRespondent);
                updateRespondent(newScreenRespondent);
            }//else

            if (!isCancelled){
                setIsPending(false);
                setError(null);    
            }

        } catch (err){
            if (!isCancelled){
                console.log(err.message);
                setError(err.message);
                setIsPending(false);    
            }
        }
    }//invite function

    const remind = async(screenRespondent, googleToken) => {
        //console.log("entering remind in addRespondentHook");
        setError(null);
        setIsPending(true);
        
        try{
            /**
             * contains 2 actions: 
             * 1. send a reminder to the respondent using the API & update the status in the DB
             * 2. adjust the status of the respondent at the front-end
             */
            const sendReminder = async(screenRespo) => {
                if (screenRespondent.status == "invited" || screenRespondent.status=="reminded"){
                    /*const checkedUser = await getUser();
                    const rsp=await axios.post(configData.SERVER_URL+'/api/messages/remind/respondent/'+screenRespo.key, {googleToken: googleToken}, { headers: { 'Authorization': 'Bearer ' + checkedUser.token} });**/
                    const rsp=await axios.post(configData.SERVER_URL+'/api/messages/remind/respondent/'+screenRespo.key, {googleToken: googleToken}, { headers: { 'Authorization': 'Bearer ' + user.token} });
                    //console.log(await rsp);
                    return rsp;
                }
                else {
                    //console.log("status not invited, message not sent.  TODO: ask for confirmation");
                    return false;
                }
            }

            const getFullRespondentFromDb = async (respondentId) => {
                /*const checkedUser = await getUser();
                const rsp = await axios.get(configData.SERVER_URL+'/api/respondents/'+respondentId, {headers:{ 'Authorization': 'Bearer ' + checkedUser.token}});**/
                const rsp = await axios.get(configData.SERVER_URL+'/api/respondents/'+respondentId, {headers:{ 'Authorization': 'Bearer ' + user.token}});

                return rsp.data;
            }

            const updateRespondentInDb = async (fullRespo) => {
                let minimalRespondent = {
                    surveySubjectId: fullRespo.surveySubject.id,
                    respondentPersonId: fullRespo.respondentPerson.id,
                    launchDate: new Date(fullRespo.launchDate),
                    relationship: fullRespo.relationship,
                    status: 'reminded'
                }
                //TODO ==> how to add the latestreminderdate ? & why minimal when we have the full ?
                /*const checkedUser = await getUser();
                const rsp = await axios.put(configData.SERVER_URL + '/api/respondents/simplified/' + fullRespo.id, minimalRespondent,{ headers: { 'Authorization': 'Bearer ' + checkedUser.token} });**/
                const rsp = await axios.put(configData.SERVER_URL + '/api/respondents/simplified/' + fullRespo.id, minimalRespondent,{ headers: { 'Authorization': 'Bearer ' + user.token} });
                //console.log(rsp.data);
                return (rsp.data);
            }

            // first try to send the reminder
            //const inviteFeedback = sendReminder(await screenRespondent);
            const reminderFeedback = await sendReminder(screenRespondent);
            

            if ((reminderFeedback === false)||reminderFeedback.status!== 200 ) {
                //console.log("inviteFeedback = "+inviteFeedback);
                setError("there was an error sending the reminder.  The respondent's status is not correct, the user gmail token has expired (reload the page), the user was not listed as an admin in the google cloud or another problem occured");
            }
            else {
                const fullRespondent = await getFullRespondentFromDb(screenRespondent.key);
                const updatedRespondent = await updateRespondentInDb(fullRespondent);
                // and finally update the application state
                const newScreenRespondent = 
                    {...screenRespondent, status: updatedRespondent.status, isSelected:false};
                //console.log("newScreenRespondent");
                //console.log(newScreenRespondent);
                updateRespondent(newScreenRespondent);
            }//else
        
        if (!isCancelled){
            setIsPending(false);
            setError(null);    
        }

    } catch (err){
        if (!isCancelled){
            console.log(err.message);
            setError(err.message);
            setIsPending(false);    
        }
    }
}//remind function

const complete = async(screenRespondent) => {
    setError(null);
    setIsPending(true);
    
    try{
        /**
         * contains 2 actions: 
         * 1. update the status in the DB
         * 2. adjust the status of the respondent at the front-end
         */

        const getFullRespondentFromDb = async (respondentId) => {
            /*const checkedUser = await getUser();
            const rsp = await axios.get(configData.SERVER_URL+'/api/respondents/'+respondentId, {headers:{ 'Authorization': 'Bearer ' + checkedUser.token}});**/
            const rsp = await axios.get(configData.SERVER_URL+'/api/respondents/'+respondentId, {headers:{ 'Authorization': 'Bearer ' + user.token}});
            return rsp.data;
        }

        const updateRespondentInDb = async (fullRespo) => {
            let minimalRespondent = {
                surveySubjectId: fullRespo.surveySubject.id,
                respondentPersonId: fullRespo.respondentPerson.id,
                launchDate: new Date(fullRespo.launchDate),
                relationship: fullRespo.relationship,
                status: 'completed'
            }
            /*const checkedUser = await getUser();
            const rsp = await axios.put(configData.SERVER_URL + '/api/respondents/simplified/' + fullRespo.id, minimalRespondent,{ headers: { 'Authorization': 'Bearer ' + checkedUser.token} });**/
            const rsp = await axios.put(configData.SERVER_URL + '/api/respondents/simplified/' + fullRespo.id, minimalRespondent,{ headers: { 'Authorization': 'Bearer ' + user.token} });
            return (rsp.data);
        }

        const fullRespondent = await getFullRespondentFromDb(screenRespondent.key);
        const updatedRespondent = await updateRespondentInDb(fullRespondent);
        // and finally update the application state
        const newScreenRespondent = 
            {...screenRespondent, status: updatedRespondent.status, isSelected:false};
        updateRespondent(newScreenRespondent);
    
        if (!isCancelled){
            setIsPending(false);
            setError(null);    
        }

        } catch (err){
            if (!isCancelled){
                console.log(err.message);
                setError(err.message);
                setIsPending(false);    
            }
        }
    }//complete function

    const getRespondentFromDb = async(respondentId) => {
        /*const checkedUser = await getUser();
        const rsp = await axios.get(configData.SERVER_URL+'/api/respondents/'+respondentId, {headers:{ 'Authorization': 'Bearer ' + checkedUser.token}});**/
        const rsp = await axios.get(configData.SERVER_URL+'/api/respondents/'+respondentId, {headers:{ 'Authorization': 'Bearer ' + user.token}});
        return rsp.data;
    }
    const getRespondentsForPerson = async(personId) => {
        /**
        const checkedUser = await getUser();
        const result = await axios.get(configData.SERVER_URL+'/api/respondents/forPerson/'+personId, {headers:{ 'Authorization': 'Bearer ' + checkedUser.token}}); */
        const result = await axios.get(configData.SERVER_URL+'/api/respondents/forPerson/'+personId, {headers:{ 'Authorization': 'Bearer ' + user.token}}); 
        return result.data;
    }

    useEffect(()=> { 
        /** the returned function is fired when unmounting, 
         * in this case preventing state to be updated after unmounting (generates an error)
         */
        return () => setIsCancelled(true);
    }, []);

    return {setRespondentsForSubjectId, add, remove, invite, remind, complete, getRespondentFromDb, getRespondentsForPerson, error, isPending} ;
}