
import { Clear, ExitToApp, Save } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useState, useEffect } from "react";
import "./RespondentForm.css";

export default function RespondentForm({addRespondent, handleClose, updateRespondent, editableRespondent}){

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [relationship, setRelationship] = useState('');
    
    
    useEffect(() => {
        console.log("respondentForm-useEffect[]")
        if (editableRespondent) {
            setFirstName(editableRespondent.respondentPerson.firstName);
            setLastName(editableRespondent.respondentPerson.lastName);
            setEmail(editableRespondent.respondentPerson.email);
            setRelationship(editableRespondent.relationship);
        } //if editableRespondent is not null
    },[]);
    useEffect(() => {
        console.log("respondentForm-useEffect[editableRespondent]");
        if (editableRespondent){
            setFirstName(editableRespondent.respondentPerson.firstName);
            setLastName(editableRespondent.respondentPerson.lastName);
            setEmail(editableRespondent.respondentPerson.email);
            setRelationship(editableRespondent.relationship);
        }// if editableRespondent is not null
    }, [editableRespondent]);

    const resetForm = () => {
        setFirstName('');
        setLastName('');
        setEmail('');
        setRelationship('');
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const resp = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            relationship: relationship,
        }
        //issue: geen id ==> probleem om toe te voegen aan de lijst ???
        if (editableRespondent!==null && editableRespondent.id!==null){
            updateRespondent({...editableRespondent, respondentPerson:{id:editableRespondent.respondentPerson.id, firstName: firstName, lastName: lastName, email: email}, relationship: relationship});
        }
        else addRespondent(resp);
        /**
         * TODO
         * 1. hoe toevoegen zonder id ?
         * 2. data meekrijgen voor edit vs. nieuw
         * 3. plaatsing van de knoppen
         */
        //addRespondent(resp);
        resetForm();

    }

    return (
        <form className="edit-respondent-form" onSubmit={handleSubmit}>
            <label>
                <span>voornaam: </span>
                <input type="text" id="firstName" onChange={(e) => setFirstName(e.target.value)} value={firstName} />
            </label>
            <label>
                <span>naam: </span>
                <input type="text" id="lastName" onChange={(e) => setLastName(e.target.value)} value={lastName}/>
            </label>
            <label>
                <span>email: </span>
                <input type="text" id="email" onChange={(e) => setEmail(e.target.value)} value={email}/>
            </label>
            <label>
                <span>relatie: </span>
                <input type="text" id="relationship" onChange={(e) => setRelationship(e.target.value)} value={relationship}/>
            </label>
            <div className="bottomButtons">
                <div>
                    <Button
                        variant="outlined"
                        onClick={handleSubmit} 
                        startIcon={<Save />} >Bewaren
                    </Button>
                </div>
                <div>
                    <Button 
                        variant="outlined"
                        onClick={resetForm} 
                        startIcon={<Clear />} >Wissen
                    </Button>
                </div> 
                <div>
                    <Button 
                        variant="outlined"
                        onClick={handleClose} 
                        startIcon={<ExitToApp />} >Sluiten
                    </Button>
                </div>
            </div>
            <br/>
        </form>

    );
}