import React, {useEffect, useState, useRef} from "react";
import { Rating, Checkbox, TextField, TextareaAutosize } from "@mui/material";
import { useManageRespond } from "../../hooks/useManageRespond";
import RadioGroupRating from "./RadioGroupRating";
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { useTranslation } from "react-i18next";

const Question = (props) => {
    const {t} = useTranslation();
    const {createOrUpdateAnswer} = useManageRespond();
    
    const [response, setResponse] = useState(props.r);
    // TODO: replace state with hookInfo
    const [answerValue, setAnswerValue] = useState(null);
    const [commentValue, setCommentValue] = useState(null);
    const [showComments, setShowComments] = useState(props.r && props.r.textValue !== null);
    //let showComments = useRef(false);

    //2024-05-10
    const [onPause, setOnPause] = useState(false);

    function activateComments (e){
        if (!response){
            console.log("no response present");
            setResponse({
                questionId: props.q.questionId, 
                respondentId: props.respondent.id, 
                id: response ? response.id:null, 
                booleanValue: false, 
                ignored: response ? response.ignored:null, 
                textValue: response ? response.textValue:null, 
                intValue: response ? response.intValue: null});
        }
        setShowComments(true);
    }

    const handleAnswerChange = async (e, newValue) => {
        /** wat moet veranderen: 
         * disablen / read-only zetten tot de db-update binnen is
         */
        setOnPause(true);

        setAnswerValue(newValue);
        console.log("====== entering handleAnswerChange with value "+newValue);
        let newResponseObject = await createOrUpdateAnswer({questionId: props.q.questionId,
                              respondentId: props.respondent.id,
                              id: props.r ? props.r.responseId : null,
                              booleanValue: false,
                              ignored: false,
                              textValue: props.q.questionType.toString() === "1"? newValue: (response ? response.textValue : null),
                              intValue: (props.q.questionType.toString() === "2") ? (newValue!==null ? newValue: response.intValue ): null,
                            });
        console.log("setting response value to ");
        console.log (newResponseObject);
        setResponse(newResponseObject);
        // kick back when a question was answered to the component above ==> is dit nodig of kan het via de context ?
        props.answerGiven({questionId: props.q.questionId, 
                           intValue: props.q.questionType.toString() === "2" ? newValue: null, 
                           booleanValue: false, 
                           textValue: props.q.questionType.toString() === "1" ? newValue: null,
                           response: newResponseObject});
        setOnPause(false);
    }

    const handleAnswerClear = async (e) => {
        setOnPause(true);
        setAnswerValue(null);
        console.log("====== entering handleAnswerClear");
        let newResponseObject = await createOrUpdateAnswer({questionId: props.q.questionId,
                              respondentId: props.respondent.id,
                              id: props.r ? props.r.responseId : null,
                              booleanValue: false,
                              ignored: true,
                              textValue: response ? response.textValue: null,
                              intValue: null,
                            });
        console.log (newResponseObject);
        setResponse(newResponseObject);
        // kick back when a question was answered to the component above ==> is dit nodig of kan het via de context ?
        props.answerGiven({questionId: props.q.questionId, 
                           intValue: null, 
                           booleanValue: false, 
                           textValue: response ? response.textValue: null,
                           ignored: true,
                           response: newResponseObject});
        setOnPause(false);
    }

    const handleTextAnswerChange = async(e) => {
        console.log(e.target.value);
        handleAnswerChange(e, e.target.value);
    }

    const handleCommentChange = async (e, newValue) => {
        setCommentValue(e.target.value);
        let newResponseObject = await createOrUpdateAnswer({questionId: props.q.questionId,
                              respondentId: props.respondent.id,
                              id: props.r ? props.r.responseId : null,
                              booleanValue: false,
                              //ignored: props.r ? props.r.ignored: null,
                              ignored: response ? response.ignored: null,
                              textValue: (e.target.value.length > 0) ? e.target.value:null,
                              intValue: response.intValue ? response.intValue: null,
                            });
        setResponse(newResponseObject);
        // kick back when a question was answered to the component above ==> is dit nodig of kan het via de context ?
        props.answerGiven({questionId: props.q.questionId, 
                           intValue: response.intValue ? response.intValue: null, 
                           booleanValue: false, 
                           ignored: response ? response.ignored: null,
                           textValue: e.target.value,
                           response: newResponseObject});
        if (e.target.value.length === 0) setShowComments(false);
    }

    useEffect(() => {
        if (response){ 
            switch (props.q.questionType.toString()){
                case "1": setAnswerValue(response.textValue ? response.textValue : ""); break;
                case "2": setAnswerValue(response.intValue); break;
                default: setAnswerValue(null);
            }
        }
        else if (props.r){
            switch (props.q.questionType.toString()){
                case "1": setAnswerValue(props.r.textValue ? props.r.textValue : ""); break;
                case "2": setAnswerValue(props.r.intValue); break;
                default:  setAnswerValue(null);
            }
            setResponse(props.r);
        }
        console.log("current value for showComments = " + showComments);
      }, [response, answerValue]);//answerValue toegevoegd 22-06-06 (//serverImport)

    return (
        <React.Fragment>
        {props.q.questionType.toString() === "1" &&
            <div className="questionContainer">
                <div className="questionCard">
                    <div className="questionCardLeft">
                        {props.q.questionText}<br/>
                        <TextareaAutosize id={`qId_${props.q.questionId}`} minRows={4} style={{width: "95%"}} onBlur={handleTextAnswerChange} defaultValue={answerValue} />
                    </div>
                </div>
            </div>
        }

        {props.q.questionType.toString() === "2" && 
            <div className="questionContainer">
                <div className="questionCard">
                    <div className="questionText">{props.q.questionText}</div>
                    <div className="questionScoring" >{/** disable this div when needed */}
                        <RadioGroupRating 
                            name={`qId_${props.q.questionId}`} 
                            score={answerValue} 
                            verander={handleAnswerChange}
                            paused={onPause}
                        />{/** geef aan de radioGroupRating een boolean door (state) die disablet / enabled */}
                        <div hidden={!onPause}>updating...</div>
                    </div>
                    <div className="questionResponse">
                        <div className="questionIgnoreCheckBox">
                            <Checkbox 
                                checked={(answerValue===0)}
                                onChange={handleAnswerClear} // if this doesn't work ==> anonymous function of answerClear ?
                            />
                            {t('I cannot evaluate this')}{/*ik kan hier niet over oordelen - je ne peux pas juger  - I cannot evaluate this*/}
                        </div>
                        <div className="questionCommentButton">{ !showComments && <ChatBubbleOutlineIcon onClick={activateComments}/>}</div>
                    </div> {/**questionResponse */}
                    <div>
                        {showComments && 
                            <div className="questionComment">
                                Commentaar<br/>
                                <TextareaAutosize id={`qId_${props.q.questionId}`} minRows={2} style={{width: "50%"}} onBlur={handleCommentChange} defaultValue={response.textValue} />
                            </div>
                        }
                    </div>
                </div>
            </div>
        }
         {props.q.questionType.toString() === "9" && 
            <div className="questionContainer">
                <div className="justTextCard">
                    <div className="questionText">{props.q.questionText}</div>
                </div>
            </div>
        }
        </React.Fragment>
        // other question types
	);
}
export default Question;