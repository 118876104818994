import { createContext, useReducer } from "react";

export const RespondContext = createContext(); // returns a new context objects

export const respondReducer = (state, action) => {
    switch (action.type){
        case 'INITIALIZE':
            /**
             * payload = respondent, subject, survey
             * also set the totalQuestions, respondedQuestions and questions[]
             * currentPage can remain at 1
             *  */ 
            let qList = [];
            let aCount = 0;

            const pages = JSON.parse(action.payload.survey.configFile).pages;
            //action.payload.survey.pages.forEach((page) => {
            pages.forEach((page) => {
                page.questions.forEach((question) => {
                    // do 2 things: 
                    // 1. add the question to the questionList
                    // 2. check if the question is answered and add to the count of answered questions
                    qList.push(question);
                    let response = action.payload.respondent.responses.find(r => r.questionId === question.questionId);
                    if (response) aCount++;
                    // ? room for optimization: add the answer to the question in the initialization ?
                }); // forEach question
              }); // forEach page
            const _numberOfQuestionsToAnswer = qList.filter((q) => q.questionType !== 9).length;
            
            return { ...state, 
                        respondent: action.payload.respondent, 
                        survey: action.payload.survey, 
                        questions: qList, 
                        numberOfQuestionsToAnswer : _numberOfQuestionsToAnswer,
                        respondedQuestions: aCount, //doordad aCount kan verlaat zijn ?
                        canFinalize: (aCount === _numberOfQuestionsToAnswer), 
                        isUnfinished: (action.payload.respondent.status !== 'completed')};

        case 'ANSWER_QUESTION':
            let updatedResponses = state.respondent.responses.filter(response => response.questionId !== action.payload.questionId).concat(action.payload); // can this be done with empty array ?
            let resp = {...state.respondent, responses: updatedResponses};
            /**if (resp.responses.length > state.respondent.responses.length) return {...state, respondent: resp, canFinalize: (resp.responses.length === state.questions.length)};
            else return {...state, respondent: resp}
            //TODO: verify if this if else construction is needed ? impact on refresh*/
            //return {...state, respondent: resp, canFinalize: (resp.responses.length === state.questions.length)};
            return {...state, respondent: resp, canFinalize: (updatedResponses.length === state.numberOfQuestionsToAnswer)};

        case 'MOVE_PAGE_LEFT':
            let newLeftPageNumber = state.currentPageNumber - 1;
            if (newLeftPageNumber < 0) newLeftPageNumber = 0;
            return {...state, currentPageNumber:newLeftPageNumber}

        case 'MOVE_PAGE_RIGHT':
            let newRightPageNumber = state.currentPageNumber + 1;
            const surveyPages = JSON.parse(state.survey.configFile).pages;
            if (newRightPageNumber > (surveyPages.length-1)) newRightPageNumber = (surveyPages.length-1);
            return {...state, currentPageNumber:newRightPageNumber}

        case 'FINALIZE':
            let rsp = {...state.respondent, status: 'completed'}
            return {...state, respondent: rsp, isUnfinished:false}; //assuming persistent state was adjusted
        
        default: 
            return state;
    }//switch
}

export function RespondProvider({children}){

    const [state, dispatch] = useReducer(respondReducer, {respondent:null, survey:null, respondedQuestions:0, currentPageNumber:0, questions:[], numberOfQuestionsToAnswer: 0, canFinalize: false, isUnfinished: true});
    /** useReducer
     * 1st argument = name of the reducer function
     * 2nd argument = initial state we want to use
     */

    const initialize = (respondent, survey) => {
        dispatch({type: 'INITIALIZE', payload: {respondent:respondent, survey:survey}}); //this is the action object (2nd argument of the reducer)
    }

    const answerQuestion = (persistedResponse) => {
        dispatch({type: 'ANSWER_QUESTION', payload: persistedResponse})
    }

    const movePageLeft = () => {
        dispatch({type:'MOVE_PAGE_LEFT'});
    }

    const movePageRight = () => {
        dispatch({type:'MOVE_PAGE_RIGHT'});
    }

    const finalize = () => {
        dispatch({type:'FINALIZE'});
    }

    return(
        <RespondContext.Provider value={{...state, initialize,answerQuestion, movePageLeft, movePageRight, finalize}} >
            {children}
        </RespondContext.Provider>
    );
}

/** <SubjectContext.Provider value = {subject}> */