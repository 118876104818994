import { useEffect, useState } from "react";
import { useAuthContext } from "./useAuthContext";
//import AuthService from "../services/auth.service";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const useLogout =  () => {
    const [isCancelled, setIsCancelled] = useState(false);
    const [error, setError] = useState(null);
    const [isPending, setIsPending] = useState(false);
    const {dispatch} = useAuthContext();
    //const authService = new AuthService();
    const history = useHistory();
    
    /**
     * OLD LOGOUT FUNCTION
     * 
     * const logout = async () => {
        //console.log("entering logout in hook");
        setError(null);
        setIsPending(true);

        //sign the user out
        try{
            // contains 2 actions: 
            // perform logout from API service && perform logout from local application state
            await authService.logout();
            // dispatch logout action
            dispatch({ type: 'LOGOUT' }); //payload can be skipped
            history.push('/');

            //update state
            if (!isCancelled){
                setIsPending(false);
                setError(null);    
            }

        } catch (err){
            if (!isCancelled){
                console.log(err.message);
                setError(err.message);
                setIsPending(false);    
            }
        }
    }//logout function
    */

    const logout = async () => {
        //console.log("entering logout in hook");
        setError(null);
        setIsPending(true);

        //sign the user out
        try{
            /* contains 2 actions: 
            perform logout from API service && perform logout from local application state*/
            //await authService.logout();  NEW VERSION ==> NO LOGOUT REQUIRED
            
            // dispatch logout action
            dispatch({ type: 'LOGOUT' }); //payload can be skipped
            history.push('/');// no need to push history

            //update state
            if (!isCancelled){
                setIsPending(false);
                setError(null);    
            }

        } catch (err){
            if (!isCancelled){
                console.log(err.message);
                setError(err.message);
                setIsPending(false);    
            }
        }
    }//logout function

    useEffect(()=> { // is fired when unmounting, preventing state to be updated after unmounting (generates an error)
        return () => setIsCancelled(true);
    }, []);

    //return {logout, newLogout, error, isPending} ;
    return {logout, error, isPending} ;
}