import { useEffect, useState } from "react";
import { useRespondents } from "./useRespondents";
import axios from 'axios';
//import { useAuthUser } from "./useAuthUser";
import configData from '../config/config.json';
import { useAuthContext } from "./useAuthContext";
//import { useManageSurveySubjects } from "./useManageSurveySubjects";
//import { useManageSurvey } from "./useManageSurvey";
//import { useManageRespondent } from "./useManageRespondent";

export const useManageTaskList =  () => {
    const [isCancelled, setIsCancelled] = useState(false);
    const [error, setError] = useState(null);
    const [isPending, setIsPending] = useState(false);
    const {addRespondent, removeRespondent, updateRespondent, setRespondents} = useRespondents();
    //const {getUser} = useAuthUser();
    const {user} = useAuthContext();
    
    /*const {getSurveySubjectsForPerson, getSurveySubjectForReportFromDb} = useManageSurveySubjects();
    const {setAndGetAllSurveys} = useManageSurvey();
    const {getRespondentsForPerson} = useManageRespondent();
    */

    const getTaskList = async () => {
        //const checkedUser = await getUser();
        //const res = await axios.get(configData.SERVER_URL+'/api/persons/'+checkedUser.person.id+'/taskList', {headers: { 'Authorization': 'Bearer ' + checkedUser.token}});
        if (user) {
            const res = await axios.get(configData.SERVER_URL+'/api/persons/'+user.person.id+'/taskList', {headers: { 'Authorization': 'Bearer ' + user.token}});
            console.log("getting tasks for "+user.firstName+" : ");
            console.log(res.data.tasks);
            return res.data.tasks;
        }
        else {
            console.log("getting tasks for NO USER, returning empty array");
            return [];
        }
    }

    useEffect(()=> { 
        getTaskList();
        // the returned function is fired when unmounting, 
        // in this case preventing state to be updated after unmounting (generates an error)
        return () => setIsCancelled(true);
    }, [user]);

    return {getTaskList, error, isPending} ;
}