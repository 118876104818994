import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import { useState, useRef, useEffect } from "react";
import ReactQuill from "react-quill";
import {
  Button,
  Grid,
  TextField,
  Tooltip,
} from "@mui/material";
import {
  AddCircleOutline,
  ArrowCircleDownOutlined,
  ArrowCircleUpOutlined,
  HighlightOffOutlined,
} from "@mui/icons-material";
import SurveyQuestion from "./survey-pages-question-component";
import { useManageSurvey } from "../../hooks/useManageSurvey";
import ClusterItemComponent from "./cluster-item-component";
import uuidv4 from "../../utilities/basicUtilities";

const SurveyClusterComponent = ({cluster,changeCluster,index, updateCluster, moveUp, moveDown, remove, isLast}) => {
  //const runCount = useRef(0);
  const [myCluster, setMyCluster] = useState(cluster);
  const [newSubClusterText, setNewSubClusterText] = useState("");


  useEffect(()=> {
    //console.log("CLUSTER COMPONENT useEffect name changed to "+clusterName);
    setMyCluster(cluster);
    //setMyTextValue(name);
  },[cluster]);

/*  useEffect(() => {
    console.log("survey-cluster-component useEffect with runCount = "+runCount.current);
    runCount.current = runCount.current + 1;
    if (runCount.current > 0){
        console.log("survey-cluster-component useEffect with runCount above 1, changing cluster in top-level component");

        //changeCluster(myCluster);
    }
  },[myCluster])
*/
  const handleUpdateCluster = (id, newValue, parentId) => {
    console.log("HANDLING UPDATE CLUSTER at cluster-component");
    if (parentId !== null){
        console.log("HANDLING UPDATING SUBCLUSTER");
        let _myCluster = structuredClone(myCluster);
        let locator = _myCluster.subClusters.length;
        _myCluster.subClusters.forEach((subCluster,index) => {
            if (subCluster.id === id){ locator=index;}
        });
        if (locator < myCluster.subClusters.length){
            const movingPart = _myCluster.subClusters.splice(locator,1)[0];
            const newCluster = {id:movingPart.id, naam:newValue, questions:[]};
            _myCluster.subClusters.splice(locator,0,newCluster);
            setMyCluster(_myCluster);
        }
        changeCluster(_myCluster);
    }
    else{
        console.log("HANDLING TOP LEVEL CLUSTER CHANGE, KICKING UP");
        updateCluster(id, newValue, null);
    }
  }

  const handleAddSubCluster = () => {
    let _myCluster = structuredClone(myCluster);
    _myCluster.subClusters.push({ id: uuidv4(), "naam": newSubClusterText, "questions": []}); 
    setMyCluster(_myCluster);
    setNewSubClusterText("");
    changeCluster(_myCluster);
    };

  const handleChangeNewSubCluster = (newValue) => {
    console.log("modified subcluster value to " + newValue);
    setNewSubClusterText(newValue);
  }

  const handleMoveUp = (id, parentId) => {
    if (parentId !== null){
        console.log("HANDLING MOVING SUBCLUSTER UP");
        let _myCluster = structuredClone(myCluster);
        let locator = _myCluster.subClusters.length;
        _myCluster.subClusters.forEach((subCluster,index) => {
            if (subCluster.id === id){ locator=index;}
        });
        if (locator !== 0){
            const movingPart = _myCluster.subClusters.splice(locator,1)[0];
            _myCluster.subClusters.splice(locator-1,0,movingPart);
            setMyCluster(_myCluster);
            changeCluster(_myCluster);
        }
    }
    else{
        moveUp(id,null);
    }

  }
  const handleMoveDown = (id, parentId) => {
    if (parentId !== null){
        console.log("HANDLING MOVING SUBCLUSTER DOWN");
        let _myCluster = structuredClone(myCluster);
        let locator = _myCluster.subClusters.length;
        _myCluster.subClusters.forEach((subCluster,index) => {
            if (subCluster.id === id){ locator=index;}
        });
        if (locator < myCluster.subClusters.length-1){
            const movingPart = _myCluster.subClusters.splice(locator,1)[0];
            _myCluster.subClusters.splice(locator+1,0,movingPart);
            setMyCluster(_myCluster);
            changeCluster(_myCluster);
        }
    }
    else{
        moveDown(id,null);
    }
  }

  const handleRemoveCluster = (id, parentId) => {
    if (parentId !== null){
        console.log("HANDLING REMOVING SUBCLUSTER");
        let _myCluster = structuredClone(myCluster);
        let locator = _myCluster.subClusters.length;
        _myCluster.subClusters.forEach((subCluster,index) => {
            if (subCluster.id === id){ locator=index;}
        });
        if (locator < myCluster.subClusters.length){
            const movingPart = _myCluster.subClusters.splice(locator,1)[0];
            //_myCluster.subClusters.splice(locator+1,0,movingPart);
            setMyCluster(_myCluster)
            changeCluster(_myCluster);
        }
    }
    else{
        remove(id, null);
    }
  }
  
/*  const uuidv4 = () => {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
      (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
    );
  }*/

  return (<
    div>
        {myCluster && 
            <Grid container spacing={2}>
            
                <Grid item xs={5} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <ClusterItemComponent id={myCluster.id} name={myCluster.naam} updateCluster={handleUpdateCluster} removeCluster={handleRemoveCluster} moveUp={handleMoveUp} moveDown={handleMoveDown} parentId={null} index={index} isLast={isLast} />
                </Grid>
                
                
                <Grid item xs={5}>            
                    {myCluster.subClusters && 
                        myCluster.subClusters.map((subCluster, subClusterIndex) => (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <ClusterItemComponent id={subCluster.id} name={subCluster.naam} updateCluster={handleUpdateCluster} removeCluster={handleRemoveCluster} moveUp={handleMoveUp} moveDown={handleMoveDown} parentId={myCluster.id} index={subClusterIndex} isLast={subClusterIndex===(myCluster.subClusters.length-1)}/>
                        </div>
                        ))
                    }
                    <Grid container spacing={2} style={{padding:"15px"}}>
                        <Grid item xs={8} style={{ padding: "15px", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <TextField fullWidth size="small" label="nieuwe subcluster" value={newSubClusterText} onChange = {(e) => handleChangeNewSubCluster(e.target.value)}/>
                        </Grid>
                        <Grid item xs={4}>
                        <Button variant="outlined" color="secondary" onClick={() => handleAddSubCluster(cluster, 0)} startIcon={<AddCircleOutline />}>subcluster toevoegen</Button>
                        </Grid>
                    </Grid>

                </Grid>
        
            </Grid>    
        }

    </div>
  );
};
export default SurveyClusterComponent;
