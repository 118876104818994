import { useContext } from "react";
import { SurveySubjectContext } from "../context/SurveySubjectContext";

export const useSurveySubject = () => {
    const context = useContext(SurveySubjectContext);

    if (context === undefined){
        throw new Error("useSurveySubject() must be used inside a SurveySubjectProvider");
    }

    return context;
}