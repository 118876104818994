import React, { useEffect, useState} from "react";
import { useAuthContext } from "../../hooks/useAuthContext";
//import { useAuthUser } from "../../hooks/useAuthUser";
//import { useManageSurveySubjects } from "../../hooks/useManageSurveySubjects";
//import { useManageSurvey } from "../../hooks/useManageSurvey";
import { useManageTaskList } from "../../hooks/useManageTaskList";
import { useTranslation } from "react-i18next";

const ProfilePage = () => {

    const {t} = useTranslation();

    const { user }= useAuthContext();
    const {getTaskList, getTasks} = useManageTaskList();

    const [tasks, setTasks] = useState(null);
    const [isFirstFetch, setIsFirstFetch] = useState(true);
    const [fetchAgain, setFetchAgain] = useState(false);

    useEffect(()=> {
        const getUserEtc = async () => {
            setTasks(await getTaskList());
        }
        
        getUserEtc();

    }, []);

    return(
        <div>
            {user && 
                <p>
                    {t('welcome')} {user.username}
                </p>
            }
            {!user && <p>{t('no user found')}</p>}
            
            {tasks && (tasks.length > 0) &&
                <table>
                    <thead>
                        <tr>
                            <th>{tasks.length}</th><th>{t('actions')}</th>
                        </tr>
                    </thead>
                    <tbody>
                    {tasks.map(task => (
                        <tr key={task.id}>
                            <td>{task.description}</td>
                            <td>
                                {(task.actionable === true) && 
                                    <a href={task.href}>
                                        {(task.taskType === 'surveySubject') && <React.Fragment>{t('open report')}</React.Fragment>}
                                        {(task.taskType === 'respondent') && <React.Fragment>{t('respond/finalize')}</React.Fragment>}
                                    </a>
                                }
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table> 
            }
            {tasks && (tasks.length === 0) &&
                <p>{t('no active tasks')}</p>
            }
            {(!tasks) &&
                <p>{t('loading...')}</p>
            }

        </div>
    );

}
export default ProfilePage;
/**
 * TODO
 * 1. list tasks on this page
 * 2. replace token with authContext information
 */