import { useEffect, useState } from "react";
import { useSurvey } from "./useSurvey";
import axios from 'axios';
import configData from '../config/config.json';

import { useAuthContext } from "./useAuthContext";
//import { useAuthUser } from "./useAuthUser";


export const useManageSurvey =  () => {

    //const {getUser} = useAuthUser();
    const { user }= useAuthContext();

    const [isCancelled, setIsCancelled] = useState(false);
    const [error, setError] = useState(null);
    const [isPending, setIsPending] = useState(false);
    
    const {updateInvite, updateReminder, updateIntro, updateOutro, updateInstructions, updateConfigFile, updateSurveyName, setCurrentSurvey, addSubjectToSurvey, deleteSubjectFromSurvey, updateSurveySubjectForSurvey, survey} = useSurvey(); // AVAILABLE: changeSurveyId, addSurveySubject, removeSurveySubject, setSurveySubjects
    
    const setSurveyById = async (surveyId) => {
        setError(null);
        setIsPending(true);

        try{
            //fetch the survey from the db
            /*const checkedUser = await getUser();
            const res = await axios.get(configData.SERVER_URL+'/api/surveys/'+surveyId, {headers: { 'Authorization': 'Bearer ' + checkedUser.token}});*/
            const res = await axios.get(configData.SERVER_URL+'/api/surveys/'+surveyId, {headers: { 'Authorization': 'Bearer ' + user.token}});

            //adjust the state
            //console.log("fetched survey from db");
            //console.log(res.data);
            setCurrentSurvey(res.data);

            if (!isCancelled){
                setIsPending(false);
                setError(null);    
            }// if !isCancelled
            return res.data;

        }//try
        catch (err){
            if (!isCancelled){
                console.log(err.message);
                setError(err.message);
                setIsPending(false);    
            } // if !isCancelled
        } // catch errors   
    }//setMessagesForSurveyId
    
    const changeInviteMessage = async (surveyId, msg) => {
        setError(null);
        setIsPending(true);

        try{
            //UPDATE DB
            /*const checkedUser = await getUser();
            const res= await axios.get(configData.SERVER_URL+'/api/surveys/'+surveyId, {headers: { 'Authorization': 'Bearer ' + checkedUser.token}});*/
            const res= await axios.get(configData.SERVER_URL+'/api/surveys/'+surveyId, {headers: { 'Authorization': 'Bearer ' + user.token}});
            //console.log("updating invite message for survey");
            //console.log(res.data);
            const updatedSurvey = {...res.data, respondentInviteMessage: msg};
            /*
            const feedback = await axios.put(configData.SERVER_URL+'/api/surveys/'+surveyId, updatedSurvey, {headers: { 'Authorization': 'Bearer ' + checkedUser.token}});*/
            const feedback = await axios.put(configData.SERVER_URL+'/api/surveys/'+surveyId, updatedSurvey, {headers: { 'Authorization': 'Bearer ' + user.token}});
            
            const adjustedSurvey = feedback.data;
            //UPDATE VIEW STATE
            updateInvite(adjustedSurvey.respondentInviteMessage);
            if (!isCancelled){
                setIsPending(false);
                setError(null);
            }
        }
        catch (err){
            console.log(err.message);
            setError(err.message);
            setIsPending(false);  
        }
    }

    const changeReminderMessage = async (surveyId, msg) => {
        setError(null);
        setIsPending(true);

        try{
            //UPDATE DB
/*            const checkedUser = await getUser();
            const res= await axios.get(configData.SERVER_URL+'/api/surveys/'+surveyId, {headers: { 'Authorization': 'Bearer ' + checkedUser.token}});*/
            const res= await axios.get(configData.SERVER_URL+'/api/surveys/'+surveyId, {headers: { 'Authorization': 'Bearer ' + user.token}});
            const updatedSurvey = {...res.data, respondentReminderMessage: msg};
            /*
            const feedback = await axios.put(configData.SERVER_URL+'/api/surveys/'+surveyId, updatedSurvey, {headers: { 'Authorization': 'Bearer ' + checkedUser.token}});*/
            const feedback = await axios.put(configData.SERVER_URL+'/api/surveys/'+surveyId, updatedSurvey, {headers: { 'Authorization': 'Bearer ' + user.token}});
            const adjustedSurvey = feedback.data;
            //UPDATE VIEW STATE
            updateReminder(adjustedSurvey.respondentReminderMessage);
            if (!isCancelled){
                setIsPending(false);
                setError(null);
            }
        }
        catch (err){
            console.log(err.message);
            setError(err.message);
            setIsPending(false);  
        }
    }

    const changeIntro = async(surveyId, msg) => {
        setError(null);
        setIsPending(true);
        try{
            //UPDATE DB
            /*
            const checkedUser = await getUser();
            const res = await axios.get(configData.SERVER_URL+'/api/surveys/'+surveyId,{headers: { 'Authorization': 'Bearer ' + checkedUser.token}});*/
            const res = await axios.get(configData.SERVER_URL+'/api/surveys/'+surveyId,{headers: { 'Authorization': 'Bearer ' + user.token}});
            const updatedSurvey = {...res.data, intro: msg};
            /*
            const feedback = await axios.put(configData.SERVER_URL+'/api/surveys/'+surveyId, updatedSurvey, {headers: { 'Authorization': 'Bearer ' + checkedUser.token}});*/
            const feedback = await axios.put(configData.SERVER_URL+'/api/surveys/'+surveyId, updatedSurvey, {headers: { 'Authorization': 'Bearer ' + user.token}});

            const adjustedSurvey = feedback.data;
            //UPDATE VIEW STATE
            updateIntro(adjustedSurvey.intro);
            if (!isCancelled){
                setIsPending(false);
                setError(null);
            }
        }
        catch(err){
            console.log(err.message);
            setError(err.message);
            setIsPending(false);
        }
    }
    const changeOutro = async(surveyId, msg) => {
        setError(null);setIsPending(true);
        try{
            //UPDATE DB
            /*
            const checkedUser = await getUser();
            const res = await axios.get(configData.SERVER_URL+'/api/surveys/'+surveyId,{headers: { 'Authorization': 'Bearer ' + checkedUser.token}});*/
            const res = await axios.get(configData.SERVER_URL+'/api/surveys/'+surveyId,{headers: { 'Authorization': 'Bearer ' + user.token}});
            const updatedSurvey = {...res.data, outro: msg};
            /*
            const feedback = await axios.put(configData.SERVER_URL+'/api/surveys/'+surveyId, updatedSurvey, {headers: { 'Authorization': 'Bearer ' + checkedUser.token}});*/
            const feedback = await axios.put(configData.SERVER_URL+'/api/surveys/'+surveyId, updatedSurvey, {headers: { 'Authorization': 'Bearer ' + user.token}});
            const adjustedSurvey = feedback.data;
            //UPDATE VIEW STATE
            updateOutro(adjustedSurvey.outro);
            if (!isCancelled){
                setIsPending(false);
                setError(null);
            }
        }
        catch(err){
            console.log(err.message);
            setError(err.message);
            setIsPending(false);
        }
    }
    const changeInstructions = async(surveyId, msg) => {
        setError(null);
        setIsPending(true);
        try{
            //UPDATE DB
/*            const checkedUser = await getUser();
            const res = await axios.get(configData.SERVER_URL+'/api/surveys/'+surveyId,{headers: { 'Authorization': 'Bearer ' + checkedUser.token}});*/
            const res = await axios.get(configData.SERVER_URL+'/api/surveys/'+surveyId,{headers: { 'Authorization': 'Bearer ' + user.token}});
            const updatedSurvey = {...res.data, instructions: msg};
            /*
            const feedback = await axios.put(configData.SERVER_URL+'/api/surveys/'+surveyId, updatedSurvey, {headers: { 'Authorization': 'Bearer ' + checkedUser.token}});*/
            const feedback = await axios.put(configData.SERVER_URL+'/api/surveys/'+surveyId, updatedSurvey, {headers: { 'Authorization': 'Bearer ' + user.token}});
            const adjustedSurvey = feedback.data;
            //UPDATE VIEW STATE
            updateInstructions(adjustedSurvey.instructions);
            if (!isCancelled){
                setIsPending(false);
                setError(null);
            }
        }
        catch(err){
            console.log(err.message);
            setError(err.message);
            setIsPending(false);
        }
    }


    const changeConfigFile = async (surveyId, msg) => {
        setError(null);
        setIsPending(true);

        try{
            //UPDATE DB
            /*const checkedUser = await getUser();
            const res= await axios.get(configData.SERVER_URL+'/api/surveys/'+surveyId, {headers: { 'Authorization': 'Bearer ' + checkedUser.token}});*/
            //console.log("updating config file with file = "+msg);
            //console.log(configData.SERVER_URL+'/api/surveys/'+surveyId);
            const res= await axios.get(configData.SERVER_URL+'/api/surveys/'+surveyId, {headers: { 'Authorization': 'Bearer ' + user.token}});
            //console.log(res.configFile);
            const updatedSurvey = {...res.data, configFile: msg};
            //console.log("putting away changes");
            /*
            const feedback = await axios.put(configData.SERVER_URL+'/api/surveys/'+surveyId, updatedSurvey, {headers: { 'Authorization': 'Bearer ' + checkedUser.token}});*/
            const feedback = await axios.put(configData.SERVER_URL+'/api/surveys/'+surveyId, updatedSurvey, {headers: { 'Authorization': 'Bearer ' + user.token}});
            const adjustedSurvey = feedback.data;
            //UPDATE VIEW STATE
            updateConfigFile(adjustedSurvey.configFile);
            if (!isCancelled){
                setIsPending(false);
                setError(null);
            }
        }
        catch (err){
            console.log(err.message);
            setError(err.message);
            setIsPending(false);  
        }
    }
    
    const changeSurveyName = async (surveyId, msg) => {
        setError(null);
        setIsPending(true);

        try{
            //UPDATE DB
            /*const checkedUser = await getUser();
            const res= await axios.get(configData.SERVER_URL+'/api/surveys/'+surveyId, {headers: { 'Authorization': 'Bearer ' + checkedUser.token}});*/
            //console.log("updating survey name with = "+msg);
            const res= await axios.get(configData.SERVER_URL+'/api/surveys/'+surveyId, {headers: { 'Authorization': 'Bearer ' + user.token}});
            const oldConfigFile = await JSON.parse(res.data.configFile);
            const updatedConfigFile = await JSON.stringify({...oldConfigFile, name: msg});
            const updatedSurvey = {...res.data, name: msg, configFile: updatedConfigFile}; //updating the name in the db name field AND in the configFile field

            /*
            const feedback = await axios.put(configData.SERVER_URL+'/api/surveys/'+surveyId, updatedSurvey, {headers: { 'Authorization': 'Bearer ' + checkedUser.token}});*/
            const feedback = await axios.put(configData.SERVER_URL+'/api/surveys/'+surveyId, updatedSurvey, {headers: { 'Authorization': 'Bearer ' + user.token}});
            const adjustedSurvey = feedback.data;

            //UPDATE VIEW STATE
            updateSurveyName(adjustedSurvey.name);
            if (!isCancelled){
                setIsPending(false);
                setError(null);
            }
        }
        catch (err){
            console.log(err.message);
            setError(err.message);
            setIsPending(false);  
        }
    }

    const addSurveySubject = async (minimalSubject) => {
        setError(null);
        setIsPending(true);
        console.log("even verifiëren of er al een survey gekend zou zijn ???");
        console.log(survey.name);


        try{
             /**
             * contains 2 actions: 
             * 1.add the surveySubject to the DB (via the API)
             * 2. add the surveySubject to the application state*/

            // DB: create the person
            // TODO verify the existence of the person !!

            //UPDATE DB = add the surveySubject to the DB first
            //console.log("this is where the surveySubject is expected to be added to the db");

            let improvedEmail = minimalSubject.email.replace('dpgmedia.be','persgroep.net');
            improvedEmail = minimalSubject.email.replace('dpgmedia.nl','persgroep.net');
            //console.log("email converted if needed");

            /** first get the person: if the person exists, the existing person is returned */
            const newPerson = {
                ...minimalSubject,
                id: null,
                //birthDate: new Date(),
                email: improvedEmail
            }//newPerson
            //console.log("new person defined");
            //console.log(newPerson);
            const res=await axios.post(configData.SERVER_URL+'/api/persons/', newPerson, { headers: { 'Authorization': 'Bearer ' + user.token} });
            // DB: create the surveySubject
            const createdPerson = res.data;
            //console.log("person from db");
            //console.log(createdPerson);

            /** register the new subject in the db using a simplified subject object */
            const simplifiedSubject = {surveyId: survey.id, subjectPersonId: createdPerson.id, launchDate: new Date()}
            //console.log("minimal subject created");
            //console.log(simplifiedSubject);
            const result =  await axios.post(configData.SERVER_URL + '/api/surveysubjects/simplified',simplifiedSubject,{ headers: { 'Authorization': 'Bearer ' + user.token} });
            const dbSurveySubject = result.data;
            //console.log("returned from db:")
            //console.log(dbSurveySubject);
            
            //UPDATE VIEW
            //console.log("this is where the view is expected to be updated, based on the output of the db operation");
            const screenSurveySubject = {...dbSurveySubject, isSelected:false}
            //IN USE_SURVEY
            addSubjectToSurvey(screenSurveySubject);

            if (!isCancelled){
                setIsPending(false);
                setError(null);
            }

        }
        catch(err){
            console.log("ERROR captured: "+err.message);
            setError(err.message);
            setIsPending(false);
        }
    }

    const deleteSurveySubject = async (screenSubject) => {
            //console.log("entering remove in manageSurveySubjectHook");
            setError(null);
            setIsPending(true);
    
            try{
                // 1.remove the surveySubject from the DB (via the API)
                // TODO keep the persons db clean
    
                const rsp = await axios.delete(configData.SERVER_URL+'/api/surveysubjects/'+screenSubject.id, {headers: { 'Authorization': 'Bearer ' + user.token}});
                const dbDeletedSubject = rsp.data;
                //WHEN TO DELETE PERSONS ???            
    
                //2.remove the surveySubject from the application state (using exposed methods from reducer)
                deleteSubjectFromSurvey(screenSubject);
                
                // remove person from db
                
    
                if (!isCancelled){
                    setIsPending(false);
                    setError(null);    
                }
    
            } catch (err){
                if (!isCancelled){
                    console.log(err.message);
                    setError(err.message);
                    setIsPending(false);    
                }
            }
        }//deleteSurveySubject function

        const updateSurveySubject = async (screenSurveySubject) => {
            setError(null)
            setIsPending(true);

            try{
                // 1.update the respondent in the DB (via the API)
                // TODO keep the persons db clean
                /////////////// TODO: is dit een goeie en volledige (of simplified) update ?  Gaat er geen info verloren (vb. responses) ?
                const screenPerson = structuredClone(screenSurveySubject.subjectPerson);
                const res = await axios.put(configData.SERVER_URL+'/api/persons/'+screenPerson.id, screenPerson, {headers: { 'Authorization': 'Bearer ' + user.token}});
                const updatedPerson = res.data; ///////////////// is die nog nodig ???
                //2.update the respondent in the application state (using exposed methods from reducer)
                updateSurveySubjectForSurvey({...screenSurveySubject, subjectPerson: updatedPerson});
                // remove person from db
    
                if (!isCancelled){
                    setIsPending(false);
                    setError(null);    
                }
    
            } catch (err){
                if (!isCancelled){
                    console.log(err.message);
                    setError(err.message);
                    setIsPending(false);    
                }
            }

        }




    useEffect(()=> { // is fired when unmounting, preventing state to be updated after unmounting (generates an error)
        return () => setIsCancelled(true);
    }, []);

    //return {setMessagesForSurveyId, changeInviteMessage, changeReminderMessage, changeConfigFile, setAndGetAllSurveys, setAndGetSurvey, error, isPending} ;
    return {setSurveyById, changeInviteMessage, changeReminderMessage, changeConfigFile, changeIntro, changeOutro, changeInstructions, changeSurveyName, addSurveySubject, deleteSurveySubject, updateSurveySubject, error, isPending} ;
}