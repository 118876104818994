import { useEffect, useState } from "react";
import axios from 'axios';
import configData from '../config/config.json';

import { useAuthContext } from "./useAuthContext";
import { useSurveySubject } from "./useSurveySubject";
import { useSurvey } from "./useSurvey";


export const useManageSurveySubject =  () => {

    const { user }= useAuthContext();

    const [isCancelled, setIsCancelled] = useState(false);
    const [error, setError] = useState(null);
    const [isPending, setIsPending] = useState(false);
    
    const {setCurrentSurveySubject, addRespondentToSurveySubject, deleteRespondentFromSurveySubject, updateRespondentForSurveySubject} = useSurveySubject(); // AVAILABLE: changeSurveyId, addSurveySubject, removeSurveySubject, setSurveySubjects
    //const {survey} = useSurvey();
    const {surveySubject} = useSurveySubject();


    const setSurveySubjectById = async (surveySubjectId) => {
        setError(null);
        setIsPending(true);

        try{
            const res = await axios.get(configData.SERVER_URL+'/api/surveysubjects/'+surveySubjectId, {headers: { 'Authorization': 'Bearer ' + user.token}});
            //adjust the state
            console.log("fetched surveysubject from db");
            console.log(res.data);
            setCurrentSurveySubject(res.data);

            if (!isCancelled){
                setIsPending(false);
                setError(null);    
            }// if !isCancelled
            return res.data;

        }//try
        catch (err){
            if (!isCancelled){
                console.log(err.message);
                setError(err.message);
                setIsPending(false);    
            } // if !isCancelled
        } // catch errors   
    }//setMessagesForSurveyId
    
    const addRespondent = async (minimalRespondent) => {
        setError(null);
        setIsPending(true);
        
        try{
             /**
             * contains 2 actions: 
             * 1.add the respondent to the DB (via the API)
             * 2. add the respondent to the application state*/

            // DB: create the person
            // TODO verify the existence of the person !!

            //UPDATE DB = add the surveySubject to the DB first
            
            let improvedEmail = minimalRespondent.email.replace('dpgmedia.be','persgroep.net');
            improvedEmail = minimalRespondent.email.replace('dpgmedia.nl','persgroep.net');
            
            /** first get the person: if the person exists, the existing person is returned */
            const newPerson = {
                ...minimalRespondent,
                id: null,
                birthDate: new Date(),
                email: improvedEmail
            }//newPerson
            const res=await axios.post(configData.SERVER_URL+'/api/persons/', newPerson, { headers: { 'Authorization': 'Bearer ' + user.token} });
            // DB: create the surveySubject
            const createdPerson = res.data;
            
            /** register the new respondent in the db using a simplified subject object */
            //////////////////////TODO: is this the simplified respondent we need ???
            const simplifiedRespondent = {surveySubjectId: surveySubject.id, respondentPersonId: createdPerson.id, launchDate: new Date()}
            const result =  await axios.post(configData.SERVER_URL + '/api/respondents/simplified',simplifiedRespondent,{ headers: { 'Authorization': 'Bearer ' + user.token} });
            const dbRespondent = result.data;
            
            //UPDATE VIEW
            const screenRespondent = {...dbRespondent, isSelected:false}
            //IN USE_SURVEYSUBJECT
            addRespondentToSurveySubject(screenRespondent);

            if (!isCancelled){
                setIsPending(false);
                setError(null);
            }

        }
        catch(err){
            console.log("ERROR captured: "+err.message);
            setError(err.message);
            setIsPending(false);
        }
    }

    const deleteRespondent = async (screenRespondent) => {
            //console.log("entering remove in manageSurveySubjectHook");
            setError(null);
            setIsPending(true);
    
            try{
                // 1.remove the respondent from the DB (via the API)
                // TODO keep the persons db clean
    
                const rsp = await axios.delete(configData.SERVER_URL+'/api/respondents/'+screenRespondent.id, {headers: { 'Authorization': 'Bearer ' + user.token}});
                const dbDeletedRespondent = rsp.data;
                //WHEN TO DELETE PERSONS ???            
    
                //2.remove the respondent from the application state (using exposed methods from reducer)
                deleteRespondentFromSurveySubject(screenRespondent);
                // remove person from db
    
                if (!isCancelled){
                    setIsPending(false);
                    setError(null);    
                }
    
            } catch (err){
                if (!isCancelled){
                    console.log(err.message);
                    setError(err.message);
                    setIsPending(false);    
                }
            }
        }//deleteRespondent function
    
        const updateRespondent = async (screenRespondent) => {
            setError(null)
            setIsPending(true);

            try{
                // 1.update the respondent in the DB (via the API)
                // TODO keep the persons db clean
                /////////////// TODO: is dit een goeie en volledige (of simplified) update ?  Gaat er geen info verloren (vb. responses) ?
                const simplifiedScreenRespondent = {...screenRespondent, 
                                                    respondentPersonId: screenRespondent.respondentPerson.id,
                                                    surveySubjectId: screenRespondent.surveySubject.id,
                                                    respondentPersonFirstName: screenRespondent.respondentPerson.firstName,
                                                    respondentPersonLastName: screenRespondent.respondentPerson.lastName,
                                                    respondentPersonEmail: screenRespondent.respondentPerson.email
                                                   }
                /** gebruik simplified: bij surveySubject zijn de antwoorden niet zichtbaar, bij een normale respondentUpdate zou dit tot een probleem kunnen leiden */
                const rsp = await axios.put(configData.SERVER_URL+'/api/respondents/simplified/'+screenRespondent.id, simplifiedScreenRespondent, {headers: { 'Authorization': 'Bearer ' + user.token}});
                const updatedRespondent = rsp.data; ///////////////// is die nog nodig ???
                //2.update the respondent in the application state (using exposed methods from reducer)
                updateRespondentForSurveySubject(updatedRespondent);
                // remove person from db
    
                if (!isCancelled){
                    setIsPending(false);
                    setError(null);    
                }
    
            } catch (err){
                if (!isCancelled){
                    console.log(err.message);
                    setError(err.message);
                    setIsPending(false);    
                }
            }

        }

        /**
         * 
         * 
         * STATUS: werkt dit ?  En werkt dit correct ?  Overal waar //////////// TODO's staan: minimized vs. volledige aanmaak / udpate / delete
         * gebruik van de context als state ==> herdiepen
         * als er iets wijzigt in context lijkt dit re-render te genereren ==> full of niet ???
         * betekent dit dat we minder informatie moeten "passen" ?
         */

    useEffect(()=> { // is fired when unmounting, preventing state to be updated after unmounting (generates an error)
        return () => setIsCancelled(true);
    }, []);

    //return {setMessagesForSurveyId, changeInviteMessage, changeReminderMessage, changeConfigFile, setAndGetAllSurveys, setAndGetSurvey, error, isPending} ;
    return {setSurveySubjectById, addRespondent, deleteRespondent, updateRespondent, error, isPending} ;
}