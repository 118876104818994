import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import { useState, useEffect, useRef } from "react";

import {
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextareaAutosize,
  Tooltip,
} from "@mui/material";
import {
  AddCircleOutline,
  DeleteOutline,
  Edit,
} from "@mui/icons-material";
import SurveyQuestion from "./survey-pages-question-component";
import SurveyMessageEditor from "./SurveyMessageEditor";
import SurveyMessageEditorForm from "./SurveyMessageEditorForm";

import { useManageSurvey } from "../../hooks/useManageSurvey";
import { isNullOrUndef } from "chart.js/helpers";
import uuidv4 from "../../utilities/basicUtilities";

/**
 * page should receive page relevant info
 * "page" object
 * "surveyClusters"
 * "pageIndex"
 * "handlePageChanged"
 * "handlePageRemoved"
 * "isFirstPage"
 * "isLastPage"
 * drag & drop ==> later
 * move left & right ?
 */
const SurveyPage = ({page, surveyClusters, pageIndex, handlePageChange, handlePageRemoved, handleClustersChanged, handleCombinedChange, handlePageMove, isFirstPage, isLastPage}) => {
  const [showPageIntroEditor, setShowPageIntroEditor] = useState(false);
  const [introMessageText, setIntroMessageText] = useState("");

  const dragItem = useRef(null);
  const dragOverItem = useRef(null);
  
  const [myPage, setMyPage] = useState(null); // to be verified if we need this
  const [mySurveyClusters, setMySurveyClusters] = useState(null);

  const [myNewQuestionText, setMyNewQuestionText] = useState("");
  const [myNewClusterId, setMyNewClusterId] = useState(null);
  const [myNewSubClusterId, setMyNewSubClusterId] = useState(null);
  const [myNewQuestionTypeId, setMyNewQuestionTypeId] = useState(null);
  const [mySubClusters, setMySubClusters] = useState(null);
  const [isEmpty, setIsEmpty] = useState(false);

  //TODO getQuestionTypes from the config
  const questionTypes = [{id:1, typeNum:1, typeName:"open"},{id:2, typeNum:2, typeName:"rating 5"}, {id:9, typeNum:9, typeName:"just text"}];
    
    
  useEffect(() => {
//    console.log("every render ?");
    if (page){
      console.log(page);
      let _questions = [page.questions];
      setIsEmpty((page.pageIntro && page.pageIntro.length===0 && _questions.length===0));
//      console.log("is empty ? "+(page.pageIntro && page.pageIntro.length===0 && _questions.length===0));
    }
  })
  useEffect(() => {
 //   console.log("initial render");
    setMyPage(page);
    setMySurveyClusters(surveyClusters);
    //console.log(page);
  },[]);

  useEffect(() => {
    //setMyPageIndex(pageIndex);
    //if (page !==null){
    if (!isNullOrUndef(page)){
      console.log("survey-page-component useEffect[page] with page");
      console.log(page);
      setMyPage(page);
      setIntroMessageText(page.pageIntro);
      setMyNewClusterId(surveyClusters[0].id);
      setMyNewSubClusterId(surveyClusters[0].subClusters[0].id);
      setMySubClusters(surveyClusters[0].subClusters);
      setMyNewQuestionTypeId(questionTypes[0].id);
    }
  }, [page]);

  const handleOpenMessageEditor = () => {
    setShowPageIntroEditor(true);
  }

  const handleCloseMessageEditor = () => {
    setShowPageIntroEditor(false);
  }

  const updateIntroMessage = (editorContent) => {
    setIntroMessageText(editorContent);
    //TODO => ensure actual update to parent components (and db) CFR clusters
    setShowPageIntroEditor(false);
    let _page = structuredClone(page);
    _page.pageIntro = editorContent;
    //setMyPage(page);
    handlePageChange(_page);
  } // updateIntroMessage

  const setDragItem = (dragItemIndex) => {
    dragItem.current = dragItemIndex;
  }
  const setDragOverItem = (dragOverItemIndex) => {
    dragOverItem.current = dragOverItemIndex;
  }
  const handleSort = () => {
    //duplicate items
    let _listItems = [...page.questions];
    //remove and save the dragged item content
    const draggedItemContent = _listItems.splice(dragItem.current, 1)[0];
    //switch the position
    _listItems.splice(dragOverItem.current, 0, draggedItemContent);
    //reset the position ref
    dragItem.current = null;
    dragOverItem.current = null;
    //update the actual array
    let _page = structuredClone(page);
    _page.questions = _listItems;
    handlePageChange(_page); 
  }//handleSort

  const handleQuestionChange = (question) => { // to do: speed up using the index ?
    let _page = structuredClone(page);
    let _isModified = false;
    _page.questions.forEach((q, qIndex) => {
      if (q.questionId===question.questionId) {
        q.questionText = question.questionText;
        q.questionType = question.questionType;
        _isModified = true;
      } //if q.id = question.id
    });// _page.questions.forEach
    if (_isModified){
      handlePageChange(_page);
    } // isModified
  }

  const handleClusterChange = (question, clusterId, subClusterId) => {
    let _surveyClusters = [...surveyClusters];
    //remove question from current place (if present)
    _surveyClusters.forEach((cluster, clusterIndex) => {
      cluster.subClusters.forEach((subCluster, subClusterIndex) => {
        if (subCluster.questions.length>0){
          let _index = subCluster.questions.findIndex((x) => {return (x===question.questionId)});
          if (_index > -1){
            subCluster.questions.splice(_index, 1);
          } // if _index > -1
        } // if subCluster.questions.length > 0
      });//_config.subClusters.forEach
    });//_config.clusters.forEach
  
    //insert the question in the new subcluster
    
    _surveyClusters.forEach((cluster) => {
        if (cluster.id === clusterId) {
          cluster.subClusters.forEach((subCluster) => {
            if (subCluster.id === subClusterId){
              subCluster.questions.push(question.questionId);
            } // if subCluster.id === (provided) subClusterId
          });//cluster.subClusters.forEach
        } // if cluster.id === (provided) clusterId
      }); //_config.clusters.forEach
    // updating the config
    setMySurveyClusters(_surveyClusters);
    handleClustersChanged(_surveyClusters);
  } 
  const handleQuestionRemove = (questionId) => {
    let _page = structuredClone(page);
    let _surveyClusters = [...surveyClusters];

    //remove the question from (sub)cluster first
    _surveyClusters.forEach((cluster, clusterIndex) => {
      cluster.subClusters.forEach((subCluster, subClusterIndex) => {
        if (subCluster.questions.length>0){
          let _index = subCluster.questions.findIndex((x) => {return (x===questionId)});
          if (_index > -1){
            subCluster.questions.splice(_index, 1);
          } // if _index > -1
        } // if subCluster.questions.length > 0
      });//_config.subClusters.forEach
    });//_config.clusters.forEach

    //remove the question from page
      if (_page.questions.length>0){
        let _index = _page.questions.findIndex((x) => {return (x.questionId === questionId)});
        if (_index > -1){
          let removedQuestion = _page.questions.splice(_index,1)[0];
//          console.log("pageComp - removedQuestion = "+removedQuestion.questionText);
//          console.log(_page.questions);
        }
      }

    setMySurveyClusters(_surveyClusters);
    setMyPage(_page);
    
    handleCombinedChange(_page,_surveyClusters);
 }


 const handlePageLeft = (questionId) => {
    if (!isFirstPage){
      let _page = structuredClone(page);
      let _index = _page.questions.findIndex((x) => { return (x.questionId === questionId)});
      if (_index > -1){
        let removedQuestion = _page.questions.splice(_index,1)[0];
        handlePageMove(removedQuestion, _page, pageIndex,-1);
      }
    }    
 }
 const handlePageRight = (questionId) => {
    if(! isLastPage){
      let _page = structuredClone(page);
      let _index = _page.questions.findIndex((x) => { return (x.questionId === questionId)});
      if (_index > -1){
        let removedQuestion = _page.questions.splice(_index,1)[0];
        handlePageMove(removedQuestion, _page, pageIndex,1);
      }
    }    
}

  const newQuestionTextChange = (newValue) => {
    setMyNewQuestionText(newValue);
  }

  const newQuestionClusterChange = (newValue) => {
    setMyNewClusterId(newValue);
    surveyClusters.forEach((cluster) => {
      if (cluster.id===newValue){
        setMySubClusters(cluster.subClusters);
        setMyNewSubClusterId(cluster.subClusters[0].id);
      }
    });
  }
  const newQuestionSubClusterChange = (newValue) => {
    setMyNewSubClusterId(newValue);
  }

  const newQuestionTypeChange = (newValue) => {
    setMyNewQuestionTypeId(newValue);
  }
  const handleAddNewQuestion = () => {

    //add the question to the page, with a new id
    if (myNewQuestionText.length>0 && myNewClusterId !== null && myNewSubClusterId !==null && myNewQuestionTypeId !== null){
      let _newQuestion = {questionId: uuidv4(), questionText: myNewQuestionText, questionType: myNewQuestionTypeId};
      let _page = structuredClone(page);
      _page.questions.push(_newQuestion);
      
            //add the question to the right cluster
      let _surveyClusters = [...surveyClusters];
      _surveyClusters.forEach((c) => {
         if (c.id === myNewClusterId){
            c.subClusters.forEach((s) => {
              if (s.id === myNewSubClusterId){
                s.questions.push(_newQuestion.questionId);
              }// s.id = myNewSubClusterId
          }); // c.subClusters.forEach
        } // if c.id === myNewClusterId
      }); // _surveyClusters.forEach
      //adjust state
      setMyPage(_page);
      //TODO: verify if this combon doesn'tlead to partial updates: config and copied by both and then re-set
      handleCombinedChange(_page,_surveyClusters);
      //empty the new question fields
      setMyNewQuestionText("");
      setMyNewClusterId(surveyClusters[0].id);
      setMyNewSubClusterId(surveyClusters[0].subClusters[0].id);
      setMySubClusters(surveyClusters[0].subClusters);
      setMyNewQuestionTypeId(questionTypes[0].id);
      //setConfig(_config);
    }
  }

  const handleRemovePage = () => {
      handlePageRemoved(page, pageIndex);
  }


  return (
    <Grid container spacing={2} sx={{ width: "100%" }}>
      <Grid item xs={12}><Divider textAlign="left">INTROTEKST</Divider></Grid>
      {/*<Grid item xs={12} style={{padding: "5px", display: "flex", justifyContent: "left", alignItems: "left",}}>Pagina introtekst</Grid>*/}
      <Grid item xs={9} style={{padding: "5px", display: "flex", justifyContent: "center", alignItems: "center",}}>
          <div dangerouslySetInnerHTML={{ __html: introMessageText}} style={{textAlign:"left"}} />
          <div className="text-editor">
              {showPageIntroEditor && 
                  <SurveyMessageEditor handleClose={handleCloseMessageEditor}>
                    <SurveyMessageEditorForm handleClose={handleCloseMessageEditor} updateSurveyMessage={updateIntroMessage} placeholder={introMessageText}/>
                  </SurveyMessageEditor>
              }
            </div>
      </Grid>
      <Grid item xs={3} style={{padding: "5px", display: "flex", justifyContent: "center", alignItems: "center",}}>
          <Button variant="outlined" color="secondary" onClick={() => handleOpenMessageEditor()} startIcon={<Edit />}>introtekst bewerken</Button>
      </Grid>
      <Grid item xs={12}><Divider textAlign="left">VRAGEN</Divider></Grid>
          {(!isNullOrUndef(page) && !isNullOrUndef(page.questions)) && page.questions.map((item, index) => (
            <Grid item xs={12} style={{padding: "5px", display: "flex", justifyContent: "center", alignItems: "center",}} className="list-sort" key={"Page-Question_"+index}>
                <SurveyQuestion  
                  question={item} surveyClusters={surveyClusters} questionIndex={index} 
                  setDragItem={setDragItem} setDragOverItem={setDragOverItem} handleSort={handleSort} 
                  handleQuestionChange={handleQuestionChange} handleClusterChange={handleClusterChange} handleQuestionRemove={handleQuestionRemove}
                  isFirstPage={isFirstPage} isLastPage={isLastPage}
                  handlePageLeft={handlePageLeft} handlePageRight={handlePageRight}/>
            </Grid>
      
          ))}
       <Grid item xs={12}><Divider textAlign="left">NIEUWE VRAAG</Divider></Grid>
     
      {(surveyClusters!== null) &&
            <Grid item xs={12} style={{padding: "5px", display: "flex", justifyContent: "left", alignItems: "left",}}>

                  <Grid container spacing={2}>
                    <Grid item xs={7} style={{padding: "15px", display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <TextareaAutosize style={{ position: "relative", width: "100%" }} value={myNewQuestionText} minRows={1} onChange={(e) => newQuestionTextChange(e.target.value)}/>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl style={{width: "30%", minWidth:"170px"}}>
                          <Tooltip title="cluster" placement="top-end">
                              <Select value={myNewClusterId} onChange={(e) => {newQuestionClusterChange(e.target.value);}} size="small" >
                                          {surveyClusters.map((cluster, clusterIndex) => (<MenuItem value={cluster.id} key={"cluster_" + clusterIndex}>{cluster.naam}</MenuItem>))}
                              </Select>
                          </Tooltip>
                              {/*{(questionIndex===0) && (<FormHelperText>cluster {myClusterId}</FormHelperText>)}*/}
                      </FormControl>
                      <FormControl style={{width: "30%", minWidth:"170px"}}>
                          <Tooltip title="subCluster" placement="top-end">
                              <Select value={myNewSubClusterId} onChange={(e)=>{newQuestionSubClusterChange(e.target.value)}} size="small" >
                                          {(mySubClusters!==null) && mySubClusters.map((subCluster, subClusterIndex) => (<MenuItem value={subCluster.id} key={"subCluster_" + subClusterIndex}>{subCluster.naam}</MenuItem>))}
                              </Select>
                          </Tooltip>
                      </FormControl>
                      <FormControl style={{width: "30%", minWidth:"170px"}}>
                          <Tooltip title="vraagtype" placement="top-end">
                              <Select value={myNewQuestionTypeId} onChange={(e) => {newQuestionTypeChange(e.target.value)}} size="small">
                                  {questionTypes.map((questionType, questionTypeIndex) => (<MenuItem value={questionType.id} key={"questionType_" + questionTypeIndex}>{questionType.typeName}</MenuItem>))}
                            </Select>
                        </Tooltip>
            </FormControl>
                    </Grid>
                    <Grid item xs={1}>
                      <Button variant="outlined" color="secondary" onClick={() => handleAddNewQuestion()} startIcon={<AddCircleOutline />}>toevoegen</Button>
                    </Grid>
                  </Grid>
            </Grid>
      }
      <Grid item xs={12} justifyContent="flex-end" textAlign="right">
            <Divider textAlign="left"></Divider>
            {page && !page.pageIntro && page.questions.length===0 &&
              (
                <Button variant="outlined" color="secondary" onClick={() => handleRemovePage()} startIcon={<DeleteOutline/>}>pagina verwijderen</Button>
              )
            }
            
      </Grid>
    </Grid> 
  );
};
export default SurveyPage;
