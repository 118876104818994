import React, { useState, useCallback, useEffect } from 'react';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { Button, IconButton, Stack, Tooltip } from '@mui/material';
import { EmailTwoTone, NotificationsActiveTwoTone, SaveTwoTone, DeleteTwoTone, SecurityTwoTone, FileCopyTwoTone, EditTwoTone } from '@mui/icons-material';

import Alert from '@mui/material/Alert';
import { PersonsContainer } from '../../unstated/persons';

export default function CellEditControlGrid() {

    const pCont = PersonsContainer.useContainer();
    
    const [personList, setPersonList] = useState([]);
    const [editRowsModel, setEditRowsModel] = useState({});
    const [newRowId, setNewRowId] = useState(-1);
    
    console.log("just set new RowId to "+newRowId);

    useEffect(() => {
        const fetchPersons = async () => {
          await setPersonList(await pCont.loadPersons());
        }
        fetchPersons();
        console.log("pList after async fetch");
        console.log(personList);
      }, []);
      // somewhere the list needs to get filtered (or greyed out) based on the use case (e.g. do not invite persons already in a survey)

    const handleEditRowsModelChange = useCallback((model) => {
        setEditRowsModel(model);
    }, []);

    const handleCommit = useCallback((model) => {
        console.log(model);
        // indien bestaand object ==> update
        // indien niet bestaand object ==> save pas als save button wordt ingedrukt
    }, []);

    // const deleteUser = React.useCallback(
    //     (id) => () => {
    //       setTimeout(() => {
    //         setPersonList((prevPersonList) => prevPersonList.filter((row) => row.id !== id));
    //       });
    //     },
    //     [],
    //   );
    
    const deleteUser = React.useCallback(
        (params) => () => {
          console.log(params);
        },
        [],
      );
    
      const toggleSaved = React.useCallback(
        (id) => () => {
          setPersonList((prevPersonList) =>
            prevPersonList.map((row) =>
                row.id === id ? { ...row, status: (row.status === "unsaved") ? "saved": "unsaved"} : row,
            ),
          );
        },
        [],
      );
    
      const duplicateUser = React.useCallback(
        (id) => () => {
          setPersonList((prevPersonList) => {
            const rowToDuplicate = prevPersonList.find((row) => row.id === id);
            return [...prevPersonList, { ...rowToDuplicate, id: Date.now() }];
          });
        },
        [],
      );


    const handleButtonClick = () => {
        console.log("button clicked");
        const newPerson = {
            id: newRowId,
            firstName:"",
            lastName:"",
            email:"",
            birthDate: new Date(),
            status: "unsaved"
        }
        setPersonList(personList.concat(newPerson));
        setNewRowId(newRowId-1);
    
    }

    const handleSave = (params, event)=> {
        console.log(params);
        //GridActionsCellItem 
        //rows by default not editable
        //editbutton click ==> make row editable
        // existing objects ==> commit changes immediately & mark "changes saved" in comment bar on top
        // new objects ==> activate save button
        // when save button is clicked ==> commit changes & mark "created" in top bar

        console.log(event);
    }
    const columns = [
        { field: 'firstName', headerName: 'Voornaam', width: 180, editable: true },
        { field: 'lastName', headerName: 'Naam', width: 180, editable: true },
        { field: 'email', headerName: 'e-mail', width: 180, editable: true },
        // {
        //     field: 'birthDate',
        //     headerName: 'Geboortedatum',
        //     type: 'date',
        //     width: 180,
        //     editable: true,
        // },
        {
            field: 'birthDate',
            headerName: 'Last Login',
            type: 'dateTime',
            width: 220,
            editable: false,
            renderCell: (params) => (
                <strong>
                    {new Date(params.value).getFullYear()}
                </strong>
            ),
          
          },

            {
                field: 'status',
                headerName: 'acties',
                type: 'actions',
                width: 300,
                editable: false,
                getActions: (params) => [
                    <GridActionsCellItem
                    icon={ <Tooltip title="verwijderen"><DeleteTwoTone/></Tooltip>}
                    color="primary"
                    size="small"
                    label="Delete"
                    onClick={deleteUser(params)}
                    />,
                    <GridActionsCellItem
                    icon={<Tooltip title="dupliceren"><FileCopyTwoTone /></Tooltip>}
                    color="primary"
                    size="small"
                    label="Duplicate User"
                    onClick={duplicateUser(params.id)}
                    />,
                    ... (params.row.status === "unsaved") ?
                        [ <GridActionsCellItem
                            icon={<Tooltip title="toggle status"><SaveTwoTone /></Tooltip>}
                            color="primary"
                            size="small"
                            label="Toggle saved"
                            onClick={toggleSaved(params.id) }
                            />
                        ] : 
                        [ <GridActionsCellItem
                            icon={<Tooltip title="aanpassen"><EditTwoTone /></Tooltip>}
                            color="primary"
                            size="small"
                            label="bewerken"
                            onClick={toggleSaved(params.id) } //TODO adjust
                            />
                        ],
                    


                ]
            }
        ];

    return (
        <div style={{ width: '100%' }}>
            <Alert severity="info" style={{ marginBottom: 8 }}>
                <code>editRowsModel: {JSON.stringify(editRowsModel)}</code>
            </Alert>
            <Stack 
                sx={{ width: '100%', mb: 1 }}
                direction="row"
                alignItems="flex-start"
                columnGap={1}
            >
                <Button size="small" onClick={handleButtonClick}>
                    Update a row
                </Button>
                <Button size="small" onClick={handleButtonClick}>
                    Update all rows
                </Button>
                <Button size="small" onClick={handleButtonClick}>
                    Delete a row
                </Button>
                <Button size="small" onClick={handleButtonClick}>
                    Add a row
                </Button>

            </Stack>
            {personList &&
                <div style={{ height: 400, width: '100%' }}>
                    <DataGrid
                        rows={personList}
                        columns={columns}
                        editRowsModel={editRowsModel}
                        onEditRowsModelChange={handleEditRowsModelChange}
                        onCellEditCommit={handleCommit}
                        checkboxSelection
                        disableSelectionOnClick
                        isRowSelectable={(params) => new Date(params.row.birthDate).getFullYear() > 1975}
                    />
                </div>
             }
        </div>
        );
    }


/*
const rows = [
  {
    id: 1,
    name: "John James",
    age: 25,
    dateCreated: new Date(1974,12,28),
    lastLogin: new Date(),
  },
  {
    id: 2,
    name: "Rick Rufus",
    age: 36,
    dateCreated: new Date(1975,6,26),
    lastLogin: new Date(),
  },
  {
    id: 3,
    name: "Jenna Johanson",
    age: 19,
    dateCreated: new Date(1977,8,12),
    lastLogin: new Date(),
  },
  {
    id: 4,
    name: "Vera Veneer",
    age: 28,
    dateCreated: new Date(1968,4,14),
    lastLogin: new Date(),
  },
  {
    id: 5,
    name: "Kim Hong",
    age: 23,
    dateCreated: new Date(1970,1,2),
    lastLogin: new Date(),
  },
];
*/