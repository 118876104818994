import { useState, useRef, useEffect } from "react";
import {FormControl, Grid, MenuItem, Select, TextareaAutosize, Tooltip} from "@mui/material";
import { ArrowCircleLeftOutlined,  ArrowCircleRightOutlined,  DeleteOutline,  ReorderOutlined} from "@mui/icons-material";

const SurveyQuestion = ({question, surveyClusters, questionIndex, setDragItem, setDragOverItem, handleSort, handleQuestionChange, handleClusterChange, handleQuestionRemove, isFirstPage, isLastPage, handlePageLeft, handlePageRight}) => {
    const [myQuestion, setMyQuestion] = useState(null);
    /** questionId,questionType,questionText*/
    const clusters = useRef(surveyClusters);
    const [myClusterId, setMyClusterId] = useState(null);
    const [mySubClusterId, setMySubClusterId] = useState(null);
    const [myQuestionTypeId, setMyQuestionTypeId] = useState(null);
    const [myClusterIndex, setMyClusterIndex] = useState(null);
    const [mySubClusterIndex, setMySubClusterIndex] = useState(null);
    const [mySubClusters, setMySubClusters] = useState([]);
    const [myTextValue, setMyTextValue] = useState("");
    
    const questionTypes = [{id:1, typeNum:1, typeName:"open"},{id:2, typeNum:2, typeName:"rating 5"}, {id:9, typeNum:9, typeName:"just text"}];
    
    useEffect(() => {
        setMyQuestion(question);
        setMyTextValue(question.questionText);
        //clusters.current.forEach((cluster, clusterIndex) => {
        surveyClusters.forEach((cluster, clusterIndex) => {
            cluster.subClusters.forEach((subCluster, subClusterIndex) => {
                if (subCluster.questions && subCluster.questions.length>0){
                    if (subCluster.questions.includes(question.questionId)){
                        setMyClusterId(cluster.id);
                        setMySubClusterId(subCluster.id);
                        setMyClusterIndex(clusterIndex);
                        setMySubClusterIndex(subClusterIndex);
                        setMySubClusters(cluster.subClusters);
                        setMyQuestionTypeId(question.questionType);
                    }    
                }
            });
        });
    },[question]);

  const clusterChange = (e) => {
    setMyClusterId(e.target.value);
    clusters.current.forEach((cluster) => {
        if (cluster.id === e.target.value) {
            setMySubClusters(cluster.subClusters);
            setMySubClusterId(cluster.subClusters[0].id);
            handleClusterChange(question, e.target.value, cluster.subClusters[0].id);
        }
    });
  }
  const subClusterChange = (e) => {
    setMySubClusterId(e.target.value);
    handleClusterChange(question, myClusterId, e.target.value);
  }
  const questionTypeChange = (e) => {
    setMyQuestionTypeId(e.target.value);
   let _question = structuredClone(myQuestion);
    _question.questionType=e.target.value;
    setMyQuestion(_question);
    handleQuestionChange(_question);
  }
  const questionTextChange = (newValue) =>{
    setMyTextValue(newValue);
  }
  const questionTextChanged = () => {
    let _question = structuredClone(myQuestion);
    _question.questionText = myTextValue;
    setMyQuestion(_question);
    handleQuestionChange(_question);
  }

  const handleDelete = () => {
    handleQuestionRemove(myQuestion.questionId);
  };

  return (
    <div style={{border:"1px solid", paddingTop:"10px", borderColor: "#d23278", width:"100%"}}>
    {(myQuestion !== null && myQuestion.questionText) &&
      <Grid container spacing={2} key={"Grid_" + questionIndex} >
        <Grid
          item xs={7} key={"card_" + questionIndex} className="sortable-list-item" draggable 
          onDragStart={(e) => (setDragItem(questionIndex))} 
          onDragEnter={(e) => (setDragOverItem(questionIndex))} 
          onDragEnd={handleSort}
          onDragOver={(e) => e.preventDefault()}
        >
          <ReorderOutlined style={{ position: "relative", width: "10%", verticalAlign: "top" }}/>
          <TextareaAutosize style={{ position: "relative", width: "90%" }} value={myTextValue} minRows={1} onChange={(e) => questionTextChange(e.target.value)} onBlur={(e) => questionTextChanged()}/>
        </Grid>
        <Grid item xs={4}>
            <FormControl style={{width: "30%", minWidth:"170px"}}>
                <Tooltip title="cluster" placement="top-end">
                    <Select value={myClusterId} onChange={(e) => {clusterChange(e);}} size="small" >
                                {clusters.current.map((cluster, clusterIndex) => (<MenuItem value={cluster.id} key={"cluster_" + clusterIndex}>{cluster.naam}</MenuItem>))}
                    </Select>
                </Tooltip>
                    {/*{(questionIndex===0) && (<FormHelperText>cluster {myClusterId}</FormHelperText>)}*/}
            </FormControl>
            <FormControl style={{width: "30%", minWidth:"170px"}}>
                <Tooltip title="subCluster" placement="top-end">
                    <Select value={mySubClusterId} onChange={(e)=>{subClusterChange(e)}} size="small" >
                                {(mySubClusters!==null) && mySubClusters.map((subCluster, subClusterIndex) => (<MenuItem value={subCluster.id} key={"subCluster_" + subClusterIndex}>{subCluster.naam}</MenuItem>))}
                    </Select>
                </Tooltip>
            </FormControl>
            <FormControl style={{width: "30%", minWidth:"170px"}}>
                <Tooltip title="vraagtype" placement="top-end">
                    <Select labelId="questionType-select-label" id={"questionType-select_"+question.questionId} value={myQuestionTypeId} onChange={(e) => {questionTypeChange(e);}} size="small">
                                {questionTypes.map((questionType, questionTypeIndex) => (<MenuItem value={questionType.id} key={"questionType_" + questionTypeIndex}>{questionType.typeName}</MenuItem>))}
                    </Select>
                </Tooltip>
            </FormControl>
        </Grid>
        <Grid item xs={1}>
          {(!isFirstPage) && <ArrowCircleLeftOutlined onClick={() => handlePageLeft(myQuestion.questionId)} />}
          {(!isLastPage) && <ArrowCircleRightOutlined onClick={() => handlePageRight(myQuestion.questionId)} />}
          <DeleteOutline onClick={(e) => handleDelete()} />
        </Grid>
      </Grid>}
      
    </div>
  );
};
export default SurveyQuestion;