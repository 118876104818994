import { useContext } from "react";
import { SurveySubjectsContext } from "../context/SurveySubjectsContext";

export const useSurveySubjects = () => {
    const context = useContext(SurveySubjectsContext);

    if (context === undefined){
        throw new Error("useSurveySubjects() must be used inside a SurveySubjectsProvider");
    }

    return context;
}