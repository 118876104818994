import { useEffect, useState } from "react";
//import { useRespondentContext } from "./useRepondentContext";
import axios from 'axios';
import configData from '../config/config.json';
import { useAuthContext } from "./useAuthContext";

export const useManageRespond =  () => {

    const {user} = useAuthContext();
    
    //const {dispatch}= useRespondentContext();
    //const {addSurveySubject, removeSurveySubject, setSurveySubjects} = useSurveySubjects(); // AVAILABLE: changeSurveyId, addSurveySubject, removeSurveySubject, setSurveySubjects
    const [isCancelled, setIsCancelled] = useState(false);
    const [error, setError] = useState(null);
    const [isPending, setIsPending] = useState(false);
 
    const createOrUpdateAnswer = async (responseObject) => {
        setError(null);
        setIsPending(true);
        console.log("create or update an answer");
        console.log(responseObject);

        try{
            let output = null;
            // create or update the response in the db
            //const checkedUser = await getUser();
            if (responseObject.id!==null){ 
                const res = await axios.get(configData.SERVER_URL+'/api/responses/'+responseObject.id,{ headers: { 'Authorization': 'Bearer ' + user.token} });

                if (res.data !== null){ // a previous answer exists ==> update
                    let simpleResponse = {...res.data, intValue: responseObject.intValue, textValue: responseObject.textValue, booleanValue: responseObject.booleanValue, ignored: responseObject.ignored};
                    const result = await axios.put(configData.SERVER_URL + '/api/responses/simplified/' + responseObject.id, simpleResponse,{ headers: { 'Authorization': 'Bearer ' + user.token} });
                    output = result.data;    
                }
                else {  // no previous answer exists ==> create
                    const result= await axios.post(configData.SERVER_URL + '/api/responses/simplified', responseObject,{ headers: { 'Authorization': 'Bearer ' + user.token} });
                    output = result.data;
                }        
            }
            else { // responseObject.id === null
                const result= await axios.post(configData.SERVER_URL + '/api/responses/simplified', responseObject,{ headers: { 'Authorization': 'Bearer ' + user.token} });
                output = result.data;           
            }
            // adjust the state ?
            if (!isCancelled){
                setIsPending(false);
                setError(null);    
            }// if !isCancelled
            return output;

        }//try
        catch (err){
            if (!isCancelled){
                console.log(err.message);
                setError(err.message);
                setIsPending(false);    
            } // if !isCancelled
        } // catch errors   
    } // createOrUpdateAnswer
    
    const finalizeRespond = async(respondent) => {
        setError(null);
        setIsPending(true);
    
        try{
            /**
             * contains 2 actions: 
             * 1. update the status in the DB
             * NOT HERE 2. adjust the status of the respondent at the front-end
             */

            let minimalRespondent = {
                surveySubjectId: respondent.surveySubject.id,
                respondentPersonId: respondent.respondentPerson.id,
                launchDate: new Date(respondent.launchDate),
                relationship: respondent.relationship,
                status: 'completed'
            }
            const rsp = await axios.put(configData.SERVER_URL + '/api/respondents/simplified/' + respondent.id, minimalRespondent,{ headers: { 'Authorization': 'Bearer ' + user.token} });

    
            if (!isCancelled){
                setIsPending(false);
                setError(null);    
            }
        return (rsp.data);

        } catch (err){
            if (!isCancelled){
                console.log(err.message);
                setError(err.message);
                setIsPending(false);    
            }
        }
    }//complete function

    
    useEffect(()=> { // is fired when unmounting, preventing state to be updated after unmounting (generates an error)
        return () => setIsCancelled(true);
    }, []);

    return {createOrUpdateAnswer, finalizeRespond, error, isPending, isCancelled} ;
}