import React, { useState, useEffect,  } from "react";
import { useParams } from "react-router-dom";
//import Chart from "react-apexcharts";
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
    ArcElement,
  } from 'chart.js';

import { Radar, PolarArea } from 'react-chartjs-2';

import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Typography } from "@mui/material";

import { useManageSurveySubjects } from "../../hooks/useManageSurveySubjects";
import { useManageSurvey } from "../../hooks/useManageSurvey";
import { useReport } from "../../hooks/useReport";


const SubjectReport = () => {
    const [relationships, setRelationships] = useState([]);
    const {subjectId} = useParams();
    const {getSurveySubjectForReportFromDb} = useManageSurveySubjects();
    const {setSurveyById} = useManageSurvey();
    const {getChartJS_Data, getRadarChart_Data, getTableData} = useReport();
    
    const [survey, setSurvey] = useState(null);
    const [surveyConfig, setSurveyConfig] = useState(null);
    const [surveySubject, setSurveySubject] = useState(null);
    const [isInitialized, setInitialized] = useState(false);
    
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [otherSelectedCategories, setOtherSelectedCategories] = useState([]);
    
    const [radarChartData, setRadarChartData] = useState(null);
    const [chartData, setChartData] = useState(null);
    const [rightChartData, setRightChartData] = useState(null);

    ChartJS.register(
        RadialLinearScale,
        PointElement,
        LineElement,
        ArcElement,
        Filler,
        Tooltip,
        Legend
      );
    
    
    const chartOptions = {
        plugins: {
            legend: {
                display: false,
            }
        },
        scales: {
            r: {
                min: 1,
                max: 5
            }
        }
    }//chartOptions

    const radOptions = {
        plugins: {
            legend: {
                display: true,
            }
        },
        scales: {
            r: {
                suggestedMin: 1,
                suggestedMax: 5
            }
        }
    }//chartOptions

    const downloadReport = async () => {
        const fileName = surveySubject.subjectPerson.lastName;
        const myTableData = await getTableData(surveyConfig, surveySubject, relationships);
        const json = JSON.stringify(myTableData);
        const blob = new Blob([json], { type: "application/json" });
        const href = URL.createObjectURL(blob);
        // create "a" HTLM element with href to file
        const link = document.createElement("a");
        link.href = href;
        link.download = fileName + ".json";
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    }
    const downloadReportAsCsV = async () => {
        const fileName = surveySubject.subjectPerson.lastName;
        const myTableData = await getTableData(surveyConfig, surveySubject, relationships);

        const headers = ['relationship;cluster;subCluster;questionText;questionType;booleanValue;intValue;textValue'];
        let data = [];
        myTableData.forEach((rij) => {
            data.push([rij.relationship, rij.cluster, rij.subCluster, rij.questionText, rij.questionType, rij.booleanValue, rij.intValue, rij.textValue].join(';'));
        });

        const allData = [...headers, ...data].join('\n');
        const blob = new Blob([allData], { type: 'text/csv' });
        const href = URL.createObjectURL(blob);
        // create "a" HTLM element with href to file
        const link = document.createElement("a");
        link.href = href;
        link.download = fileName + ".csv";
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    }

    useEffect(() => {
        const fetchSurvey = async (surveyId) => {
            const fetchedSurvey = await setSurveyById(surveyId);
            setSurvey(fetchedSurvey);
            return fetchedSurvey;
        }
        const fetchSurveyConfig = async (aSurvey) => {
            let myConfig = JSON.parse(aSurvey.configFile);
            setSurveyConfig(myConfig);
            return myConfig;
        }

        const fetchSubject = async (subjectId) => {
            const response = await getSurveySubjectForReportFromDb(subjectId);
            setSurveySubject(response);
            return response;
        }//fetchSubject

        const fetchRelationships = async (aSubject) => {
            let rels = [];
            if (relationships.length === 0){
                aSubject.respondents.forEach((respondent) => {
                    if (!rels.includes(respondent.relationship)) rels.push(respondent.relationship);
                }); //respondents.forEach
                setRelationships(rels);
                setSelectedCategories(rels);
                setOtherSelectedCategories(rels);
            }            
            return rels;
        }

        const fetchAll = async () => {
            //let myRadarData = await getRadarChart_Data(myConfig, mySubject, myRelationships);
            let fetchedSubject = await fetchSubject(subjectId);
            let fetchedSurvey = await fetchSurvey(fetchedSubject.surveyId);
            let fetchedConfig = await fetchSurveyConfig(fetchedSurvey);
            let fetchedRelationships = await fetchRelationships(fetchedSubject);
            let myRadarData = await getRadarChart_Data(fetchedConfig, fetchedSubject, fetchedRelationships);
            let myChartData = await getChartJS_Data(fetchedConfig, fetchedSubject, fetchedRelationships);
            let myRightChartData = await getChartJS_Data(fetchedConfig, fetchedSubject, fetchedRelationships);
            console.log("subjectReport.useEffect.fetchAll: myRadarData, myChartData, myRightChartData");
            console.log(myRadarData);
            console.log(myChartData);
            console.log(myRightChartData);
            await setRadarChartData(myRadarData);
            await setChartData(myChartData);
            await setRightChartData(myRightChartData);
            setInitialized(true);
            //TODO remove & do something with it
            getTableData(fetchedConfig, fetchedSubject, fetchedRelationships);
            
        }
        fetchAll();
    },[]);

    useEffect(() => {
       const adjustChartData = async () => {
            let myChartData = await getChartJS_Data(surveyConfig, surveySubject, selectedCategories);
            setChartData(myChartData);
            return myChartData;
        }
        if (isInitialized) adjustChartData();

    }, [isInitialized, selectedCategories]);//useEffect
 
    useEffect(() => {
        const adjustChartData = async () => {
             let myChartData = await getChartJS_Data(surveyConfig, surveySubject, otherSelectedCategories);
             setRightChartData(myChartData);
             return myChartData;
         }
         if (isInitialized) adjustChartData();

     }, [isInitialized, otherSelectedCategories]);//useEffect
 

    const handleLeftChange = async (event) => {
        if (selectedCategories.includes(event.target.name) && !event.target.checked) {
            let myConfig = await JSON.parse(survey.configFile);
            let myChartData = await getChartJS_Data(myConfig, surveySubject, selectedCategories.filter(cat => cat !==event.target.name));
            setChartData(myChartData);
            setSelectedCategories(selectedCategories.filter(cat => cat!==event.target.name));
        }
        else if (!selectedCategories.includes(event.target.name) && event.target.checked) {
            let myConfig = await JSON.parse(survey.configFile);
            let myChartData = await getChartJS_Data(myConfig, surveySubject, [...selectedCategories, event.target.name]);
            setChartData(myChartData);
            setSelectedCategories([...selectedCategories, event.target.name]);
        }
        else console.log("nothing happening");
      };
      const handleRightChange = (event) => {
        if (otherSelectedCategories.includes(event.target.name) && !event.target.checked) {
            setOtherSelectedCategories(otherSelectedCategories.filter(cat => cat!==event.target.name));
        }
        else if (!otherSelectedCategories.includes(event.target.name) && event.target.checked) {
            setOtherSelectedCategories([...otherSelectedCategories, event.target.name]);
        }
        else console.log("nothing happening");
      };

    return(
        <div>
{/**            {surveySubject && chartData && radarChartData && survey && (radarData.length > 0) && (roseData.length > 0) && roseOptions && radarOptions && (rose2data.length > 0) && relationships && tableData && */}
            {surveySubject && chartData && rightChartData && radarChartData && survey && relationships &&
                <div>Resultaten 360° - {surveySubject.subjectPerson.firstName + ' '+ surveySubject.subjectPerson.lastName}<br/>
                    <div className="reportContainer">
                        <div className="reportItem"><Radar data={radarChartData} options={radOptions}/></div>
                        <div/>
                        <div><a href="" onClick={downloadReport}>download details as json</a><br/><a href="" onClick={downloadReportAsCsV}>download details as csv</a></div>
                    </div>
                    <div className="reportContainer">
                        <div className="reportItem"><PolarArea data={chartData} options={chartOptions}/></div>
                        <div/>
                        <div className="reportItem"><PolarArea data={rightChartData} options={chartOptions}/></div>
                    </div>
                    
                    <div className="reportContainer">
                        <div className="reportItem">
                            <FormControl sx={{ m: -0.5}} component="fieldset">
                                <FormLabel component="legend">Selecteer respondentengroepen</FormLabel>
                                <FormGroup sx={{ m: -0.5, fontSize: '8pt'}}>
                                    {relationships.map(relationship => (
                                        <FormControlLabel
                                            key={relationship}
                                            control={
                                            <Checkbox checked={selectedCategories.includes(relationship)} onChange={handleLeftChange} name={relationship} size="small"/>
                                            }
                                            label={<Typography sx={{fontSize: '100%'}}>{relationship.toLowerCase()}</Typography>}
                                        />
                                    ))}
                                </FormGroup>
                            </FormControl>
                       </div>
                       <div/>
                       <div className="reportItem">
                            <FormControl sx={{ m: -0.5}} component="fieldset">
                                <FormLabel component="legend">Selecteer respondentengroepen</FormLabel>
                                <FormGroup sx={{ m: -0.5, fontSize: '8pt'}}>
                                    {relationships.map(relationship => (
                                        <FormControlLabel
                                            key={relationship}
                                            control={<Checkbox checked={otherSelectedCategories.includes(relationship)} onChange={handleRightChange} name={relationship} size="small" />}
                                            label = {<Typography sx={{fontSize: '100%'}}>{relationship.toLowerCase()}</Typography>}
                                        />
                                    ))}
                                </FormGroup>
                            </FormControl>

                       </div>
                    </div>
                    
                </div>
            }
        </div>
    );//return
}
export default SubjectReport;

/*
  
  function getRoseChartData(clusters, mySubject, isLeft){
        let roseChartData=[];
        let roseChartLabels=[];

        clusters.forEach(cluster => {
            cluster.subClusters.forEach(subCluster => {
                let subClusterTotal = 0;
                let occurrences = 0;
                subCluster.questions.forEach(subClusterQuestionId => {
                    mySubject.respondents.forEach(respondent => {
                        if (
                            ((selectedCategories.includes(respondent.relationship) && isLeft) || 
                             (otherSelectedCategories.includes(respondent.relationship) && !isLeft)) && 
                            respondent.status === "completed"){
                                respondent.responses.forEach(response => {
                                    if (response.questionId === subClusterQuestionId) {
                                        
                                        if (response.intValue>0) {
                                            occurrences++;
                                            subClusterTotal+=(response.intValue-1);
                                        }
                                    }
                                });//response forEach
                        }// if respondent.relationship
                    });//respondent forEach
                });// subClusterQuestionId for each
                roseChartData.push((subClusterTotal/(occurrences*4)).toFixed(2));
                //roseChartLabels.push(cluster.naam+" - "+subCluster.naam);
                roseChartLabels.push(subCluster.naam);
            });// subCluster forEach
        });//cluster forEach
        roseOps.labels = roseChartLabels;
        setRoseOptions(roseOps);
        //console.log("returning roseChartData (one or two)");
        //console.log(roseChartData);
        return roseChartData;
    } 


    function getRadarData(clusters, mySubject){
        let radarSeries = [];
        let clusterNames =[];
        relationships.forEach(relationship => {
            let clustersData = [];
            clusters.forEach(cluster => {
                if (relationship.toLowerCase() === "self"){
                    clusterNames.push(cluster.naam);
                }
                let clusterTotal = 0;
                let occurrences = 0;
                
                cluster.subClusters.forEach(subCluster => {
                    subCluster.questions.forEach(subClusterQuestionId => {
                        mySubject.respondents.forEach(respondent => {
                            if (respondent.relationship === relationship && respondent.status === "completed" && selectedCategories.includes(respondent.relationship)){
                                respondent.responses.forEach(response => {
                                    if (response.questionId === subClusterQuestionId) {
                                        
                                        if (response.intValue>0) {
                                            occurrences++;
                                            clusterTotal+=(response.intValue-1);
                                        }
                                    }
                                });//response forEach
                            }// if respondent.relationship
                        });//respondent forEach
                    });// subClusterQuestionId for each
                });// subCluster forEach
                clustersData.push((clusterTotal/(occurrences*4)).toFixed(2));
            });
            radarSeries.push({name: relationship, data: clustersData})
        }); // relationships.forEach
        //setClusters(clusterNames);
        radarOps.xaxis.categories = clusterNames;
        setRadarOptions(radarOps);
        //console.log("getRadarData fist results:");
        //console.log(radarSeries);
        console.log("REPORT.getRadarData");
        console.log(radarSeries);
        return radarSeries;
    }


            const fetchRelationships = async (aSubject) => {
            console.log("subjectReport.fetchRelationships");
            console.log("subjectReport.fetchRelationships - respondents");
            console.log(aSubject.respondents);
            let rels = [];
            aSubject.respondents.forEach((respondent) => {
                console.log("subjectreport.fetchRelationship - respondent.relationship "+respondent.relationship);
                if (!rels.includes(respondent.relationship)){
                    rels.push(respondent.relationship);
                }
            });
            return rels;
        }





    let radarOps = {
        chart: {
          height: 400,
          type: 'radar',
          dropShadow: {
            enabled: true,
            blur: 1,
            left: 1,
            top: 1
          }
        },
        legend: {
            position: 'right',
            horizontalAlign: 'left'
        },
        colors: ['#783CB8', '#FABB22', '#D232DE', '#FA4422', '#D4025E'],
        title: {
          text: 'Clusters per respondentengroep'
        },
        stroke: {
          width: 2
        },
        fill: {
          opacity: 0.05
        },
        markers: {
          size: 0
        },
        xaxis: {
          categories: [],
          labels: {
            show: true,
            align: 'right',
            minWidth: 0,
            maxWidth: 160,
            style: {
                colors: [],
                fontSize: '12px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 400,
                cssClass: 'apexcharts-yaxis-label',
            },
            offsetX: 0,
            offsetY: 0,
            rotate: 0,
            //formatter: (value) => { if (value < 0.01)return 0; else return value.toPrecision(2) },
        },
        
        },
        yaxis: {
            show: true,
            showAlways: true,
            showForNullSeries: true,
            seriesName: undefined,
            opposite: false,
            reversed: false,
            logarithmic: false,
            logBase: 10,
            tickAmount: 4,
            min: 0,
            max: 0.8,
            //range: 0.85,
            forceNiceScale: true,
            //floating: true,
            decimalsInFloat: 1,
            labels: {
                show: true,
                align: 'right',
                minWidth: 0,
                maxWidth: 160,
                style: {
                    colors: [],
                    fontSize: '12px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 400,
                    cssClass: 'apexcharts-yaxis-label',
                },
                offsetX: 0,
                offsetY: 0,
                rotate: 0,
                formatter: (value) => { if (value < 0.01)return 0; else return value.toPrecision(2) },
            },
            axisBorder: {
                show: true,
                color: '#78909C',
                offsetX: 0,
                offsetY: 0
            },
            axisTicks: {
                show: true,
                borderType: 'solid',
                color: '#78909C',
                width: 6,
                offsetX: 0,
                offsetY: 0
            },
            title: {
                text: undefined,
                rotate: -90,
                offsetX: 0,
                offsetY: 0,
                style: {
                    color: undefined,
                    fontSize: '12px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                    cssClass: 'apexcharts-yaxis-title',
                },
            },
            crosshairs: {
                show: true,
                position: 'back',
                stroke: {
                    color: '#b6b6b6',
                    width: 1,
                    dashArray: 0,
                },
            },
            tooltip: {
                enabled: true,
                offsetX: 0,
            },
            
        }
    };//radarOptions
        /*yaxis: {
            min: 1, 
            max: 5,
            tickAmount: 4
        }*/

        /*
    let roseOps = {
        chart: {
            type: 'polarArea',
        }, 
        // TODO: colors moeten onbeperkt uitbreidbaar zijn ?  i.f.v. variabele set clusters ==> meegeven in de survey
        colors: ['#783C96', '#783CB8', // Cluster I: visie, verandering & daadkracht
            '#FABB66', '#FABB22', '#FABB88', '#FABB44', // Cluster II: Samenwerking & connectie
            '#D23278', '#D2329A', '#D232BC', '#D232DE', // Cluster III: Samenwerking & connectie: Betrokkenheid genereren: 6
            '#FA4422', '#FA2222',// Cluster IV
            '#D4023C', '#D4025E', // Cluster V
           ],
        labels: [],
        legend: {
            show: false,
            position: 'bottom',
            labels: {
              colors: ['#000000'],
              useSeriesColors: false,
            },
        },
        fill: {
            opacity: 0.8,
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: 'right',
                },
            }
        }],
        yaxis: {

            labels:{
                formatter: (value) => { if (value < 0.01)return 0; 
                    else return (Math.round(value * 100) / 100); },
            },
        }
      };
 */