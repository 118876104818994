import React, { useEffect, useState} from "react";
import {
  TextField,
  Tooltip,
} from "@mui/material";
import {
  ArrowCircleDownOutlined,
  ArrowCircleUpOutlined,
  HighlightOffOutlined,
} from "@mui/icons-material";

const ClusterItemComponent = ({id, name, updateCluster, removeCluster, moveUp, moveDown, parentId, index, isLast}) => {
  const [myTextValue, setMyTextValue] = useState(null);

  useEffect(()=> {
    console.log("ITEM useEffect name changed to "+name);
    setMyTextValue(name);
  },[name]);

  useEffect(() => {
    console.log("ITEM textvalue changed")
  }, [myTextValue]);

  const handleChange = (newValue) => {
    setMyTextValue(newValue);
  }

  const handleChanged = () => {
    updateCluster(id,myTextValue, parentId);
  }

  const handleMoveUp = () => {
    console.log("ITEM move cluster up");
    if (index>0) moveUp(id, parentId);
  }

  const handleMoveDown = () => {
    if (!isLast) moveDown(id, parentId);
  }

  const handleRemove = () => {
    console.log("ITEM removing a cluster");
    removeCluster(id, parentId);
  }
  
  return (
    <React.Fragment>
            {(myTextValue !== null) &&
            <TextField fullWidth size="small" value={myTextValue} onChange={(e) => handleChange(e.target.value)} onBlur={handleChanged}/>}
            <Tooltip title="naar boven verplaatsen" placement="top">
                <ArrowCircleUpOutlined color={(index>0) ? "secondary" : "disabled"} style={{ marginLeft: "10px" }} onClick={handleMoveUp} />
            </Tooltip>
            <Tooltip title="naar beneden verplaatsen" placement="top">
                <ArrowCircleDownOutlined color={isLast ? "disabled":"secondary"} style={{ marginLeft: "10px" }} onClick={handleMoveDown} />
            </Tooltip>
            <Tooltip title="verwijderen" placement="right">
                <HighlightOffOutlined color="secondary" style={{ marginLeft: "10px" }} onClick={handleRemove} />
            </Tooltip>
    </React.Fragment>
  );
};
export default ClusterItemComponent;
