import { useContext } from "react";
import { RespondContext } from "../context/RespondContext";

export const useRespond = () => {
    const context = useContext(RespondContext);

    if (context === undefined){
        throw new Error("useRespond() must be unsed inside a RespondProvider");
    }

    return context;
}