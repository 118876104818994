import React, { useState } from "react";
import { History } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
//import axios from 'axios';
import {PersonsContainer} from '../../unstated/persons';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const EditPerson = () => {

	const persons = PersonsContainer.useContainer();
	console.log ("selected person's id = "+persons.selectedPerson);

	const [stateId, setStateId] = useState(persons.selectedPerson.id);
	const [firstName, setFirstName] = useState(persons.selectedPerson.firstName);
	const [lastName, setLastName] = useState(persons.selectedPerson.lastName);
	const [email, setEmail] = useState(persons.selectedPerson.email);
	const [birthDate, setBirthDate] = useState(formatDate(persons.selectedPerson.birthDate));
	
	const history = useHistory();


	

	function onChangePersonFirstName(e){
		setFirstName(e.target.value);
		// currently no immediate updating
//		this.personsContainer.updatePerson(this.state)
	}
	function onChangePersonLastName(e){
		setLastName(e.target.value);
	}
	function onChangePersonEmail(e){
		setEmail(e.target.value);
	}
	function onChangePersonBirthDate(e){
		setBirthDate(new Date(e.target.value))
		// TODO (ongoing) verifiëren of casting expliciet nodig is
	}

	function onSubmit() {
		console.log("in state, current selected person id = " + persons.selectedPerson);
		console.log("???in here , current selected person id = " + persons.selectedPerson);
		
		console.log("EDIT PERSON on submit starts")
		
		console.log("EDIT PERSON on submit personsContainer set")
		return async event =>  {
			console.log("EDIT PERSON onSubmit in return aSync event")
			event.preventDefault()  // wat is dit ???
			const personObject = {
				id: stateId,
				firstName: firstName,
				lastName: lastName,
				email: email,
				birthDate: birthDate
				};
			try{
				await persons.updatePerson(personObject);
			}
			catch (error){
				console.log(error)
			}
			console.log("EDIT PERSON onSubmit about to push")
			history.push('/person-list');
		}//returned async event
  	}//onSubmit
	
	function formatDate (date) {
	    var d = new Date(date),
	        month = '' + (d.getMonth() + 1),
	        day = '' + d.getDate(),
	        year = d.getFullYear();

	    if (month.length < 2) 
	        month = '0' + month;
	    if (day.length < 2) 
	        day = '0' + day;

	    return [year, month, day].join('-');
	}

	
	return (
		<PersonsContainer.Provider>
		<div className="form-wrapper">
			<Form onSubmit={onSubmit()}>
	
				<Form.Group controlId="FirstName">
				<Form.Label>First Name</Form.Label>
				<Form.Control type="text" value={firstName} onChange={onChangePersonFirstName} />
				</Form.Group>
	
				<Form.Group controlId="LastName">
				<Form.Label>Last Name</Form.Label>
				<Form.Control type="text" value={lastName} onChange={onChangePersonLastName} />
				</Form.Group>
	
				<Form.Group controlId="Email">
				<Form.Label>email</Form.Label>
				<Form.Control type="email" value={email} onChange={onChangePersonEmail} />
				</Form.Group>
	
				<Form.Group controlId="BirthDate">
				<Form.Label>Birth Date</Form.Label>
				<Form.Control type="date" value={formatDate(birthDate)} onChange={onChangePersonBirthDate} />
				</Form.Group>
				
				<Button variant="danger" size="lg" block="block" type="submit">
					Update Person
				</Button>
			</Form>
		</div>
		</PersonsContainer.Provider>
	);
	
	
}

export default EditPerson;
	//		</Subscribe>


	// import React, { Component } from "react";
	// import Form from 'react-bootstrap/Form';
	// import Button from 'react-bootstrap/Button';
	// import axios from 'axios';
	// //import {Subscribe} from 'unstated-next';
	// import { useContainer } from "unstated-next";
	// import {PersonsContainer} from '../../unstated/persons';
	
	// class EditPerson extends Component {
	
		
		
	// 	constructor(props){
	// 		super(props)
	// 		this.onChangePersonFirstName = this.onChangePersonFirstName.bind(this);
	// 		this.onChangePersonLastName = this.onChangePersonLastName.bind(this);
	// 		this.onChangePersonEmail = this.onChangePersonEmail.bind(this);
	// 		this.onChangePersonBirthDate = this.onChangePersonBirthDate.bind(this);
	// 		this.onSubmit = this.onSubmit.bind(this);
	
	// 		// state
	
	// 		this.state={
	// 			id:null,
	// 			firstName:"",
	// 			lastName:"",
	// 			email:"",
	// 			birthDate:new Date()
	// 		}
	// 		this.personsContainer={}
	// 		console.log("EDIT PERSON CONSTRUCTOR ENDS")
	// 	}
	
	// 	componentDidMount = async() => {
	// 		console.log("EDIT PERSON component did mount starts")
			
	// 		try{
	// 			console.log("trying to fetch person for edit");
	// 			const res = await axios.get('http://localhost:9000/api/persons/' + this.props.match.params.id)
	// 			this.setState({
	// 				id: res.data.id,
	// 				firstName: res.data.firstname,
	// 				lastName: res.data.lastname,
	// 				email: res.data.email,
	// 				birthDate: res.data.birthdate
	// 				}
	// 			);
	// 		}
	// 		catch(error) {console.log(error)}
	// 		console.log("EDIT PERSON component did mount ends")
	// 	}
		
	
	// 	onChangePersonFirstName(e){
	// 		this.setState({firstName: e.target.value})
	// //		this.personsContainer.updatePerson(this.state)
	// 	}
	// 	onChangePersonLastName(e){
	// 		this.setState({lastName: e.target.value})
	// 		console.log("lastname set to "+e.target.value)
	// 	}
	// 	onChangePersonEmail(e){
	// 		this.setState({email: e.target.value})
	// 	}
	// 	onChangePersonBirthDate(e){
	// 		//this.setState({birthDate: new Date(e.target.value)})
	// 		this.setState({birthDate: e.target.value})
	// 		// TODO (ongoing) verifiëren of casting expliciet nodig is
	// 	}
	
	// 	onSubmit(personsContainer) {
	// 		console.log("EDIT PERSON on submit starts")
	// 		this.personsContainer = personsContainer;
	// 		console.log("EDIT PERSON on submit personsContainer set")
	// 		return async event =>  {
	// 			console.log("EDIT PERSON onSubmit in return aSync event")
	// 			event.preventDefault()
	// 			const personObject = {
	// 				id: this.state.id,
	// 				firstName: this.state.firstName,
	// 				lastName: this.state.lastName,
	// 				email: this.state.email,
	// 				birthDate: this.state.birthDate
	// 				};
	// 			try{
	// 				await personsContainer.updatePerson(personObject)
	// 			}
	// 			catch (error){
	// 				console.log(error)
	// 			}
	// 			console.log("EDIT PERSON onSubmit about to push")
	// 			this.props.history.push('/person-list')
	// 		}//returned async event
	// 	  }//onSubmit
		
	// 	formatDate = date => {
	// 		var d = new Date(date),
	// 			month = '' + (d.getMonth() + 1),
	// 			day = '' + d.getDate(),
	// 			year = d.getFullYear();
	
	// 		if (month.length < 2) 
	// 			month = '0' + month;
	// 		if (day.length < 2) 
	// 			day = '0' + day;
	
	// 		return [year, month, day].join('-');
	// 	}
	
	// 	render() {
	// 		console.log("EDIT PERSON render starts")
	// 		console.log("BirthDate " + this.state.birthDate)
	// 		return (
	// 			/*<Subscribe to={[PersonsContainer]}>*/
	// 			<PersonsContainer.Provider>
	// 				{personsContainer =>
	// 					<div className="form-wrapper">
	// 						<Form onSubmit={this.onSubmit(personsContainer)}>
					
	// 							<Form.Group controlId="FirstName">
	// 							<Form.Label>First Name</Form.Label>
	// 							<Form.Control type="text" value={this.state.firstName} onChange={this.onChangePersonFirstName} />
	// 							</Form.Group>
					
	// 							<Form.Group controlId="LastName">
	// 							<Form.Label>Last Name</Form.Label>
	// 							<Form.Control type="text" value={this.state.lastName} onChange={this.onChangePersonLastName} />
	// 							</Form.Group>
					
	// 							<Form.Group controlId="Email">
	// 							<Form.Label>email</Form.Label>
	// 							<Form.Control type="email" value={this.state.email} onChange={this.onChangePersonEmail} />
	// 							</Form.Group>
					
	// 							<Form.Group controlId="BirthDate">
	// 							<Form.Label>Birth Date</Form.Label>
	// 							<Form.Control type="date" value={this.formatDate(this.state.birthDate)} onChange={this.onChangePersonBirthDate} />
	// 							</Form.Group>
								
	// 							<Button variant="danger" size="lg" block="block" type="submit">
	// 								Update Person
	// 							</Button>
	// 						</Form>
	// 					</div>
	// 				}
	// 			</PersonsContainer.Provider>
	// 		);
	// 	}
	// }
	
	// export default EditPerson;
	// 	//		</Subscribe>