import { useEffect, useState } from "react";
import axios from 'axios';
import configData from '../config/config.json';
//import { useAuthUser } from "./useAuthUser";
import { useAuthContext } from "./useAuthContext";

export const useManageSurveys =  () => {

    //const {getUser} = useAuthUser();
    const { user }= useAuthContext();

    const [isCancelled, setIsCancelled] = useState(false);
    const [error, setError] = useState(null);
    const [isPending, setIsPending] = useState(false);
    
    const getSurveys = async () => {
        setError(null);
        setIsPending(true);

        try{
            //fetch the surveys from the db
            //POGING om gewoon met de user te werken uit useAuthContext
            
            /*const checkedUser = await getUser();
            const res = await axios.get(configData.SERVER_URL+'/api/surveys/', {headers: { 'Authorization': 'Bearer ' + checkedUser.token}});*/
            const res = await axios.get(configData.SERVER_URL+'/api/surveys/', {headers: { 'Authorization': 'Bearer ' + user.token}});
            
            
            if (!isCancelled){
                setIsPending(false);
                setError(null);
            }// if !isCancelled
            return res.data;
        }
        catch (err){
            if (!isCancelled){
                console.log(err.message);
                setError(err.message);
                setIsPending(false);    
            } // if !isCancelled
        } // catch errors   
    }

    const addSurvey = async (newSurvey) => {
        setError(null);
        setIsPending(true);
        console.log(newSurvey);
/** 2024-05-09 adjusted on api side: accept simplified Survey */
        try{
            /*const checkedUser = await getUser();
            const res = await axios.post(configData.SERVER_URL+'/api/surveys/', newSurvey, {headers: { 'Authorization': 'Bearer ' + checkedUser.token}});*/
            const res = await axios.post(configData.SERVER_URL+'/api/surveys/', newSurvey, {headers: { 'Authorization': 'Bearer ' + user.token}});
            if (!isCancelled){
                setIsPending(false);
                setError(null);
            }// if !Iscancelled
            return res.data; //the added survey
        }
        catch(err){
            if (!isCancelled){
                console.log(err.message);
                setError(err.message);
                setIsPending(false);
            }// !if !isCancelled
        }//catch errors
    } // addSurvey

   useEffect(()=> { // is fired when unmounting, preventing state to be updated after unmounting (generates an error)
        return () => setIsCancelled(true);
    }, []);

    //return {setMessagesForSurveyId, changeInviteMessage, changeReminderMessage, changeConfigFile, setAndGetAllSurveys, setAndGetSurvey, error, isPending} ;
    return {addSurvey, getSurveys, error, isPending} ;
}