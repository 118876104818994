import React, { useEffect, useState } from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import IconButton from "@mui/material/IconButton";

import NavigateBeforeOutlinedIcon from '@mui/icons-material/NavigateBeforeOutlined';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';

import Question from "./question-component";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useManageRespondent } from "../../hooks/useManageRespondent";
import { useManageSurvey } from "../../hooks/useManageSurvey";

import {useRespond} from "../../hooks/useRespond";
import {useManageRespond} from "../../hooks/useManageRespond";

import { useTranslation } from "react-i18next";

const Respond = () => {

  const {t} = useTranslation();
  const { respondentId } = useParams();

  const [surveyPages, setSurveyPages] = useState([]);
  const { getRespondentFromDb} = useManageRespondent(); //importing the function
  const { setSurveyById} = useManageSurvey(); // importing the function
  const {createOrUpdateAnswer, finalizeRespond} = useManageRespond(); // importing the function

  const {respondent, survey, questions, respondedQuestions, currentPageNumber, canFinalize, isUnfinished, initialize, answerQuestion, movePageLeft, movePageRight, finalize} = useRespond(); 
  //destructuring the elements from the respondContext


  useEffect(() => {
    const setBasics = async (respondentId) => {
      const resp = await getRespondentFromDb(respondentId);
      const surv = await setSurveyById(resp.surveySubject.surveyId);
      initialize(resp,surv);
      await setSurveyPages(JSON.parse(surv.configFile).pages);
    }
    setBasics(respondentId);
  }, []); //useEffect TODO: wat in 2e argument ?? ==> wanneer wel en wanneer niet een page refresh
  
  /** bij verandering van currentPageNumber (in context, niet state), scrollt de pagina naar boven */
  useEffect(() => {
      window.scrollTo(0,0);
  }, [currentPageNumber]);

  useEffect(() => {
    console.log("can finalize changed to "+canFinalize);
  },[canFinalize]);
  
  let getCurrentResponseByQuestionId = (questionId) => {
      let response = respondent.responses.find(o => o.questionId === questionId);
      return response;
  }

  /** functie die wordt doorgegeven naar question */
  const answerGiven = async (feedback) => {  
      // alternatively use React.useCallback((feedback) => {
      await answerQuestion(feedback.response); // updates local state
      return feedback ;
  };

  const afronden = () => {
    // persist the change first
    finalizeRespond(respondent);
    finalize();//updates local state
  }

  return (
      <div>
        <Card><CardContent>{t('360° feedback')} {t('for')} {respondent && respondent.surveySubject.subjectPerson.firstName + " " + respondent.surveySubject.subjectPerson.lastName}</CardContent></Card>
        {respondent && (respondent.status !== 'completed') && isUnfinished &&
            <div>
                {(surveyPages.length > 0) && surveyPages[currentPageNumber].pageIntro && 
                  <div className="questionContainer">
                    <div className="pageInstructions" dangerouslySetInnerHTML={{ __html: surveyPages[currentPageNumber].pageIntro }}></div>
                  </div>
                }
                <div>
                  {(survey && respondent) && (surveyPages.length > 0) &&surveyPages[currentPageNumber].questions.map(question => (
                          <Question key={'qId'+question.questionId} q={question} r={getCurrentResponseByQuestionId(question.questionId)} respondent={respondent} answerGiven={answerGiven}/>
                      ))}
                </div>
                <div className="buttonContainer">
                    {currentPageNumber > 0 && 
                        <div className="pageLeftButton"><IconButton color="primary" onClick={(e) => movePageLeft()}><NavigateBeforeOutlinedIcon /></IconButton></div>
                    }
                    {survey && (surveyPages.length > 0) && currentPageNumber < (surveyPages.length -1) && 
                        <div className="pageMiddleButton"><IconButton color="primary" onClick={(e) => movePageRight()}><NavigateNextOutlinedIcon /></IconButton></div>
                    }
                    {/**{canFinalize && 
                        <div className="pageRightButton"><Button variant="contained" onClick={() => afronden()}>{t('Finalize')}</Button></div>
                    }*/}
                    <div className="pageRightButton"><Button variant="contained" disabled={!canFinalize} onClick={() => afronden()}>{t('Finalize')}</Button></div>
                </div>
                
                <br/>
                <div>{t('Page')} {currentPageNumber + 1} / {(survey && surveyPages.length )}</div>
            </div>
        }

        {respondent && ((respondent.status === 'completed')||(!isUnfinished)) &&
          <div>
            <br/><br/>
            <div dangerouslySetInnerHTML={{__html:survey.outro}} />
          </div>
        }
      </div>
	);
}

export default Respond;

/**
 * USE CASES:
 * 1. respond questions (not deleting answers ?) ==> update the local state AND the db-state
 *  !! do (1) for different question types
 * 2. finalize the survey as soon as all responses are given & only when all responses are given
 * 
 * TO GET STARTED: 
 * Based on the respondentId ==> fetch the survey questions & the answers & the details of the subject (name)
 * 
 */