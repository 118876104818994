import "../editor/MsgEditor.css";

import { Clear, ExitToApp, Save } from "@mui/icons-material";
import { Button, TextField } from "@mui/material";
import { useState, useEffect } from "react";
import "./RespondentForm.css";


export default function SurveyFlatEditorForm({updateSurveyMessage, handleClose, placeholder}){

    const [editorText, setEditorText] = useState("");

    const handleChange = myText => {
        setEditorText(myText.target.value);
    };

    const resetForm = () => {
        setEditorText("");
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        updateSurveyMessage(editorText);
        resetForm();
    }

    useEffect(() => {
        console.log("surveyFlatEditorForm useEffect fired");
        setEditorText(placeholder);
    },[]);

    return (
        <div className="text-editor">

            <TextField id="config-textarea" label="configuratie" placeholder={"plaats de configuratie hier"} multiline minRows={4} fullWidth  
                       onChange={(newValue) => setEditorText(newValue.target.value)} value={editorText}
                       />
            <div className="bottomButtons">
                <div>
                    <Button
                        variant="outlined"
                        onClick={handleSubmit} 
                        startIcon={<Save />} >Bewaren
                    </Button>
                </div>
                <div>
                    <Button 
                        variant="outlined"
                        onClick={resetForm} 
                        startIcon={<Clear />} >Wissen
                    </Button>
                </div> 
                <div>
                    <Button 
                        variant="outlined"
                        onClick={handleClose} 
                        startIcon={<ExitToApp />} >Sluiten
                    </Button>
                </div> 
            </div>
        </div>
    );
}

/**placeholder={props.placeholder}**/
/**placeholder={placeholder} */