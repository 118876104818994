import React, { Component } from "react";
import { useState, useEffect } from "react";
import {Space, Tooltip, Table, Button, Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";

//import PersonDetails from "./person-details";

//import Table from 'react-bootstrap/Table';
import {Link, Route } from 'react-router-dom';
import { PersonsContainer } from "../../unstated/persons";
import { DeleteTwoTone, EditTwoTone } from "@ant-design/icons";
import CreatePerson from "./create-person.component";

function UserList (){

	const pCont = PersonsContainer.useContainer();
	const [isReady, setIsReady] = useState([false]);
	const [data, setData] = useState([]);
	const [showingModal, setShowingModal] = useState(false);
	const [loading, setLoading] = useState(false);
	
	const columns = [
		{
		  title: 'voornaam',
		  dataIndex: 'firstName',
		  key: 'firstName',
		  render: text => <a>{text}</a>,
		},
		{
		  title: 'naam',
		  dataIndex: 'lastName',
		  key: 'lastName',
		},
		{
		  title: 'e-mail',
		  dataIndex: 'email',
		  key: 'email',
		},
		{
		  title: 'paswoord',
		  dataIndex: 'password',
		  key: 'password',
		},
		{
		  title: 'acties',
		  key: 'action',
		  render: (text, record) => (
			<Space size="large">
			  <Tooltip title={`${record.firstName} bewerken`}><a><EditTwoTone/></a></Tooltip>
			  <Tooltip title={`${record.firstName} verwijderen`}><a><DeleteTwoTone/></a></Tooltip>
			</Space>
		  ),
		},
	  ];
	
	  
	  function insertData (personList){
		let idata = [];
		personList.forEach(function (person, index) {
		  idata.push({
			key: person.id,
			firstName: person.firstName,
			lastName: person.lastName,
			email: person.email,
			password: person.password,
		  });
		});
		setData(idata);
	}
	
	  useEffect(() => {
		setIsReady(false);
		const fetchPersons = async () => {
		  const fetchedPersons = await pCont.loadPersons();
		  insertData(fetchedPersons);
		  //sCont.setSelectedSubject(subject);
		  setIsReady(true);
		}
		fetchPersons();
	  }, []);
	
	  
	  const addPersonView = () => {
		  setShowingModal(true);
	  }
	  const handleCancelClick = () => {
		  setShowingModal(false);
	  }
	  const handleOkClick = () => {
		setShowingModal(false);
	}

	  return (
		<PersonsContainer.Provider>
		  <div>
			{(data.length > 0) ? <div>
			  <Table columns={columns} dataSource={data} />
			</div> : <div>loading ...</div>}
			
			{ !showingModal && <Button type="primary" shape="circle" icon={<PlusOutlined onClick={addPersonView} />}></Button>}

			<Modal
				visible={showingModal}
				title="gebruiker toevoegen"
				onOk={handleOkClick}
				onCancel={handleCancelClick}
				width={1000}
				footer={[
				<Button key="back" onClick={handleCancelClick}>
					afsluiten
				</Button>,
				]}
			>
			<CreatePerson/>
			</Modal>


			
		  </div>
		</PersonsContainer.Provider>
		);
}
export default UserList;