import React, { useCallback, useEffect, useState } from "react";
import { useManageSurveys } from "../../hooks/useManageSurveys";

import { Add, Edit } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import EditSurveyModal from "./EditSurveyModal";
import EditSurveyForm from "./EditSurveyForm";
import { useManageSurvey } from "../../hooks/useManageSurvey";
import { isNullOrUndef } from "chart.js/helpers";

const SurveyList = () => {
  const { getSurveys, addSurvey, error, isPending} = useManageSurveys();/** todo: add edit & remove options and use isPending */
  const { changeConfigFile } = useManageSurvey();

  const [myData, setMyData] = useState([]);
  const [isReady, setIsReady] = useState(false);
  const [showModal, setShowModal] = useState(false);
  /**const [currentMessageText, setCurrentMessageText] = useState('enter survey name here');*/
  

  function readableError(error){
	if (error === 'Request failed with status code 401'){
		return "Er liep iets mis met je toegangsrechten.  Dit kan te wijten zijn aan een vervallen beveiligingscertificaat.  Log opnieuw in indien nodig en/of ververs de pagina";
	}
	else return error;
}


  const handleCloseModal = () => {
    setShowModal(false);
  };

  const addSurveyInComponent = async (newSurvey) => {
        //adding survey in component, setting new survey in db
        //console.log("adding survey in component, setting new survey in db");
        //console.log(newSurvey);
        const dbSurvey = await addSurvey(newSurvey);
        //after retrieval, immediatly set the configFile (to ensure the presence of the required info)
        //console.log("after retrieval, immediatly set the configFile (to ensure the presence of the required info)");
        let configObject = {id: dbSurvey.id, name: newSurvey.name, info: "", clusters:[], pages:[], questions:[]};
        //console.log(configObject);
        let configText = JSON.stringify(configObject);
        //console.log("changing the configFile in the database");
        changeConfigFile(dbSurvey.id, configText).then((configResult) => {
            setMyData([...myData, dbSurvey]);
            setShowModal(false);
        });// then

	//setMyData([...myData, dbSurvey]);
	//setShowModal(false);
  } //addSurveyInComponent

  const fetchSurveys = useCallback(async () => {
	//console.log("fetching surveys");
	if (myData.length === 0){
		const data = await getSurveys();
		await setMyData(data);
		await setIsReady(true);
    //console.log("surveys =");
    //console.log(data);
	//	console.log("surveys fetched");	
	}
  }, []);

  useEffect(() => {
	  fetchSurveys();
  }, [fetchSurveys]);

  return (
    <div>
		{isReady && myData.length>0 && <div>number of surveys = {myData.length}</div>}
      {!isReady && <div>Loading...</div>}
      {isReady && myData && (
        <>
          {!showModal && (
            <div className="rightButton">
              <Button
                variant="outlined"
                onClick={() => setShowModal(true)}
                startIcon={<Add />}
              >
                Survey
              </Button>
            </div>
          )}
		  {showModal && <EditSurveyModal handleClose={handleCloseModal}>
                    <EditSurveyForm addSurvey={addSurveyInComponent} handleClose={handleCloseModal} placeholder="enter survey name here"/>
                </EditSurveyModal>}
          <table className="personsTable">
            <thead>
              <tr>
                <th>Naam</th>
                {/**<th>Info</th>*/}
                <th># subjecten</th>
                <th>Bewerken</th>
              </tr>
            </thead>
            <tbody>
              {myData.map((survey) => {
                return (
                  <tr key={survey.id}>
                    <td>
                      <a href={"/survey/" + survey.id}>{survey.name}</a>
                    </td>
                    {/** configFile = plain text  <td>{survey.configFile && survey.configFile.info && (survey.configFile.info)}</td>*/}
                    <td>{!isNullOrUndef(survey.surveySubjects) && survey.surveySubjects.length}</td>
                    <td>
                      <IconButton
                        color="secondary"
                        aria-label="bewerken"
                        component={Link}
                        to={"/survey/" + survey.id}
                      >
                        <Edit />
                      </IconButton>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      )}
	  {error && <p>{readableError(error)}</p> }
    </div>
  );
};

export default SurveyList;
