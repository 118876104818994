import jwtDecode from "jwt-decode";

export default function authHeader(){
    //console.log("authHeader accessed");
    const user = JSON.parse(localStorage.getItem('user'));
    const token = JSON.parse(localStorage.getItem('accessToken'));

    /** 
     * verifieer of de token expired is
     * zoja ==> redirect
    */
    const checkExpiration = async () => {
        //console.log("authHeader - checking expiration");
        const decoded =  await jwtDecode(await JSON.parse(localStorage.getItem('accessToken')));
        const expirationDate = parseInt(decoded.exp);
        const current_time = new Date().getTime() / 1000;
        //console.log('current_time   = ' + current_time);
        //console.log('expirationDate = ' + expirationDate);
        //console.log('current_time > expirationDate ' + (current_time > expirationDate));
        if (current_time > expirationDate) {
            //console.log("expired");
            localStorage.removeItem('accessToken');
            localStorage.removeItem('user');
        }
        return expirationDate > current_time;
    }

    //if (user && user.accessToken){
    if (user && checkExpiration()){
        return { 'Authorization': 'Bearer ' + token};
    } else { 
        return {};
    }//else
}//export function

/*
We have methods for retrieving data from a server. 
In the case we access protected resources, the HTTP request needs an Authorization header.

The code above checks Local Storage for user item. 
If there is a logged in user with accessToken (JWT), return HTTP Authorization header. 
Otherwise, return an empty object.
*/