import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import { useEffect, useRef, useState } from "react";

//TO REMOVE AFTER TESTING
import { useManageSurvey } from "../../hooks/useManageSurvey";
import { Button, Divider, Grid } from "@mui/material";
import SurveyPage from "./survey-page-component";
import { AddCircleOutline, ExitToApp, Save } from "@mui/icons-material";
import uuidv4 from "../../utilities/basicUtilities";

//const SurveyPages = ({survey, changeConfigFile, handleClose}) => {
const SurveyPages = ({survey, changeConfigFile, handleClose}) => {
  //TO REMOVE AFTER TESTING
  //const {setSurveyById, changeConfigFile} = useManageSurvey();
  //const surveyId = "b061da46-f88f-11ed-b8ab-e217ccb939f4"; // demo survey
  //const [survey, setSurvey] = useState(null);
  const changesOccurred = useRef(false);
  const firstRun = useRef(true);

  const [configFile, setConfigFile] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);
  const [isFirstPage, setIsFirstPage] = useState(null);
  const [isLastPage, setIsLastPage] = useState(null);
  
  
  const configSetCount = useRef(0);
    
  const [tabValue, setTabValue] = useState(0);

  //TO REMOVE AFTER TESTING ?are we setting config twice
  /*useEffect(() => {
    setSurveyById(surveyId).then(
      (mySurvey) => {
      setSurvey(mySurvey); setConfigFile(JSON.parse(mySurvey.configFile));
    });
  },[]);*/


  //KEEP ?
  useEffect(() => {
    if (survey) {
      setConfigFile(JSON.parse(survey.configFile));
    }
  }, [survey]);

  useEffect(() => {
    if (firstRun.current===true) firstRun.current = false;
    //else setChangesOccurred(true);
    else changesOccurred.current = true;
    console.log("============ CHANGES ============");
  }, [configFile]);

  useEffect(() => {
    if (firstRun.current===true) firstRun.current = false;
    //else setChangesOccurred(true);
    else changesOccurred.current = true;

    configSetCount.current = configSetCount.current+1;
    console.log("config set count = "+configSetCount.current);
    console.log(configFile);
    if (configFile!==null){
      if (configFile.pages.length>0){
        setCurrentPage(configFile.pages[tabValue]);
        setIsFirstPage(tabValue === 0);
        setIsLastPage(tabValue === (configFile.pages.length-1));
      }
    }
  }, [configFile]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setCurrentPage(configFile.pages[newValue]);
    setIsFirstPage(newValue===0);
    setIsLastPage(newValue===(configFile.pages.length-1));
  };

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };


  const handlePageChange = (newPage) => {
    console.log("handlePageChange started");
    let _config = structuredClone(configFile);
    console.log("_config created (handlePageChange");
    console.log(_config);
    _config.pages.forEach((p, pIndex) => {
      if (p.pageId === newPage.pageId){
        _config.pages.splice(pIndex, 1, newPage);
        console.log("_config.pages spliced");
        setCurrentPage(newPage);
        setConfigFile(_config);
        console.log("setConfigFile executed (pageChange)");
      } // if (p.pageId === page.pageId)
    }); // _config.pages.forEach*/
  }

  const handleAddPage = () => {
    let _configFile = structuredClone(configFile);
    _configFile.pages.push({pageId:uuidv4(), pageIntro:"", questions:[] });
    setConfigFile(_configFile);
  }
  const handleRemovePage = (_page, _index) => {
    let _configFile = structuredClone(configFile);
    _configFile.pages.splice(_index,1);
    setTabValue(_index-1);
    setCurrentPage(_configFile.pages[_index-1]);
    setConfigFile(_configFile);
  }

  const handleClustersChanged = (surveyClusters) => {
        console.log("handleClustersChange started");
        let _config = structuredClone(configFile);
        console.log("_config created  (clustersChanged)");
        console.log(_config);
        _config.clusters=surveyClusters;
        console.log("_config updated (clustersChanged)");
        setConfigFile(_config);
        console.log("setConfigFile executed (clustersChanged)");
  }

  const handleCombinedChange = (newPage, newSurveyClusters) => {
        let _config = structuredClone(configFile);
        _config.pages.forEach((p, pIndex) => {
            if (p.pageId === newPage.pageId){
              _config.pages.splice(pIndex, 1, newPage);
              setCurrentPage(newPage);
            }
        })//pages.forEach
        _config.clusters=[...newSurveyClusters];
        setConfigFile(_config);
  }

  const handleQuestionPageMove = (movingQuestion, adjustedPage, pageIndex, direction) => {
    let _config = structuredClone(configFile);
    _config.pages.splice(pageIndex,1,adjustedPage);
    _config.pages[(pageIndex+direction)].questions.push(movingQuestion);
    setConfigFile(_config);
  }

  const handleClosePage = () => {
    handleClose();
  };
  const handleSubmit = () => {
    changeConfigFile(survey.id, JSON.stringify(configFile));
    handleClose();
  };


  /**const uuidv4 = () => {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
      (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
    );
  }*/

  return (
    <Grid container spacing={2}>
        <Grid item xs={9} style={{padding: "15px", display: "flex", justifyContent: "center", alignItems: "center",}}>
          <Tabs value={tabValue} onChange={handleTabChange} variant="scrollable" scrollButtons="auto" aria-label="scrollable auto tabs example">
            {(configFile!==null) && 
                configFile.pages.map((page, pageIndex) => (
                  <Tab key={"pageTab_"+page.pageId} label={"page "+(pageIndex+1)} {...a11yProps(pageIndex)}></Tab>
                ))
            }
          </Tabs>
        </Grid>
        <Grid item xs={3} style={{padding: "15px", display: "flex", justifyContent: "center", alignItems: "center",}}>
            <Button variant="outlined" color="secondary" onClick={() => handleAddPage()} startIcon={<AddCircleOutline/>}>pagina toevoegen</Button>
        </Grid>
        <Grid item xs={12}><Divider textAlign="left"></Divider></Grid>

        <Grid item xs={12} style={{padding: "15px", display: "flex", justifyContent: "center", alignItems: "center",}}>
              {configFile!==null && currentPage!==null && tabValue!==null &&
                  (<SurveyPage page={currentPage} surveyClusters={configFile.clusters} pageIndex={tabValue} 
                              handlePageChange={handlePageChange} handlePageRemoved={handleRemovePage} handleClustersChanged={handleClustersChanged} handleCombinedChange={handleCombinedChange}
                              handlePageMove={handleQuestionPageMove}
                              isFirstPage={isFirstPage} isLastPage={isLastPage}/>)
              }
        </Grid>
        <Grid item xs={12}>
            <div className="bottomButtons">
                    {(changesOccurred.current===true) && <Button variant="outlined" onClick={handleSubmit} startIcon={<Save/>}>Bewaren</Button>}
                    <Button variant="outlined" onClick={handleClosePage} startIcon={<ExitToApp />} style={{marginLeft:"15px"}}>Sluiten</Button>
            </div>
        </Grid>
    </Grid>
  );
};
export default SurveyPages;
//({page, surveyClusters, pageIndex, handlePageChange, handlePageRemoved, isFirstPage, isLastPage})