import React, { Component } from "react";
import Chart from "react-apexcharts";

class ApexChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        chart: {
          id: "basic-bar"
        },
        xaxis: {
          categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999]
        }
      },
      series: [
        {
          name: "series-1",
          data: [30, 40, 45, 50, 49, 60, 70, 91]
        }
      ],
      myOptions: {

        chart: {
            type: 'polarArea',
        },
        labels: ['visie', 
                 'verandering & vernieuwing', 
                 'daadkracht & beslissingen nemen', 
                 'betrokkenheid genereren', 
                 'verbinding stimuleren', 
                 'actief luisteren', 
                 'sociale veiligheid', 
                 'zichzelf ontwikkelen', 
                 'veerkracht',
                 'kwetsbaarheid/authenticiteit',
                 'integriteit',
                 'coachen, motiveren, ontwikkelen',
                 'talent management',
                 'duidelijk communiceren',
                 'constructief feedback geven'
            ],
        stroke: {
            colors: ['#fff']
        },
        fill: {
            opacity: 0.8,
            colors: ['#783C96', // Cluster I: visie, verandering & daadkracht: Visie: 6
                     '#783CB8', // Cluster I: visie, verandering & daadkracht: Verandering & Vernieuwing: 6
                     '#783CDA', // Cluster I: visie, verandering & daadkracht: Daadkracht & Beslissingen nemen: 7
                     '#D23278', // Cluster II: Samenwerking & connectie: Betrokkenheid genereren: 6
                     '#D2329A', // Cluster II: Samenwerking & connectie: Verbinding stimuleren: 8
                     '#D232BC', // Cluster II: Samenwerking & connectie: Actief luisteren; 6
                     '#D232DE', // Cluster II: Samenwerking & connectie: Sociale veiligheid: 12 
                     '#E6463C', // Cluster III: Zelfleiderschap & Authenticiteit: Zichzelf ontwikkelen : 10
                     '#E6465E', // Cluster III: Zelfleiderschap & Authenticiteit: Veerkracht: 3
                     '#E64680', // Cluster III: Zelfleiderschap & Authenticiteit: Kwetsbaarheid/authenticiteit : 5
                     '#E646A2', // Cluster III: Zelfleiderschap & Authenticiteit: Integriteit : 5
                     '#FABB22', // Cluster IV: Coachend leiderschap: Coachen, motiveren, ontwikkelen: 9
                     '#FABB44', // Cluster IV: Coachend leiderschap: Talent management : 9
                     '#E6E6E6', // Cluster V: Communicatie & Feedback: Duidelijk communiceren
                     '#E6E6FF', // Cluster V: Communicatie & Feedback: Constructief feedback geven
                     '#F44336', 
                     '#E91E63', 
                     '#9C27B0']
        },
        responsive: [{
            breakpoint: 480,
            options: {
            chart: {
                width: 200
            },
            legend: {
                position: 'bottom'
            }
            }
        }]
      },
      mySeries: [4.3, 4.1, 2.1, 3.0, 1.3, 4.3, 4.7, 4.93, 2.6, 3, 3, 3.1, 3.9, 4.1, 4.3],

      radarSeries: [{
        name: 'Series 1',
        data: [80, 50, 30, 40, 100, 20],
      }, {
        name: 'Series 2',
        data: [20, 30, 40, 80, 20, 80],
      }, {
        name: 'Series 3',
        data: [44, 76, 78, 13, 43, 10],
      }],
      radarOptions: {
        chart: {
          height: 350,
          type: 'radar',
          dropShadow: {
            enabled: true,
            blur: 1,
            left: 1,
            top: 1
          }
        },
        title: {
          text: 'Radar Chart - Multi Series'
        },
        stroke: {
          width: 2
        },
        fill: {
          opacity: 0.1
        },
        markers: {
          size: 0
        },
        xaxis: {
          categories: ['Visie, verandering, daadkracht', 
                       'Samenwerking & connectie', 
                       'Zelfleiderschap & Authenticiteit', 
                       'Coachend leiderschap', 
                       'Communicatie & Feedback', '2016']
        }
      },
    
    }; // state
  } // constructor

  render() {
    return (
      <div className="app">
        <div className="row">
            <Chart
              options={this.state.options}
              series={this.state.series}
              type="bar"
              width="500"
            />
            <Chart
                options={this.state.myOptions}
                series={this.state.mySeries}
                type="polarArea"
            />
              <Chart
                options={this.state.radarOptions}
                series={this.state.radarSeries}
                type="radar"
                height={450}
              />
          
        </div>
      </div>
    );
  }
}

export default ApexChart;