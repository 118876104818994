import {useEffect} from "react";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useLogin } from "../../hooks/useLogin";

const LinkMeInComponent = () => {

	const history = useHistory();
	const {loginLink} = useParams();
	const {linkMeIn, error, isPending} = useLogin();


	useEffect(() => {
		/** perform login and redirect */
        linkMeIn(loginLink).then(() => history.push("/userprofile"));
	},[]);

	return (
		<div>
		{isPending && <div>Logging in ...</div>}
		</div>
	);
}

export default LinkMeInComponent;