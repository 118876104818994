import { useContext } from "react";
import { SurveyContext } from "../context/SurveyContext";

export const useSurvey = () => {
    const context = useContext(SurveyContext);

    if (context === undefined){
        throw new Error("useSurveyContext() must be unsed inside a SurveyProvider");
    }

    return context;
}