import { useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./MsgEditor.css";


/*
 * Editor component with custom toolbar and content containers
 */

// when including this editor elsewhere ==> pass a function in the props to handle SAVE

const MsgEditor = (props) => {
    const [editorHtml, setEditorHtml] = useState("");


    const handleChange = html => {
        setEditorHtml(html);
    };
    const handleCommit = () => {
        console.log(editorHtml);
        /** perform post-processing */
        /**
         * replace <p><br></p> with <br/>
         * replace all " signs with \"
         */
        /**  test by sending a message with this content using the API ???  If this works, we can save it like that*/

        /** postprocessing to be done AFTER saving to the DB ?  that would make the processing easier for the online editor */
        /** as a consequence this should be done in the API sending code */

        /** paragraph styling can be done by adding an encapsulating div ?? */
        /** TODO also verify if the logo works */

        /** TODO LISTJE
         * DONE 1 aanpassen van de API code voor de nodige text-replacements (post-processing)
         * DONE 2 voor alle veiligheid de huidige berichten kopiëren naar de ongebruikte messages
         * DONE 3 editeerbare survey
         * DONE 4 aanpassen van de messages
         * DONE 5 zorgen dat ze worden opgeslagen in de DB
         * DONE 6 creëren van een userInterface voor het editeren van survey-elementen
         * 
         * TEST
         * Aanpassen van berichten ==> aanpassingen worden opgeslagen
         * post-processing ==> gebeurt dit netjes ? (styling van de divs ??)
         */
//        console.log(editorHtml.replaceAll("<p><br></p>","<br/>").replaceAll("<p>", "<p style=\"margin-top:0;margin-bottom:0px;\">").replaceAll("\"","\\\""));

    }

    const toolbarOptions = [
        ['bold', 'italic', 'underline'],        // toggled buttons
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        
        [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction
      
        
        [{ 'color': [] }],          // dropdown with defaults from theme
        [{ 'align': [] }],
        ['link'],
      
        ['clean']                                         // remove formatting button
      ];

    const modules = {
        toolbar: toolbarOptions,
         
    };

    const formats = [
            "header",
            "font",
            "size",
            "bold",
            "italic",
            "underline",
            "list",
            "bullet",
            "indent",
            "link",
            "color",
            "align",
    ];

return (
    <div className="text-editor">
        <ReactQuill
          value={editorHtml}
          onChange={handleChange}
          placeholder={props.placeholder}
          modules={modules}
          formats={formats}
          theme="snow"
        />
        <button onClick={handleCommit}>commit changes</button>
      </div>
    );
}
export default MsgEditor;