import { createContext, useReducer } from "react";

export const RespondentsContext = createContext(); // returns a new context objects


export const respondentsReducer = (state, action) => {
    switch (action.type){
        case 'CHANGE_SUBJECTID':
            return { ...state, subjectId: action.payload};
        case 'ADD_RESPONDENT':
            return {...state, respondents: [...state.respondents, action.payload]};
        case 'REMOVE_RESPONDENT':
            //return {...state, respondents: state.respondents.filter((resp)=> resp.key !== action.payload.key)};
            return {...state, respondents: state.respondents.filter((resp)=> resp.id !== action.payload.id)};
        case 'SET_RESPONDENTS':
            //return {...state, respondents: action.payload.sort((a,b) => a.relationship > b.relationship)};
            return {...state, respondents: action.payload};
        case 'UPDATE_RESPONDENT':
            let newArray = state.respondents.map((respondent) =>{
                if (respondent.id == action.payload.id) {return action.payload} 
                else {return respondent
            }});
            return {...state, respondents: newArray};
        default: 
            return state;

    }//switch


}

export function RespondentsProvider({children}){

    const [state, dispatch] = useReducer(respondentsReducer, {subjectId:null, respondents:[]});

    const changeSubjectId = (subjectId) => {
        dispatch({type: 'CHANGE_SUBJECTID', payload: subjectId}); //this is the action object (2nd argument of the reducer)
    }

    const addRespondent = (newRespondent) => {
        //console.log("respondentsProvider.addRespondent");
        dispatch({type: 'ADD_RESPONDENT', payload: newRespondent});
    }
    const removeRespondent = (respondent) => {
        //console.log("respondentsProvider.removeRespondent");
        dispatch({type: 'REMOVE_RESPONDENT', payload:respondent});
    }

    const setRespondents = (respondents) => {
        //console.log("respondentsPRovider.setRespondents");
        dispatch({type: 'SET_RESPONDENTS', payload: respondents});
    }

    const updateRespondent = (respondent) => {
        //console.log("respondentProvider.updateRespondent");
        dispatch({type: 'UPDATE_RESPONDENT', payload:respondent});
    }

    return(
        <RespondentsContext.Provider value={{...state, changeSubjectId, addRespondent, removeRespondent, setRespondents, updateRespondent}} >
            {children}
        </RespondentsContext.Provider>
    );
}

/** <SubjectContext.Provider value = {subject}> */