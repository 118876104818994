import "./App.css";

import { useState, useEffect } from "react";
//import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
//import Tooltip from "@mui/material";
//import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import { Button, Typography, Tooltip, Avatar } from "@mui/material";
import { MailLockOutlined, MarkEmailReadOutlined } from "@mui/icons-material";


import { Link } from "react-router-dom";

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';

import { useLogout } from "./hooks/useLogout";
//import { useAuthUser } from "./hooks/useAuthUser";
import { useAuthContext } from "./hooks/useAuthContext";

import { useGoogleLogin } from '@react-oauth/google';
import { useLogin } from "./hooks/useLogin";

import { useTranslation } from "react-i18next";

import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
/**
 * 
 * TODO: 
 * 1. corrigeren van de behaviour: close when clicked (bij hamburgermenu) 
 * 2. aanpassen kleuring van de links (bij hamburgermenu)
 * aanpassen van het menu op basis van de rol
 * 2. redirect op basis van de rol ?  (in app.js)
 * 3. 
 */


const MyAppBar = () => {
    //const {logout, newLogout} = useLogout();
    const {logout} = useLogout();
    const {user, googleToken} = useAuthContext();
    //const {getUser} = useAuthUser();

    const {doGoogleLogin} = useLogin();

    const {t, i18n} = useTranslation();

    //const history = useHistory();

    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);
    const [lng, setLng] = useState('nl');
  
    const handleOpenNavMenu = (event) => {setAnchorElNav(event.currentTarget);};
    const handleOpenUserMenu = (event) => {setAnchorElUser(event.currentTarget);};
    const handleCloseNavMenu = () => {setAnchorElNav(null);};
    const handleCloseUserMenu = () => {setAnchorElUser(null);
    };

    //TODO: pagina's beperken ifv de rollen
    const paginas = [{key: '/survey-list', text:t('survey management'), target:'/survey-list', roles:["ROLE_SUPERUSER","ROLE_ADMIN"]},
                     {key: '/userprofile', text:t('user profile'), target:'/userprofile', roles:["ROLE_ADMIN", "ROLE_SUPERUSER","ROLE_USER"]}]; 
    const [pages, setPages] = useState([]);
    const instellingen = [{key: '/userprofile', text:t('user profile'), target:'/userprofile', roles:["ROLE_ADMIN", "ROLE_SUPERUSER","ROLE_USER"]},
                          {key: '/logout', text:t('logout'), target:'/', roles:["ROLE_ADMIN", "ROLE_SUPERUSER","ROLE_USER"], onClick:logout},
                          {key: '/loginForm', text:t('login'), target:'/loginForm', roles:[]}
                         ];
    const languages = [
        {key: 'en', nativeName: 'English'},
        {key: 'nl', nativeName: 'Nederlands'},
        {key: 'fr', nativeName: 'Français'}
    ];
    
    const [settings, setSettings] = useState([]);

    const adjustPages = () => {
        let newPages = [];
        paginas.forEach((page) => {
            let isAllowed = false;
            page.roles.forEach((pageRole) => {
                if (user && user.roles.includes(pageRole)) {isAllowed = true;}
            });
            if (isAllowed) {newPages.push(page);}
        });
        setPages(newPages);
    }
    const adjustSettings = () => {
        let newSettings = [];
        instellingen.forEach((setting) => {
            let isAllowed = false;
            if (setting.roles.length>0){
                setting.roles.forEach((settingRole) => {
                    if (user && user.roles.includes(settingRole)) {
                        isAllowed = true;
                    }
                });   
            }// roles are defined
            else if (!user) {isAllowed = true;}
            if (isAllowed) {newSettings.push(setting);}
        });
        setSettings(newSettings);
    }

    const adjustLanguague = (langKey) => {
        i18n.changeLanguage(langKey);
        setLng(langKey);
    }

    //useEffect(() => {getUser(); },[]); //TODO getUser vervangen door user
    useEffect(() => {
        adjustSettings();
        adjustPages();
        //if (user) adjustPages(user.roles);
    },[user, lng]);

    /*useEffect(() => {
        console.log("MyAppBar - useEffect for language changed to "+ lng);
    }, [lng]);*/
  
    const myScopes = "https://www.googleapis.com/auth/gmail.send"; // https://www.googleapis.com/auth/userinfo.profile 
    const handleMailLogin = useGoogleLogin({onSuccess: tokenResponse => doGoogleLogin(tokenResponse), scope: myScopes }); //flow: 'auth-code', scope: myScopes
     
    return (
        <AppBar position="static">
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleOpenNavMenu}
                        color="inherit">
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                            >
                            {pages.map((pagina) => (
                                    <MenuItem key={pagina.key} onClick={handleCloseNavMenu}>
                                        <Typography textAlign="center"><Link to={pagina.target} className="navmenu-link">{pagina.text}</Link></Typography>
                                    </MenuItem>
                            ))} 
                        </Menu>
                    </Box>
                    <Container sx={{maxWidth: {xs: 0.9, md: 150},}}>
                        <Box 
                            component="img"
                            sx={{height: 60,width: 112, maxWidth: { xs: 112, md: 112 },}}
                            alt="DPG Media"
                            src="/dpg-media.png"
                            />
                    </Container>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {pages.map((pagina) => (
                            <Button
                            key={pagina.key}
                            onClick={handleCloseNavMenu}
                            sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                            <Link to={pagina.target} className="nav-link">{pagina.text}</Link>
                            </Button>
                        ))}
                    </Box>
                    <Box sx={{ flexGrow: 0 }}>
                        <table><tbody><tr>
                        {languages.map((language) => (
                            <td style={{paddingRight:'30px'}} key={"td_"+language.key}>
                                <a  className="languageLink" key={language.key} onClick={() => adjustLanguague(language.key)} href="#" disabled={i18n.resolvedLanguage === language.key}>{language.key}</a>
                            </td>
                           
                        ))}
                        </tr></tbody></table>
                        {/** <Button key={language.key} onClick={() => adjustLanguague(language.key)} variant="text" color="inherit" disabled={i18n.resolvedLanguage === language.key}>{language.key}</Button>
                            */}
                    </Box>

                    <Box sx={{ flexGrow: 0 }}>
                
                        {user && (user.roles.includes("ROLE_SUPERUSER")||user.roles.includes("ROLE_ADMIN")) && 
                            <>
                                {googleToken && (<IconButton color="success" aria-label="ready for email"><MarkEmailReadOutlined/></IconButton>)}
                                {!googleToken && (<IconButton color="error" aria-label="activate email" onClick={handleMailLogin} ><MailLockOutlined/></IconButton>)}
                            </>
                        }
                        {/** settings.map((setting) => 
                            <Link to={setting.target} className="nav-link" onClick={setting.onClick}>{setting.text}</Link>
                    )*/}
                      {/**  {user && <Link to="/userprofile" className="nav-link">{user.username}  </Link>}
                        {user && <Link to="/" className="nav-link" onClick={newLogout}>Afmelden</Link>}
                        {!user && <Link to="/loginForm" className="nav-link">Aanmelden</Link>} */}
                    
                    </Box>
                    <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title={t('Open settings')}>
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <AccountCircleOutlinedIcon color="white"/>
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                            >
                            {settings.map((setting) => (
                                <MenuItem key={setting.key} onClick={handleCloseUserMenu}>
                                    <Typography textAlign="center"><Link to={setting.target} className="navmenu-link" onClick={setting.onClick}>{setting.text}</Link></Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default MyAppBar;