import React, { useState } from "react";
import { Input, Button } from "antd";

import { PersonsContainer } from '../../unstated/persons';


const CreatePerson = () => {

	const formatDate = date => {
	    var d = new Date(date),
	        month = '' + (d.getMonth() + 1),
	        day = '' + d.getDate(),
	        year = d.getFullYear();
	    if (month.length < 2) month = '0' + month;
	    if (day.length < 2) day = '0' + day;
	    return [year, month, day].join('-');
	}

	const persons = PersonsContainer.useContainer();

	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [birthDate, setBirthDate] = useState(formatDate(new Date()));
	const [password, setPassword] = useState("");

	function onChangePersonFirstName(e){
		setFirstName(e.target.value);
	}
	
	function onChangePersonLastName(e) {
		setLastName(e.target.value);
	}

	function onChangePersonEmail(e){
		setEmail(e.target.value);
	}

	function onChangePersonBirthDate(e) {
		setBirthDate(e.target.value);
	}
	function onChangePersonPassword(e){
		setPassword(e.target.value);
	}


	function mySubmit() {
		console.log("saving ...");
		console.log(firstName + " "+ lastName);
		const personObject = {
			firstName: firstName,
			lastName: lastName,
			email: email,
			birthDate: birthDate,
			password: password,
		};
		persons.addPerson(personObject);
		setFirstName('');
		setLastName('tick');
		setEmail('');
		setBirthDate(formatDate(new Date()));
		setPassword('');
	}
  	
  
	return (
		<PersonsContainer.Provider>
			<div className="form-wrapper">
				<Input placeholder="voornaam" onChange={onChangePersonFirstName}/>
				<Input placeholder="naam" onChange={onChangePersonLastName}/>
				<Input placeholder="email" onChange={onChangePersonEmail}/>
				<Input placeholder="paswoord" onChange={onChangePersonPassword}/>
				
				<Button onClick={mySubmit}>Bewaren</Button>
			</div>
		</PersonsContainer.Provider>
	);
}

export default CreatePerson;


	// function onSubmit() {
	// 	return event => {
	// 		event.preventDefault()
	// 		const personObject = {
	// 		firstName: firstName,
	// 		lastName: lastName,
	// 		email: email,
	// 		birthDate: birthDate,
	// 		password: password,
	// 		};

	// 	persons.addPerson(personObject);
	// 	setFirstName('');
	// 	setLastName('tick');
	// 	setEmail('');
	// 	setBirthDate(formatDate(new Date()));
	// 	setPassword('');
	// 	}
	// }


	// <Form onSubmit={onSubmit()}>
	// <Form.Group controlId="FirstName">
	// <Form.Label>First Name</Form.Label>
	// <Form.Control type="text" value={firstName} onChange={onChangePersonFirstName}/>
	// </Form.Group>
	// <Form.Group controlId="LastName">
	// <Form.Label>Last Name</Form.Label>
	// <Form.Control type="text" value={lastName} onChange={onChangePersonLastName}/>
	// </Form.Group>

	// <Form.Group controlId="Email">
	// <Form.Label>Email</Form.Label>
	// <Form.Control type="email" value={email} onChange={onChangePersonEmail}/>
	// </Form.Group>

	// <Form.Group controlId="BirthDate">
	// <Form.Label>Birthdate</Form.Label>
	// <Form.Control type="date" value={birthDate} onChange={onChangePersonBirthDate}/>
	// </Form.Group>

	// <Button variant="danger" size="lg" block="block" type="submit">
	// Create Person
	// </Button>
	// </Form>


// import React, { Component } from "react";
// import Form from 'react-bootstrap/Form';
// import Button from 'react-bootstrap/Button';
// //import {Subscribe} from 'unstated-next';
// import { PersonsContainer } from '../../unstated/persons';


// //import axios from 'axios';

// class CreatePerson extends Component {


// 	constructor(props) {
// 		super(props)

// 		// Setting up functions
// 		this.onChangePersonFirstName = this.onChangePersonFirstName.bind(this);
// 		this.onChangePersonLastName = this.onChangePersonLastName.bind(this);
// 		this.onChangePersonEmail = this.onChangePersonEmail.bind(this);
// 		this.onChangePersonBirthDate = this.onChangePersonBirthDate.bind(this);
// 		this.onSubmit = this.onSubmit.bind(this);

// 		// Setting up state
// 		this.state = {
// 			firstName: '',
// 			lastName: '',
// 			email: '',
// 			birthDate: null
// 		}
// 	}

// 	onChangePersonFirstName(e) {
// 		this.setState({firstName: e.target.value})
// 	}
	
// 	onChangePersonLastName(e) {
// 		this.setState({lastName: e.target.value})
// 	}

// 	onChangePersonEmail(e) {
// 		this.setState({email: e.target.value})
// 	}

// 	onChangePersonBirthDate(e) {
// 		this.setState({birthDate: e.target.value})
// 	}

// 	onSubmit(personsContainer) {
// 		return event => {
// 			event.preventDefault()
// 			const personObject = {
// 			firstName: this.state.firstName,
// 			lastName: this.state.lastName,
// 			email: this.state.email,
// 			birthDate: this.state.birthDate
// 			};
// 		personsContainer.addPerson(personObject)
// 		this.setState({firstName: '', lastName: '', email: '', birthDate: null})
// 		}
//   	}
	
// 	formatDate = date => {
// 	    var d = new Date(date),
// 	        month = '' + (d.getMonth() + 1),
// 	        day = '' + d.getDate(),
// 	        year = d.getFullYear();

// 	    if (month.length < 2) 
// 	        month = '0' + month;
// 	    if (day.length < 2) 
// 	        day = '0' + day;

// 	    return [year, month, day].join('-');
// 	}

  
// 	render() {
// 		return (
// 			<Subscribe to={[PersonsContainer]}>
// 				{personsContainer =>
// 					<div className="form-wrapper">
// 						<Form onSubmit={this.onSubmit(personsContainer)}>
// 							<Form.Group controlId="FirstName">
// 							<Form.Label>First Name</Form.Label>
// 							<Form.Control type="text" value={this.state.firstName} onChange={this.onChangePersonFirstName}/>
// 							</Form.Group>
// 							<Form.Group controlId="LastName">
// 							<Form.Label>Last Name</Form.Label>
// 							<Form.Control type="text" value={this.state.lastName} onChange={this.onChangePersonLastName}/>
// 							</Form.Group>
			
// 							<Form.Group controlId="Email">
// 							<Form.Label>Email</Form.Label>
// 							<Form.Control type="email" value={this.state.email} onChange={this.onChangePersonEmail}/>
// 							</Form.Group>
			
// 							<Form.Group controlId="BirthDate">
// 							<Form.Label>Birthdate</Form.Label>
// 							<Form.Control type="date" value={this.formatDate(this.state.birthDate)} onChange={this.onChangePersonBirthDate}/>
// 							</Form.Group>
			
// 							<Button variant="danger" size="lg" block="block" type="submit">
// 							Create Person
// 							</Button>
// 						</Form>
// 					</div>
// 				}
// 			</Subscribe>
// 		);
//   	}
// }

// export default CreatePerson;