
import { Clear, ExitToApp, Save } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import "./RespondentForm.css";
import { isNullOrUndef } from "chart.js/helpers";

export default function EditSurveyForm({addSurvey, handleClose, updateSurveyName, surveyName}){

    const [name, setName] = useState('');
    
    const resetForm = () => {
        setName('');
    }

    useEffect(() => {
        setName(surveyName);
    },[]);
    useEffect(() => {
        setName(surveyName);
    }, [surveyName]);

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!isNullOrUndef(addSurvey)){
            console.log("adding new survey");
            const simplifiedSurvey = {
                id:null,
                name: name,
            }
            addSurvey(simplifiedSurvey);
    
        } else if (!isNullOrUndef(updateSurveyName)){
            console.log("updating survey");
            updateSurveyName(name);
        }
        resetForm();
        handleClose();
    }

    return (
        <form className="edit-respondent-form" onSubmit={handleSubmit}>
            <label>
                <span>naam: </span>
                {/**{!isNullOrUndef(name) && <input type="text" id="name" onChange={(e) => setName(e.target.value)} value={name} />}*/}
                <input type="text" id="name" onChange={(e) => setName(e.target.value)} value={name} />
            </label>
            <div className="bottomButtons">
                <div>
                    <Button
                        variant="outlined"
                        onClick={handleSubmit} 
                        startIcon={<Save />} >Bewaren
                    </Button>
                </div>
                <div>
                    <Button 
                        variant="outlined"
                        onClick={resetForm} 
                        startIcon={<Clear />} >Wissen
                    </Button>
                </div> 
                <div>
                    <Button 
                        variant="outlined"
                        onClick={handleClose} 
                        startIcon={<ExitToApp />} >Sluiten
                    </Button>
                </div> 
            </div>
            <br/>
        </form>
    );
}