import { useEffect, useState } from "react"
import { useAuthContext } from "./useAuthContext"
import axios from "axios";
import jwtDecode from "jwt-decode";
import configData from '../config/config.json';

export const useLogin =  () => {
    const [isCancelled, setIsCancelled] = useState(false);
    const [error, setError] = useState(null);
    const [isPending, setIsPending] = useState(false);
    const {dispatch} = useAuthContext();

    const login = async (username, password) => {
        console.log("entering login in hook");
        setError(null);
        setIsPending(true);

        //sign the user in
        try{
            /** contains 2 actions: 
                1. perform login for API service && perform login in local application state
                2. update the state in the react app (using the dispatch function)
            */
           let user = null;
           const response = await axios.post(configData.SERVER_URL+"/api/auth/"+"signin",{'usernameOrEmail': username, 'password': password});
            console.log(response.data);
           if (response.data.accessToken){
                const decoded = await jwtDecode(response.data.accessToken);
                const userRoles = await decoded.userRoles.split(',');

                const res = await axios.get(configData.SERVER_URL+'/api/persons/'+decoded.personId,{ headers: { 'Authorization': 'Bearer ' + response.data.accessToken} });
                const person = res.data;

                user = {firstName: decoded.firstName, 
                        username: decoded.sub, 
                        roles: userRoles, 
                        token: response.data.accessToken,
                        person: person
                       };
           }

            /*const res = await authService.login(username, password);*/
                        
            // dispatch login action ==> update state
            dispatch({ type: 'LOGIN' , payload: user});

            //update state
            if (!isCancelled){
                setIsPending(false);
                setError(null);    
            }

        } catch (err){
            if (!isCancelled){
                setError(err.message);
                setIsPending(false);    
            }
        }
    }//newLogin function

    const doGoogleLogin = async(googleResponse) => {
        setError(null);
        setIsPending(true);
        try{
            console.log(googleResponse);
            dispatch({ type: 'SET_GOOGLE_TOKEN', payload: googleResponse.access_token});
            if (!isCancelled){
                setError(null);
                setIsPending(false);
            }
        }//try
        catch (err){
            if (!isCancelled){
                setError(err.message);
                setIsPending(false);
            }
        }
    }//doGoogleLogin

    const linkMeIn = async(loginLink) => {

         //console.log("entering login in hook");
         setError(null);
         setIsPending(true);
 
         //sign the user in
         try{
             /** contains 2 actions: 
                 1. perform login for API service && perform login in local application state
                 2. update the state in the react app (using the dispatch function)
             */
            let user = null;
            const response = await axios.post(configData.SERVER_URL+"/api/auth/linkMeIn/"+loginLink);
            if (response.data.accessToken){
                 const decoded = await jwtDecode(response.data.accessToken);
                 const userRoles = await decoded.userRoles.split(',');
 
                 const res = await axios.get(configData.SERVER_URL+'/api/persons/'+decoded.personId,{ headers: { 'Authorization': 'Bearer ' + response.data.accessToken} });
                 const person = res.data;
 
                 user = {firstName: decoded.firstName, 
                         username: decoded.sub, 
                         roles: userRoles, 
                         token: response.data.accessToken,
                         person: person
                        };
                 console.log(user);
            }
 
             dispatch({ type: 'LOGIN' , payload: user});
            
             //update state
             if (!isCancelled){
                 setIsPending(false);
                 setError(null);    
             }
             return user; // added 27/05/23 for apiTest
 
         } catch (err){
             if (!isCancelled){
                 setError(err.message);
                 setIsPending(false);    
             }
         }
    }// linkMeIn function

    const loginAsRespondent = async (respondentId) => {
        
        setError(null);
        setIsPending(true);

        try{
            let user = null;
            const rsp = await axios.get(configData.SERVER_URL+"/api/auth/"+"linkin/"+respondentId);
            if (rsp.data.accessToken){
                const decoded = await jwtDecode(rsp.data.accessToken);
                const userRoles = await decoded.userRoles.split(',');
                user = {firstName: decoded.firstName, 
                    username: decoded.sub, 
                    roles: userRoles, 
                    token: rsp.data.accessToken
                   };
            }
            dispatch({type:'LOGIN', payload:user});
            if (!isCancelled){
                setIsPending(false);
                setError(null);
            }
            return user;
        }//try
        catch (err){
            if (!isCancelled){
                setError(err.message);
                setIsPending(false);
            }
        } // catch
    } //loginAsRespondent function

    useEffect(()=> { // is fired when unmounting, preventing state to be updated after unmounting (generates an error)
        return () => setIsCancelled(true);
    }, []);

    return {login, doGoogleLogin, linkMeIn, loginAsRespondent, error, isPending} ;
}