import { useContext } from "react";
import { RespondentsContext } from "../context/RespondentsContext";

export const useRespondents = () => {
    const context = useContext(RespondentsContext);

    if (context === undefined){
        throw new Error("useRespondents() must be unsed inside a RespondentsProvider");
    }

    return context;
}