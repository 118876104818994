import { useEffect, useState } from "react";
import { useSurveySubjects } from "./useSurveySubjects";
import axios from 'axios';
import configData from '../config/config.json';
//import { useAuthUser } from "./useAuthUser";
import { useAuthContext } from "./useAuthContext";

export const useManageSurveySubjects =  () => {

    //const {getUser} = useAuthUser();
    const { user }= useAuthContext();
    const [isCancelled, setIsCancelled] = useState(false);
    const [error, setError] = useState(null);
    const [isPending, setIsPending] = useState(false);
    const {addSurveySubject, removeSurveySubject, setSurveySubjects} = useSurveySubjects(); // AVAILABLE: changeSurveyId, addSurveySubject, removeSurveySubject, setSurveySubjects
    

    const sortSurveySubjectsByStatusAndLastName = (subjects) => {
        return subjects.sort((a,b) => {
            const xS = "x";//a.status.toLowerCase();
            const yS = "x";//b.status.toLowerCase();
            const xL = a.subjectPerson.lastName.toLowerCase();
            const yL = b.subjectPerson.lastName.toLowerCase();

            return ((xS < yS) ? -1 : ((xS > yS) ? 1 : ((xL < yL) ? -1 : ((xL > yL) ? 1 : 0))));
        });
    }

    const setSurveySubjectsForSurveyId = async (surveyId) => {
        setError(null);
        setIsPending(true);

        try{
            // fetch the subjects from the db
            //console.log("useManageSurveySubjects - setSurveySubjectsForSurveyId - fetching surveysubjects for a survey");
            /*const checkedUser = await getUser();
            const res = await axios.get(configData.SERVER_URL+'/api/surveysubjects/survey/'+surveyId, {headers: { 'Authorization': 'Bearer ' + checkedUser.token}});*/
            //console.log("user token = "+user.token);
            const res = await axios.get(configData.SERVER_URL+'/api/surveysubjects/survey/'+surveyId, {headers: { 'Authorization': 'Bearer ' + user.token}});
            //console.log(res);
            const sortedSubjects = await sortSurveySubjectsByStatusAndLastName(await res.data);

            // adjust the state
            /** 2024-05-10, aanpassing naar 'normale' surveySubjects */
            /**setSurveySubjects(await sortedSubjects.map((dbSurveySubject) => ({
                key: dbSurveySubject.id,
                firstName: dbSurveySubject.subjectPerson.firstName,
                lastName: dbSurveySubject.subjectPerson.lastName,
                email: dbSurveySubject.subjectPerson.email,
                respondentCount: dbSurveySubject.respondents.length,
                isSelected:false
            }))//map
            );//setSurveySubjects*/
            setSurveySubjects(await sortedSubjects.map((dbSurveySubject) => ({
                id: dbSurveySubject.id,
                launchDate: dbSurveySubject.launchDate,
                subjectPerson: structuredClone(dbSurveySubject.subjectPerson),
                respondents: [...dbSurveySubject.respondents],
                isSelected:false
            }))//map
            );//setSurveySubjects
        
            if (!isCancelled){
                setIsPending(false);
                setError(null);    
            }// if !isCancelled

        }//try
        catch (err){
            if (!isCancelled){
                console.log(err.message);
                setError(err.message);
                setIsPending(false);    
            } // if !isCancelled
        } // catch errors   
    } // setSurveySubjectsForSurveyId

    const add = async (firstName, lastName, email, surveyId) => {

        setError(null);
        setIsPending(true);

        //sign the user in
        try{
            /**
             * contains 2 actions: 
             * 1.add the surveySubject to the DB (via the API)
             * 2. add the surveySubject to the application state*/
        
            // DB: create the person
            // TODO verify the existence of the person !!
            email = email.replace('dpgmedia.be','persgroep.net');
            email = email.replace('dpgmedia.nl','persgroep.net');

            const newPerson = {
                    id: null,
                    firstName: firstName,
                    lastName: lastName,
                    birthDate: new Date(),
                    email: email
                }//newPerson
/*            const checkedUser = await getUser();
            const res=await axios.post(configData.SERVER_URL+'/api/persons/', newPerson, { headers: { 'Authorization': 'Bearer ' + checkedUser.token} }) // http://localhost:8080/api/v1/persons/ */
            const res=await axios.post(configData.SERVER_URL+'/api/persons/', newPerson, { headers: { 'Authorization': 'Bearer ' + user.token} });
            // DB: create the surveySubject
            const createdPerson = res.data;
            //console.log("created person returned from db, should contain an id");
            //console.log(createdPerson);

            const minimalSubject = {
                surveyId: surveyId,
                subjectPersonId: createdPerson.id,
                launchDate: new Date()
            }
            //console.log("minimal surveySubject, should contain person id");
            //console.log(minimalSubject);
            
            /*
            const result =  await axios.post(configData.SERVER_URL + '/api/surveysubjects/simplified',minimalSubject,{ headers: { 'Authorization': 'Bearer ' + checkedUser.token} });*/
            const result =  await axios.post(configData.SERVER_URL + '/api/surveysubjects/simplified',minimalSubject,{ headers: { 'Authorization': 'Bearer ' + user.token} });
            const dbSurveySubject = result.data;
 
            
            const screenSurveySubject = {...dbSurveySubject, isSelected:false}
            /** 2024-05-10 
                                     {key: dbSurveySubject.id,
                                      firstName: dbSurveySubject.subjectPerson.firstName,
                                      lastName: dbSurveySubject.subjectPerson.lastName,
                                      email: dbSurveySubject.subjectPerson.email,
                                      respondentCount: dbSurveySubject.respondents.length,
                                      isSelected:false
                                    }
*/
            // change application state
            //console.log("ready to dispatch in useManageSurveySubjects");
            //dispatch is not exposed ==> cannot be used directly
            addSurveySubject(screenSurveySubject);
            //console.log("finished dispatching");
            //update state
            if (!isCancelled){
                setIsPending(false);
                setError(null);    
            }

        } catch (err){
            if (!isCancelled){
                console.log(err.message);
                setError(err.message);
                setIsPending(false);    
            }
        }
    }//add function

    const remove = async(screenSurveySubject) => {
        //console.log("entering remove in manageSurveySubjectHook");
        setError(null);
        setIsPending(true);

        try{
            /**
             * contains 2 actions: 
             * 1.remove the surveySubject from the DB (via the API)
             * 2.remove the surveySubject from the application state*/
        
            // DB: remove the surveySubject
            // TODO keep the persons db clean
            const getFullSurveySubjectFromDb = async (screenSurveySubject) => {
                /*const checkedUser = await getUser();
                const rsp = await axios.get(configData.SERVER_URL+'/api/surveysubjects/'+screenSurveySubject.key, {headers: { 'Authorization': 'Bearer ' + checkedUser.token}});*/
                const rsp = await axios.get(configData.SERVER_URL+'/api/surveysubjects/'+screenSurveySubject.key, {headers: { 'Authorization': 'Bearer ' + user.token}});
                //console.log("full surveySubject (rsp.data):");
                //console.log(rsp.data);
                return rsp.data;
            }

            const removeSurveySubjectFromDb = async (fullSurveySubject) => {
                /*const checkedUser = await getUser();
                const rsp = await axios.delete(configData.SERVER_URL+'/api/surveysubjects/'+fullSurveySubject.id, {headers: { 'Authorization': 'Bearer ' + checkedUser.token}});*/
                const rsp = await axios.delete(configData.SERVER_URL+'/api/surveysubjects/'+fullSurveySubject.id, {headers: { 'Authorization': 'Bearer ' + user.token}});
                //console.log("surveySubject removed result (rsp.data):");
                //console.log(rsp.data);
                return (rsp.data);
            }


            const removePersonFromDb = async (pId, feedback) => {
                //console.log("removing person from db (works only if the person is not a respondent or subject elsewhere");
                //console.log("with feedback removed surveySubject:");
                //console.log(feedback);

                /*
                const checkedUser = await getUser();
                const persResult = await axios.delete(configData.SERVER_URL+'/api/persons/'+pId, {headers: { 'Authorization': 'Bearer ' + checkedUser.token}});*/
                const persResult = await axios.delete(configData.SERVER_URL+'/api/persons/'+pId, {headers: { 'Authorization': 'Bearer ' + user.token}});
                //console.log("result:");
                //console.log(persResult);
                return persResult;
            }

            //console.log("first fetch full surveySubject (to get Person)");
            const fullSurveySubject = await getFullSurveySubjectFromDb(screenSurveySubject);
            //console.log(fullSurveySubject);
            
            //console.log("removing surveySubject from database");
            const feedbackRemovedSurveySubject = await removeSurveySubjectFromDb(fullSurveySubject);
            //console.log(feedbackRemovedSurveySubject);

            //console.log("removing person from database");
            const removedPersonFeedback = await removePersonFromDb(fullSurveySubject.subjectPerson.id, feedbackRemovedSurveySubject);
            //console.log("remove person completed");
            //console.log(removedPersonFeedback);
            
            //TODO: show the error on screen in the component


            // change application state (using exposed methods from reducer)
            removeSurveySubject(screenSurveySubject);
            //update state

            // remove person from db
            

            if (!isCancelled){
                setIsPending(false);
                setError(null);    
            }

        } catch (err){
            if (!isCancelled){
                console.log(err.message);
                setError(err.message);
                setIsPending(false);    
            }
        }
    }//remove function

    const getSurveySubjectFromDb = async(surveySubjectId) => {
        /*const checkedUser = await getUser();
        const rsp = await axios.get(configData.SERVER_URL+'/api/surveysubjects/'+surveySubjectId, {headers:{ 'Authorization': 'Bearer ' + checkedUser.token}});*/
        const rsp = await axios.get(configData.SERVER_URL+'/api/surveysubjects/'+surveySubjectId, {headers:{ 'Authorization': 'Bearer ' + user.token}});
        return rsp.data;
    }
    const getSurveySubjectForReportFromDb = async(surveySubjectId) => {
        /*
        const checkedUser = await getUser();
        const rsp = await axios.get(configData.SERVER_URL+'/api/surveysubjects/'+surveySubjectId+'/report', {headers:{ 'Authorization': 'Bearer ' + checkedUser.token}});*/
        const rsp = await axios.get(configData.SERVER_URL+'/api/surveysubjects/'+surveySubjectId+'/report', {headers:{ 'Authorization': 'Bearer ' + user.token}});
        return rsp.data;
    }
    const getSurveySubjectsForPerson = async(personId) => {
        console.log("useManageSurveySubjects - getSurveySubjectsForPerson - getting survey subject for person "+personId);
        /*const checkedUser = await getUser();
        const result = await axios.get(configData.SERVER_URL+'/api/surveysubjects/forPerson/'+personId, {headers:{ 'Authorization': 'Bearer ' + checkedUser.token}});*/
        const result = await axios.get(configData.SERVER_URL+'/api/surveysubjects/forPerson/'+personId, {headers:{ 'Authorization': 'Bearer ' + user.token}});
        console.log(result.data);
        return result.data;
    }

    useEffect(()=> { // is fired when unmounting, preventing state to be updated after unmounting (generates an error)
        return () => setIsCancelled(true);
    }, []);

    return {setSurveySubjectsForSurveyId, add, remove, getSurveySubjectFromDb, getSurveySubjectForReportFromDb, getSurveySubjectsForPerson, error, isPending} ;
}