import "./subject-component.css";
import "./Modal.css";
import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import RespondentDetails from "./RespondentDetails";

import RespondentForm from "./RespondentForm";
//import { useRespondents } from "../../hooks/useRespondents";
import { useManageRespondent } from "../../hooks/useManageRespondent";
import { Button, IconButton } from "@mui/material";
import { Add, AlarmAddOutlined, Delete, Done, Edit, Email } from "@mui/icons-material";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useManageSurveySubject } from "../../hooks/useManageSurveySubject";
import { useSurveySubject } from "../../hooks/useSurveySubject";

const SubjectComponent = () => {

    const {setSurveySubjectById, addRespondent, deleteRespondent, updateRespondent, error, isPending} = useManageSurveySubject();
    const {surveySubject} = useSurveySubject();

    const {invite, remind, complete} = useManageRespondent(); ////////////////// ==> will this adjust the state (visibly) ? vb. when invited / when reminded ??
    const {googleToken} = useAuthContext();

    const {subjectIdParam} = useParams();

    const [isReady, setIsReady] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const activeRespondent = useRef(null);

    useEffect(() => {
        console.log("subject-component useEffect[]");
        setIsReady(false);
        setSurveySubjectById(subjectIdParam).then(()=> setIsReady(true));
    }, []); //useEffect

    useEffect(() => {
        console.log("subject-component useEffect[surveySubject]");
        console.log(surveySubject);
    },[surveySubject])

    const handleCloseModal = () => {
        setShowModal(false);
    }
//TODO ==> will this work ??? where is the respondentPerson ??
    const addRespondentInComponent = (_respondent) => {
        addRespondent(_respondent);
        setShowModal(false);
    }

    const adjustRespondent = (_respondent) => {
        activeRespondent.current = _respondent;
        setShowModal(true);
    }

    const adjustRespondentInComponent = (_respondent) => {
        //console.log("make sure it gets updated in db and state using the right tools from useManageRespondent");
        updateRespondent(_respondent);
        //console.log(respondent);
        setShowModal(false);
    }

    function readableError(error){
        if (error === 'Request failed with status code 401'){
            return "Er liep iets mis met je toegangsrechten.  Dit kan te wijten zijn aan een vervallen beveiligingscertificaat.  Log opnieuw in indien nodig en/of ververs de pagina";
        }
        else return error;
    }
  
    const handleRemind = (respondent) => {
        /** verifieer eerst de mailgoedkeuring ?
         * alternatief ==> uitgrijzen van de mogelijkheid tot "activeer mailfuncties" is gekozen =>
         * button rechtsboven ==> toggle kleur van mailicoontjes via de authContext ??
         */
        remind(respondent);
    }
    
    return (
        
            <div>
                {!isReady && (<div>Loading...</div>)}
                {/**{isReady && respondents && (     */}
                {isReady && surveySubject && (
                    <>
                        {!showModal && (
                            <div className="rightButton" >
                                
                                <Button 
                                    variant="outlined"
                                    onClick={() => setShowModal(true)} 
                                    startIcon={<Add />} >Respondent toevoegen
                                </Button>
                                <br/> 
                            </div>
                        )}
                        <table className='personsTable'>
                            <thead>
                                <tr>
                                    <th>Voornaam</th>
                                    <th>Naam</th>
                                    <th>e-mail</th>
                                    <th>relatie</th>
                                    <th>status</th>
                                    <th>aanpassen</th>
                                    <th># antwoorden</th>
                                    <th>uitnodiging</th>
                                    <th>herinnering</th>
                                    <th>afronden</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
{/**                                 {respondents.map(respondent => (    */}
                                {surveySubject.respondents.map(respondent => (

                                    <tr key={respondent.id}>
                                        <td>{respondent.respondentPerson.firstName}</td>
                                        <td>{respondent.respondentPerson.lastName}</td>
                                        <td>{respondent.respondentPerson.email}</td>
                                        <td>{respondent.relationship}</td>
                                        <td><IconButton color="secondary" aria-label="aanpassen" onClick={() => adjustRespondent(respondent)}><Edit/></IconButton></td>
                                        <td>{respondent.status} </td>
                                        <td>{respondent.responses!==null && respondent.responses.length}</td>
                                        <td style={{textAlign:'right',}}>{respondent.responses.length} </td>
                                        <td>
                                            {(respondent.status == 'draft') && googleToken &&
                                                <IconButton color="secondary" aria-label="verstuur uitnodiging" onClick={(e) => invite(respondent, googleToken)}>
                                                    <Email/>
                                                </IconButton>
                                             }
                                         </td>
                                        <td>{/** TODO kleur reminder-knop ifv laatste verzending */}
                                            {(respondent.status !== 'draft' && respondent.status!=='completed') &&
                                                <IconButton color="secondary" aria-label="verstuur reminder" onClick={(e) => remind(respondent, googleToken)}>
                                                    <AlarmAddOutlined/>
                                                </IconButton>
                                            }
                                        </td>
                                        <td>
                                            {(respondent.status !== 'draft' && respondent.status !== 'completed') &&
                                                <IconButton color="secondary" aria-label="afronden" onClick={(e) => complete(respondent)}>
                                                    <Done/>
                                                </IconButton>
                                             }
                                        </td>
                                        <td>{/** TODO bevestiging vragen & andere statussen toelaten ?? */}
                                            {(respondent.status == 'draft') &&

                                                <IconButton color="primary" aria-label="verwijderen" onClick={(e) => deleteRespondent(respondent)}>
                                                    {/**<IconButton color="primary" aria-label="verwijderen" onClick={(e) => remove(respondent)}>*/}
                                                    <Delete/>
                                                </IconButton>
                                            }
                                        </td>
                                    </tr>
                            ))}
                            </tbody>
                        </table>
                        
                    </>
                )}
                {showModal && <RespondentDetails handleClose={handleCloseModal}>
                    <RespondentForm addRespondent={addRespondentInComponent} handleClose={handleCloseModal} updateRespondent={adjustRespondentInComponent} editableRespondent={activeRespondent.current}/>
                </RespondentDetails>}
                {!showModal && (
                    <div className="rightButton" >
                        <br/>
                        <Button variant="outlined" onClick={() => setShowModal(true)} startIcon={<Add />} >Respondent toevoegen</Button> 
                    </div>
                )}
                {error && <p>{readableError(error)}</p> }
            </div>
        
	);
}

export default SubjectComponent;