import { useState, useEffect, useRef } from "react";
import {Button, Card, Grid, TextField} from "@mui/material";
import {AddCircleOutline, Save, Clear, ExitToApp} from "@mui/icons-material";
//import { useManageSurvey } from "../../hooks/useManageSurvey";
import SurveyClusterComponent from "./survey-cluster-component";
import { isNullOrUndef } from "chart.js/helpers";
import uuidv4 from "../../utilities/basicUtilities";

const SurveyClusters = ({survey, changeConfigFile, handleClose}) => {
  /*const {setSurveyById, changeConfigFile} = useManageSurvey();*/
  const [configFile, setConfigFile] = useState(null);
  const [newClusterText, setNewClusterText] = useState("");
  const changesOccurred = useRef(false);
  const firstRun = useRef(true);
  

  useEffect(() => {
    if (survey) {
      setConfigFile(JSON.parse(survey.configFile));
    }
  }, [survey]);

  useEffect(() => {
    if (firstRun.current===true) firstRun.current = false;
    else changesOccurred.current = true;
  }, [configFile]);

  const handleUpdateCluster = (id, newValue, parentId) => {
    if (parentId === null){
        let _myConfigFile = structuredClone(configFile);
        let locator = _myConfigFile.clusters.length;
        _myConfigFile.clusters.forEach((cluster,index) => {
            if (cluster.id === id){ locator=index;}
        });
        if (locator < _myConfigFile.clusters.length){
          
            const movingPart = _myConfigFile.clusters.splice(locator,1)[0];
            const newCluster = {id:movingPart.id, naam:newValue, questions:(movingPart.questions ?[...movingPart.questions]:[]), subClusters:(movingPart.subClusters ? [...movingPart.subClusters]:[])};
            _myConfigFile.clusters.splice(locator,0,newCluster);
            setConfigFile(_myConfigFile);
        }
    }
  }
  
  const handleAddCluster = () => {
    let _myConfigFile = structuredClone(configFile);
    console.log(configFile);
    _myConfigFile.clusters.push({ id: uuidv4(), naam: newClusterText, subClusters:[{id: uuidv4(), naam: newClusterText}], questions:[]})
    setConfigFile(_myConfigFile);
    setNewClusterText("");
  };

  const handleChangeNewCluster = (newValue) => {
    setNewClusterText(newValue);
  }

  const handleMoveUp = (id, parentId) => {
    if (parentId === null){
        let _myConfigFile = structuredClone(configFile);
    
        let locator = _myConfigFile.clusters.length;
        _myConfigFile.clusters.forEach((cluster,index) => {
            if (cluster.id === id){ locator=index;}
        });
        if (locator !== 0){
            const movingPart = _myConfigFile.clusters.splice(locator,1)[0];
            _myConfigFile.clusters.splice(locator-1,0,movingPart);
            setConfigFile(_myConfigFile);
        }
    }
  }
  const handleMoveDown = (id, parentId) => {
    console.log("moving down a top-level cluster");
    if (parentId === null){
      let _myConfigFile = structuredClone(configFile);
  
      let locator = _myConfigFile.clusters.length;
      _myConfigFile.clusters.forEach((cluster,index) => {
          if (cluster.id === id){ locator=index;}
      });
      if (locator < _myConfigFile.clusters.length-1){
          const movingPart = _myConfigFile.clusters.splice(locator,1)[0];
          _myConfigFile.clusters.splice(locator+1,0,movingPart);
          setConfigFile(_myConfigFile);
          console.log(_myConfigFile.clusters);
        }
    }
  }

  const handleRemoveCluster = (id, parentId) => {
    if (parentId === null){
      let _myConfigFile = structuredClone(configFile);
  
      let locator = _myConfigFile.clusters.length;
      _myConfigFile.clusters.forEach((cluster,index) => {
          if (cluster.id === id){ locator=index;}
      });
      if (locator < _myConfigFile.clusters.length){
          const movingPart = _myConfigFile.clusters.splice(locator,1)[0];
          setConfigFile(_myConfigFile);
        }
    }
  }

  const changeCluster = (adjustedCluster) => {
    let _myConfigFile = structuredClone(configFile);
  
    let locator = _myConfigFile.clusters.length;
    _myConfigFile.clusters.forEach((cluster,index) => {
        if (cluster.id === adjustedCluster.id){ locator=index;}
    });
    if (locator < _myConfigFile.clusters.length){
        const movingPart = _myConfigFile.clusters.splice(locator,1)[0];
        _myConfigFile.clusters.splice(locator,0,adjustedCluster);
        setConfigFile(_myConfigFile);
      }
  }

  const handleClosePage = () => {
    handleClose();
  };
  const handleSubmit = () => {
    changeConfigFile(survey.id, JSON.stringify(configFile));
    handleClose();
  };

  /**function uuidv4() {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c) =>
      (
        +c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))
      ).toString(16)
    );
  }*/

  return (
    <div style={{ width: "80%" }}>
      {!isNullOrUndef(configFile) && !isNullOrUndef(configFile.clusters) &&
        configFile.clusters.map((cluster, clusterIndex) => (
          <Card variant="outlined" style={{ margin: "15px", padding: "15px" }}>
            <SurveyClusterComponent cluster={cluster} changeCluster={changeCluster} index={clusterIndex} updateCluster={handleUpdateCluster} moveUp={handleMoveUp} moveDown={handleMoveDown} remove={handleRemoveCluster} isLast={(clusterIndex===(configFile.clusters.length-1))}/>
          </Card>
        ))}
      <Grid container spacing={2}>
        <Grid item xs={9} style={{padding: "15px", display: "flex", justifyContent: "center", alignItems: "center",}}>
          <TextField fullWidth size="small" label="nieuwe cluster" value={newClusterText} onChange={(e) => handleChangeNewCluster(e.target.value)} />
        </Grid>
        <Grid item xs={3}>
          <Button variant="outlined" color="secondary" onClick={() => handleAddCluster()} startIcon={<AddCircleOutline />}>cluster toevoegen</Button>
        </Grid>
      </Grid>
      <div className="bottomButtons">
              {(changesOccurred.current===true) && <Button variant="outlined" onClick={handleSubmit} startIcon={<Save/>}>Bewaren</Button>}
              <Button variant="outlined" onClick={handleClosePage} startIcon={<ExitToApp />} style={{marginLeft:"15px"}}>Sluiten</Button>
      </div>
    </div>
  );
};
export default SurveyClusters;