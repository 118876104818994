import React, { Component } from "react";
import { useFetch } from "../../useFetch";

import PersonDetails from "./person-details";

//import axios from 'axios';
import Table from 'react-bootstrap/Table';
//import Button from 'react-bootstrap/Button';
import {Link, Route } from 'react-router-dom';

import configData from '../../config/config.json';

//import PersonTableRow from './PersonTableRow';

//import {Subscribe} from 'unstated';
//import PersonsContainer from '../../unstated/persons';
//import EditPerson from "./edit-person.component";

function PersonMasterDetailList (){

	const { data: persons, isPending, error } = useFetch(configData.SERVER_URL+'/api/persons/');
	return (
	<div>
		<div className="table-wrapper">
			{isPending && <div>Loading...</div>}
			{error && <div>{error}</div>}
			{ <Table striped bordered hober>
					<thead>
						<tr>
							<td>First Name</td>
							<td>Last Name</td>
							<td>e-mail</td>
							<td>Birth Date</td>
							<td>action</td>
						</tr>
					</thead>
					<tbody>
			
			{persons && 
						persons.map((person) => (
							<tr key={person.id}>
								<td>{person.firstName}</td>
								<td>{person.lastName}</td>
								<td>{person.email}</td>
								<td>{person.birthDate}</td>
								<td><Link to={`/person-md/${person.id}`}>details</Link></td>
							</tr>
							)
						)}
					</tbody>
				</Table> 
						}
			
		</div>
		<div className="detail-view">
			<h2>Staff details</h2>
			<Route path="/person-md/:personId"><PersonDetails data={persons} /></Route>
				<Route exact path={"/person-md"}><p style={{ textAlign: "center" }}>Please select a person.</p>
			</Route>
		</div>
		
	</div>
	);
}
export default PersonMasterDetailList;