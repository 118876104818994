import { message } from "antd";
import { createContext, useReducer } from "react";

export const SurveyContext = createContext(); // returns a new context objects


export const SurveyReducer = (state, action) => {
    switch (action.type){
        case 'UPDATE_INVITE':
/**                return {...state, respondentInviteMessage: action.payload};*/
            return {...state, survey: {...state.survey, respondentInviteMessage: action.payload}};
        case 'UPDATE_REMINDER':
/**                return {...state, respondentReminderMessage: action.payload};*/
            return {...state, survey: {...state.survey, respondentReminderMessage: action.payload}};
        /**case 'UPDATE_CONFIGFILE':
                return {...state, configFile: action.payload};*/
        case 'UPDATE_CONFIGFILE':
            return {...state, survey: {...state.survey, configFile: action.payload}};
        case 'UPDATE_INTRO':
            return {...state, survey: {...state.survey, intro: action.payload}};
        case 'UPDATE_OUTRO':
            return {...state, survey: {...state.survey, outro: action.payload}};
        case 'UPDATE_INSTRUCTIONS':
            return {...state, survey: {...state.survey, instructions: action.payload}};
        case 'UPDATE_SURVEYNAME':
            const oldConfigFile = JSON.parse(state.survey.configFile);
            const newConfigFile = JSON.stringify({...oldConfigFile, name:action.payload});
            return {...state, survey: {...state.survey, name: action.payload, configFile: newConfigFile}};
        case 'SET_SURVEY':
            return {...state, survey: action.payload};
        case 'ADD_SURVEYSUBJECT':
            return {...state, survey: {...state.survey, surveySubjects: [...state.survey.surveySubjects, action.payload]}};
        case 'DELETE_SURVEYSUBJECT':
            //return {...state, respondents: state.respondents.filter((resp)=> resp.id !== action.payload.id)};
            /*let _surveySubjects = [...state.survey.surveySubjects];
            
            let locator = -1;
            _surveySubjects.forEach((surveySubject,index) => {
                if (surveySubject.id === action.payload.id) locator = index;
            });
            if (locator > -1) {
                _surveySubjects.splice(locator,1);
                return {...state, survey: {...state.survey, surveySubjects: _surveySubjects}};
            }
            else return state;*/
            return {...state, survey: {...state.survey, surveySubjects: state.survey.surveySubjects.filter((sbj) => sbj.id !== action.payload.id)}};
        case 'UPDATE_SURVEYSUBJECT':
            let index = state.survey.surveySubjects.findIndex((el) => {return(el.id === action.payload.id)});
            let _surveySubjects = [...state.survey.surveySubjects];
            _surveySubjects.splice(index, 1, action.payload);
            return {...state, survey: {...state.survey, surveySubjects: _surveySubjects}}

        default: 
            return state;

    }//switch
}

export function SurveyProvider({children}){

    const [state, dispatch] = useReducer(SurveyReducer, {survey: {id:null, name: "", respondentInviteMessage: "", respondentReminderMessage:"", configFile:""}});

    const updateInvite = (message) => {
        dispatch({type:'UPDATE_INVITE', payload:message});
    }
    const updateReminder = (message) => {
        dispatch({type:'UPDATE_REMINDER', payload:message});
    }
    const updateConfigFile = (message) => {
        dispatch({type:'UPDATE_CONFIGFILE', payload:message});
    }
    const updateIntro = (message) => {
        //console.log("SurveyContext receiving updated intro text");
        //console.log(message);
        dispatch({type:'UPDATE_INTRO', payload:message});
    }
    const updateOutro = (message) => {
        //console.log("SurveyContext receiving updated outro text");
        //console.log(message);
        dispatch({type:'UPDATE_OUTRO', payload:message});
    }
    const updateInstructions = (message) => {
        //console.log("SurveyContext receiving updated instructions text");
        //console.log(message);
        dispatch({type:'UPDATE_INSTRUCTIONS', payload:message});
    }
    const updateSurveyName = (message) => {
        //console.log("SurveyContext receiving updated name");
        //console.log(message);
        dispatch({type:'UPDATE_SURVEYNAME', payload:message});
    }
    const addSubjectToSurvey = (surveySubject) => {
        //console.log("SurveyContext receiving surveySubject to add");
        //console.log(surveySubject);
        dispatch({type:'ADD_SURVEYSUBJECT', payload:surveySubject});
    }
    const deleteSubjectFromSurvey = (surveySubject) => {
        //console.log("SurveyContext receiving surveySubject to delete");
        //console.log(surveySubject);
        dispatch({type:'DELETE_SURVEYSUBJECT', payload:surveySubject});
    }
    const updateSurveySubjectForSurvey = (surveySubject) => {
        dispatch({type:'UPDATE_SURVEYSUBJECT', payload:surveySubject})
    }
    const setCurrentSurvey = (surveyObject) => {
        dispatch({type:'SET_SURVEY', payload: surveyObject});
        //console.log("SurveyContext");
        //console.log(surveyObject);
    }

    return(
        <SurveyContext.Provider value={{...state, updateInvite, updateReminder, updateConfigFile, updateIntro, updateOutro, updateInstructions, updateSurveyName, addSubjectToSurvey, deleteSubjectFromSurvey, updateSurveySubjectForSurvey, setCurrentSurvey}}>
            {children}
        </SurveyContext.Provider>
    );
}