export const useReport =  () => {

    const defaultRadarBackgroundColors = [
        'rgba(255, 99, 132, 0.05)',
        'rgba(54, 162, 235, 0.05)',
        'rgba(255, 206, 86, 0.05)',
        'rgba(75, 192, 192, 0.05)',
        'rgba(153, 102, 255, 0.05)',
        'rgba(255, 159, 64, 0.05)',
      ];
      const defaultRadarBorderColors = [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
      ];


    const getChartJS_Data = async (surveyConfiguration, surveySubject, relationships) => {
        
        const getAverageScoreForListOfQuestionsAndRelationships = (surveySubject, questionList, relationships) => {
            let scores = [];
            let relevantRespondents = surveySubject.respondents.filter((respondent) => relationships.includes(respondent.relationship));
            relevantRespondents.forEach((respondent) => {
                let relevantAnswers = respondent.responses.filter((answer) => (questionList.includes(answer.questionId))&&(answer.intValue!==0));
                relevantAnswers.forEach((relevantAnswer)=> scores.push(relevantAnswer.intValue));
            });//relevantRespondents.forEach
            let total = 0;
            scores.forEach((score) => total = total +score);
            return (scores.length === 0) ? -1 : (total/scores.length);
        }//getAverageScore
            
        // set labels and data
        let labels = [];
        let data = [];
        surveyConfiguration.clusters.forEach((cluster) => {
            cluster.subClusters.forEach((subCluster) => {
                let score = getAverageScoreForListOfQuestionsAndRelationships(surveySubject, subCluster.questions, relationships);
                if (score !== -1) {
                    labels.push(subCluster.naam);
                    data.push(score);
                }//only add scores && labels if there is a value to be returned
            })
        });//clusters.forEach

        //return result
        const returnData = {
            labels: labels,
            datasets: [
                {
                    //label: ,
                    data: data,
                    backgroundColor : surveyConfiguration.reportColors,
                    borderWidth: 1,
                }
            ]
        };
        return returnData;
    } // getChartJS_Data
   /**
     * TODO: uitfilteren op basis van questionType
     * DONE: uitfilteren op basis van niet beantwoorde vragen (no opinion) (=nulscore)
     * TODO: herscoren op basis van procentenschaal
     */

    const getRadarChart_Data = async (surveyConfiguration, surveySubject, relationships) => {
        relationships.sort();
        // set the labels
        let labels = [];
        surveyConfiguration.clusters.forEach((cluster) => labels.push(cluster.naam));
        let datasets = [];
        
        relationships.forEach((relationship,i) => {
            let relationshipData = [];
            let relevantRespondents = surveySubject.respondents.filter((respondent) => respondent.relationship === relationship);

            surveyConfiguration.clusters.forEach((cluster) => {
                let clusterScoresForRelevantRespondents = [];
                cluster.subClusters.forEach((subCluster) => {
                    relevantRespondents.forEach((respondent) => {
                        let relevantAnswers = respondent.responses.filter((answer) => (subCluster.questions.includes(answer.questionId)) && (answer.intValue!==0) );
                        relevantAnswers.forEach((relevantAnswer) => {
                            clusterScoresForRelevantRespondents.push(relevantAnswer.intValue);
                        });//relevantAnswers.forEach
                    });//relevantRespondents.forEach
                });//subClusters.forEach
                let totalScore = 0;
                clusterScoresForRelevantRespondents.forEach((score) => totalScore = totalScore + score);
                let gemiddelde = (clusterScoresForRelevantRespondents.length > 0) ? (totalScore / clusterScoresForRelevantRespondents.length) : null;
                relationshipData.push(gemiddelde);
            });//clusters.forEach
            datasets.push({
                label:relationship,
                data:relationshipData,
                backgroundColor: defaultRadarBackgroundColors[(i % defaultRadarBackgroundColors.length)],
                borderColor: defaultRadarBorderColors[(i % defaultRadarBorderColors.length)],
                borderWidth: 2,
            });
        });//relationships.forEach
        //return result
        const returnData = {
            labels: labels,
            datasets: datasets
            };//returnData
        return returnData;
    } // getRadarChartJS_Data

    const getTableData = async (surveyConfiguration, surveySubject, relationships) => {
        let result = [];
        
        const getQuestionById = (id) => {
            let foundQuestion = {questionId:null, questionType:0, questionText:'no question found'};
            surveyConfiguration.pages.forEach((page) => {
                let questionArray = page.questions.filter(q => q.questionId===id);
                if (questionArray.length === 1) foundQuestion = questionArray[0];
            });
            return foundQuestion;
        }
        const getClusterInfo = (id) => {
            let foundCluster = {cluster:'no cluster found', subCluster:'no subCluster found'};
            surveyConfiguration.clusters.forEach((cluster) => {
                cluster.subClusters.forEach((subCluster) => {
                    if (subCluster.questions.includes(id)) return foundCluster = {cluster:cluster.naam, subCluster:subCluster.naam};
                });
            });//clusters.forEach
            return foundCluster;
        }

        relationships.sort();
        surveySubject.respondents.forEach((respondent) => {
            respondent.responses.forEach((response) => {
                const myQuestion = getQuestionById(response.questionId);
                const clusterInfo = getClusterInfo(response.questionId);
                result.push({relationship: respondent.relationship,
                             ...clusterInfo, 
                             questionText: myQuestion.questionText, 
                             questionType: myQuestion.questionType, 
                             booleanValue: response.booleanValue, 
                             intValue: response.intValue,
                             textValue: response.textValue});
            });//responses.forEach
        });//respondents.forEach
        return result;
    }

    return {getChartJS_Data, getRadarChart_Data, getTableData} ;
}