//import React, { Component } from "react";
import React from "react";
import { useHistory } from "react-router-dom";

//import axios from 'axios';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import {Link} from 'react-router-dom';

import { PersonsContainer } from '../../unstated/persons';

const PersonList = () => {
	const persons = PersonsContainer.useContainer();
	let history = useHistory();
	
	const formatDate = (date) => {
	    var d = new Date(date),
	        month = '' + (d.getMonth() + 1),
	        day = '' + d.getDate(),
	        year = d.getFullYear();

	    if (month.length < 2) 
	        month = '0' + month;
	    if (day.length < 2) 
	        day = '0' + day;

	    return [day, month, year].join('-');
	}

	function handleEditClick(e){
		console.log("edit  button clicked from "+e.target.id);
		console.log(e.target);
		// filter out the selectedPerson

		let mySelectedPerson = persons.persons.find(x => x.id == e.target.id);
		console.log ("fished a person from the array");
		console.log (mySelectedPerson);

		//persons.setSelectedPerson(persons.persons.find(x => x.id == e.target.id));
		//console.log("just set the selected person to ...");
		console.log(persons.selectedPerson);
		
		persons.selectAPerson(persons.persons.find(x => x.id == e.target.id));
		console.log("just set the selected person to ... (2nd method)");
		console.log(persons.selectedPerson);
		history.push("/edit-person/"+e.target.id);

	}

	function getDataTable(pContainer){
		console.log(`PERSONS-LIST at the start of getDataTable = ' + ${persons.persons.length}`);
		console.log("the fetch state = " + persons.isFetched);

		if (!persons.isFetched) persons.loadPersons();
		//};
		return (persons.persons.map((res,i) =>			
			<tr key={res.id}>
				<td>{res.firstName}</td>
				<td>{res.lastName}</td>
				<td>{res.email}</td>
				<td>{formatDate(res.birthDate)}</td>
				<td>
					<Button onClick={handleEditClick} id={res.id}>EDIT</Button>
					<Link className="edit-link" to={"/edit-person/" + res.id}>Edit - </Link>
					<Button onClick={() => persons.removePerson(res.id)} size="sm" variant="danger">Delete</Button>
				</td>
			</tr>				
		));
	}

	
	return (
		<PersonsContainer.Provider>
		<div className="table-wrapper">
			<Table striped bordered hover>
				<thead>
					<tr>
						<td>First Name</td>
						<td>Last Name</td>
						<td>e-mail</td>
						<td>Birth Date</td>
						<td>action</td>
					</tr>
				</thead>		
				<tbody>
					{
						getDataTable(persons)
					}
				</tbody>
			</Table>
		</div>
		</PersonsContainer.Provider>
	);
}

export default PersonList;

/*

this.renderPosts() will return a Promise not the actual data, and AFAIK Reactjs will not resolve Promises implicitly in render.

You need to do it like this

CODE START

componentDidMount() {
  this.renderPosts();
}

renderPosts = async() => {
  try {
    let res = await axios.get('/posts');
    let posts = res.data;
    // this will re render the view with new data
    this.setState({
      Posts: posts.map((post, i) => (
        <li key={i} className="list-group-item">{post.text}</li>
      ))
    });
  } catch (err) {
    console.log(err);
  }
}

render() {
  return (
    <div>
      <ul className="list-group list-group-flush">
        {this.state.Posts}
      </ul>
    </div>
  );
}

CODE STOP

*/

// import React, { Component } from "react";
// //import axios from 'axios';
// import Table from 'react-bootstrap/Table';
// import Button from 'react-bootstrap/Button';
// import {Link} from 'react-router-dom';

// //import PersonTableRow from './PersonTableRow';

// import {Subscribe} from 'unstated-next';
// import { PersonsContainer } from '../../unstated/persons';

// class PersonList extends Component {

	
// 	formatDate = date => {
// 	    var d = new Date(date),
// 	        month = '' + (d.getMonth() + 1),
// 	        day = '' + d.getDate(),
// 	        year = d.getFullYear();

// 	    if (month.length < 2) 
// 	        month = '0' + month;
// 	    if (day.length < 2) 
// 	        day = '0' + day;

// 	    return [day, month, year].join('-');
// 	}
// /*
//  * the personsContainer is passed to the DataTable function to allow the use of 
//  * the container functions outside of the render function.
//  * Ideally, it would be available at componentDidMount as described e.g. here:
//  * https://stackoverflow.com/questions/56149515/calling-unstated-function-using-componentdidmount-or-from-another-file
//  * 
//  * TODO: verify if deleting & adding elements to the list result in a correct update of the list
//  * !! unstated Provider is using componentWillReceiveProps, which is now UNSAFE & will be deprecated at React17
//  * 
//  */
// 	DataTable(pContainer){
// 		console.log("the fetch state = " + pContainer.state.fetched);

// 		if (!pContainer.state.fetched) pContainer.loadPersons();
// 		//};
// 		return (pContainer.state.persons.map((res,i) =>			
// 			<tr key={res.id}>
// 				<td>{res.firstname}</td>
// 				<td>{res.lastname}</td>
// 				<td>{res.email}</td>
// 				<td>{this.formatDate(res.birthdate)}</td>
// 				<td>
// 					<Link className="edit-link" to={"/edit-person/" + res.id}>
// 						Edit - 
// 					</Link>
// 					<Button onClick={() => pContainer.removePerson(res.id)} size="sm" variant="danger">Delete</Button>
// 				</td>
// 			</tr>				
// 		));
// 	}

// 	render() {
// 		/*setTimeout(() =>
// 			{console.log("PersonList.render.started time-out");}, 1000
// 		)*/
// 		console.log("PersonList.render started")
			
// 		return (
// 			<Subscribe to={[PersonsContainer]} >
// 				{personsContainer =>
// 	      			<div className="table-wrapper">
// 		            	<Table striped bordered hover>
// 		      				<thead>
// 		      					<tr>
// 									<td>First Name</td>
// 									<td>Last Name</td>
// 									<td>e-mail</td>
// 									<td>Birth Date</td>
// 									<td>action</td>
// 								</tr>
// 							</thead>		
// 							<tbody>
// 								{
// 									this.DataTable(personsContainer)
// 								}
// 							</tbody>
// 						</Table>
// 					</div>
// 				}
// 			</Subscribe>
// 	    );
// 	}
// }

// export default PersonList;