import React from "react";
import 'antd/dist/antd.css';

import "./App.css";

import { Switch, Route, Redirect } from "react-router-dom";

import { createTheme, ThemeProvider } from "@mui/material/styles";

import { Layout } from "antd";

import { PersonsContainer } from "./unstated/persons";
import Respond from './components/survey/respond';
import LoginForm from "./components/auth/loginForm";
import LinkMeInComponent from "./components/auth/linkMeInComponent";
import ProfilePage from "./components/auth/profilePage";


import CreatePerson from "./components/person/create-person.component";
import EditPerson from "./components/person/edit-person.component";
import PersonList from "./components/person/person-list.component";
import PersonMasterDetailList from "./components/person/person-master-detail-list.component";
import SurveyList from './components/survey/survey-list';

import UserList from './components/person/user-list';
import CellEditControlGrid from "./components/person/person-table";
import MsgEditor from "./components/editor/MsgEditor";
import ApexChart from "./components/reporting/apexChart";
import SubjectReport from "./components/reporting/subjectReport";

import MyAppBar from "./MyAppBar";
import { useAuthContext } from "./hooks/useAuthContext";

import {SurveyProvider} from "./context/SurveyContext";
import { RespondentsProvider } from "./context/RespondentsContext";
import SubjectComponent from "./components/survey/subject-component";
import { SurveySubjectsProvider } from "./context/SurveySubjectsContext";
import SurveyDetailsComponent from "./components/survey/survey-details-component";
import { RespondProvider } from "./context/RespondContext";
import { GoogleOAuthProvider } from "@react-oauth/google";
import SurveyPages from "./components/survey/survey-pages-component"
import SurveyClusters from "./components/survey/survey-clusters-component";

import './i18n';
import { useTranslation, Trans } from "react-i18next";
import SurveyPage from "./components/survey/survey-page-component";
import { SurveySubjectProvider } from "./context/SurveySubjectContext";

 const { Header, Content, Sider } = Layout;

 const dpgMediaTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#783c96',
    },
    secondary: {
      main: '#d23278',
    },
    error: {
      main: '#e6463c',
    },
    warning: {
      main: '#fabb22',
    },
    info: {
      main: '#db5b93',
    },
  },
//  typography: {
//    fontFamily: 'Open Sans',
//  },
});

const myClientId = "263152169034-7q4spag2halgohvgfsss61sibcj1ipan.apps.googleusercontent.com";
const myScopes = "https://www.googleapis.com/auth/gmail.send";

const Home = (props) => {
  const {t} = useTranslation();

  return (
    <div>
      <h2>{t('Welkom op 360°feedback.be !')}</h2>
      <div style={{textAlign : "left" }}>
{/**        Je komt op deze website terecht omdat je bent uitgenodigd om deel te nemen aan een 360° feedback-oefening voor een collega of iemand waarmee je samenwerkt over de grenzen van organisaties heen.<br/>
        Normaalgezien werd je daarvoor uitgenodigd via mail &amp; met de link in die mail kan je de vragenlijst rechtstreeks beantwoorden.<br/>*/}
        {t('Je komt op deze website terecht omdat je bent uitgenodigd om deel te nemen aan een 360° feedback-oefening voor een collega of iemand waarmee je samenwerkt over de grenzen van organisaties heen.')}<br/>
        {t('Normaalgezien werd je daarvoor uitgenodigd via mail en met de link in die mail kan je de vragenlijst rechtstreeks beantwoorden.')}<br/>
        <br/>
        {t('Veel succes !')}
      </div>
    </div>
  );
};

function App() {
  const {authIsReady, user} = useAuthContext();


  return (<ThemeProvider theme={dpgMediaTheme}>
      <div className="App">
        {!authIsReady && <div>loading ...</div>}
        {authIsReady && (
        <PersonsContainer.Provider>
              <GoogleOAuthProvider clientId={myClientId} scope={myScopes}>
                <MyAppBar/>
              </GoogleOAuthProvider>
               
              <Layout>
                <Layout style={{ padding: "0 24px 24px" }}>
                  <Content
                    className="site-layout-background"
                    style={{
                      padding: 24,
                      margin: 0,
                      minHeight: 280,
                    }}
                  >
                    <div className="wrapper">
                      <SurveyProvider>
                        <SurveySubjectsProvider>
                          <SurveySubjectProvider>
                          <RespondentsProvider>
                              <RespondProvider>
                                <Switch>
                                  <Route exact path="/">
                                    {!user && <Redirect to="/loginForm"/>}
                                    {user && <Home />}
                                  </Route>
                                  <Route path="/loginForm">
                                    {!user && <LoginForm/>}
                                    {user && <Redirect to="/userprofile"/>}
                                  </Route>
                                  
                                  <Route path="/linkMeIn/:loginLink" component={LinkMeInComponent}/>

                                  <Route path="/survey-list" component={SurveyList} />
                                  <Route path="/create-person" component={CreatePerson} />
                                  <Route path="/person-list" component={PersonList} />
                                  <Route path="/person-md" component={PersonMasterDetailList} />
                                  <Route path="/edit-person/:id" component={EditPerson}></Route>
                                  <Route path="/survey/:surveyIdParam" component={SurveyDetailsComponent}></Route>
                                  <Route path="/newsubject/:subjectIdParam" component={SubjectComponent}></Route>
                                  
                                  <Route path="/users" component={UserList}></Route>
                                  <Route path="/person-table" component={CellEditControlGrid}></Route>
                                  <Route path="/editorTest" component={MsgEditor} placeholder="test me"></Route>
                                  <Route path="/chart" component={ApexChart}/>
                                  <Route path="/report/:subjectId" component={SubjectReport}/>
                                  <Route path="/userprofile" component={ProfilePage}/>
                                  <Route path="/respond/:respondentId" component={Respond}></Route>
                                  
                                  {/* === tijdelijke routes voor testing */}
                                  {/*<Route path="/surveypages" component={SurveyPages}></Route>
                                  <Route path="/surveypage" component={SurveyPage}></Route>*/}

                                  <Route path="/:id" element={
                                    <React.Fragment>
                                      Even de weg kwijt ?  (klik op de link die je kreeg)
                                    </React.Fragment>
                                  }/>
                                </Switch>
                              </RespondProvider>
                            </RespondentsProvider>
                            </SurveySubjectProvider>
                        </SurveySubjectsProvider>
                        </SurveyProvider>
                    </div>
                  </Content>
                </Layout>  
              </Layout>
        </PersonsContainer.Provider>
        )}
       </div>
    </ThemeProvider>
  );
}

export default App;