
import { Clear, ExitToApp, Save } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useState, useEffect } from "react";
import "./RespondentForm.css";

export default function SurveySubjectForm({addSurveySubject, handleClose, updateSurveySubject, editableSurveySubject}){
//updateSurveySubject={adjustSurveySubjectInComponent} editableSurveySubject={activeSurveySubject.current}

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    //const [birthDate, setBirthDate] = useState(new Date());

    useEffect(() => {
        console.log("surveySubjectForm-useEffect[]")
        if (editableSurveySubject) {
            setFirstName(editableSurveySubject.subjectPerson.firstName);
            setLastName(editableSurveySubject.subjectPerson.lastName);
            setEmail(editableSurveySubject.subjectPerson.email);
            //setBirthDate(editableSurveySubject.subjectPerson.birthDate);
        } //if editableSurveySubject is not null
    },[]);
    useEffect(() => {
        console.log("surveySubjectForm-useEffect[editableSurveySubject]");
        if (editableSurveySubject){
            setFirstName(editableSurveySubject.subjectPerson.firstName);
            setLastName(editableSurveySubject.subjectPerson.lastName);
            setEmail(editableSurveySubject.subjectPerson.email);
            //setBirthDate(editableSurveySubject.subjectPerson.birthDate);
        }// if editableSurveySubject is not null
    }, [editableSurveySubject]);

    const resetForm = () => {
        setFirstName('');
        setLastName('');
        setEmail('');
        //setBirthDate(new Date());
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const subj = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            //birthDate: birthDate,
        }
        console.log(editableSurveySubject);
        if (editableSurveySubject!==null && editableSurveySubject.id!==null){
            updateSurveySubject({...editableSurveySubject, subjectPerson:{id:editableSurveySubject.subjectPerson.id, firstName: firstName, lastName: lastName, email: email}});
        }
        else addSurveySubject(subj);
        
        resetForm();
    }

    return (
        <form className="edit-respondent-form" onSubmit={handleSubmit}>
            <label>
                <span>voornaam: </span>
                <input type="text" id="firstName" onChange={(e) => setFirstName(e.target.value)} value={firstName} />
            </label>
            <label>
                <span>naam: </span>
                <input type="text" id="lastName" onChange={(e) => setLastName(e.target.value)} value={lastName}/>
            </label>
            <label>
                <span>email: </span>
                <input type="text" id="email" onChange={(e) => setEmail(e.target.value)} value={email}/>
            </label>
            <div className="bottomButtons">
                <div>
                    <Button
                        variant="outlined"
                        onClick={handleSubmit} 
                        startIcon={<Save />} >Bewaren
                    </Button>
                </div>
                <div>
                    <Button 
                        variant="outlined"
                        onClick={resetForm} 
                        startIcon={<Clear />} >Wissen
                    </Button>
                </div> 
                <div>
                    <Button 
                        variant="outlined"
                        onClick={handleClose} 
                        startIcon={<ExitToApp />} >Sluiten
                    </Button>
                </div> 
            </div>
            <br/>
        </form>
    );
}